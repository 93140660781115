import React, { useState } from "react";
import { useSelector } from "react-redux";
import { TransitionGroup } from 'react-transition-group';
// import { setProfile } from "../../../../actions/auth_actions";

import styles from '../../../../assets/css/styling.module.css';

const Employement_Details = (props) => {

    const { profileDetails, setProfileDetails } = props

    const [isAllowEdit, setAllowEdit] = useState(false);
    const systemOptionsState = useSelector(state => state.SystemSettings)

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
            <div className="mb-3">

                {/* Edit */}
                <div className="clearfix px-3">
                    <div className="text-right">
                        <button className="btn btn-link" style={{ color: "#1c6f9f", fontSize: '1.05rem', fontWeight: 600, letterSpacing: "0.5px" }} onClick={() => setAllowEdit(true)}>Edit</button>
                    </div>
                </div>

                <div className={styles.card_border + " mb-4"}>
                    <div className={styles.card_border_body + " p-4"}>

                        {/* Question */}
                        <h5 style={{ color: "#000", fontWeight: 600, fontSize: "1rem" }} className="mb-0">
                            {`Q. Relation with Chief Wage Earner (CWE)`}
                        </h5>

                        {/* Answer */}
                        {!isAllowEdit ? (
                            <div className="pt-3">
                                <p style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}  className={`form-control ${!profileDetails.seniority? `${styles.custom_thick_border}` : 'form-control valid'}`}>
                                    {profileDetails.cwe}
                                </p>
                            </div>
                        ) : (
                            <div className="mt-3">
                                {/* Text  Area */}
                                <select className={`form-control ${!profileDetails.cwe? `${styles.custom_thick_border}` : 'form-control valid'}`} value={profileDetails.cwe}
                                    onChange={(e) => setProfileDetails({ ...profileDetails, cwe: e.target.value })}>
                                    <option value='' >Select</option>
                                    {
                                        systemOptionsState.specificOptions && systemOptionsState.specificOptions.relationship && 
                                        systemOptionsState.specificOptions.relationship.map(relationship => {
                                            return <option value={relationship.name}>{relationship.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        )}

                    </div>
                </div>

                <div className={styles.card_border + " mb-4"}>
                    <div className={styles.card_border_body + " p-4"}>

                        {/* Question */}
                        <h5 style={{ color: "#000", fontWeight: 600, fontSize: "1rem" }} className="mb-0">
                            {`Q. What is the name of the company you / your CWE works for?`}
                        </h5>

                        {/* Answer */}
                        {!isAllowEdit ? (
                            <div className="pt-3">
                                <p style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}  className={`form-control ${!profileDetails.seniority? `${styles.custom_thick_border}` : 'form-control valid'}`}>
                                    {profileDetails.company}
                                </p>
                            </div>
                        ) : (
                            <div className="mt-3">
                                {/* Text  Area */}
                                <textarea className={`form-control ${!profileDetails.company? `${styles.custom_thick_border}` : 'form-control valid'}`} placeholder="Write description here" value={profileDetails.company} onChange={e => setProfileDetails({ ...profileDetails, company: e.target.value })} />
                            </div>
                        )}

                    </div>
                </div>

                <div className={styles.card_border + " mb-4"}>
                    <div className={styles.card_border_body + " p-4"}>

                        {/* Question */}
                        <h5 style={{ color: "#000", fontWeight: 600, fontSize: "1rem" }} className="mb-0">
                            {`Q. What is your / your CWE's job title?`}
                        </h5>

                        {/* Answer */}
                        {!isAllowEdit ? (
                            <div className="pt-3">
                                <p style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}  className={`form-control ${!profileDetails.seniority? `${styles.custom_thick_border}` : 'form-control valid'}`}>
                                    {profileDetails.jobRole}
                                </p>
                            </div>
                        ) : (
                            <div className="mt-3">
                                {/* Text  Area */}
                                <textarea className={`form-control ${!profileDetails.jobRole? `${styles.custom_thick_border}` : 'form-control valid'}`} placeholder="Write description here" value={profileDetails.jobRole} onChange={e => setProfileDetails({ ...profileDetails, jobRole: e.target.value })} />
                            </div>
                        )}

                    </div>
                </div>


                {isAllowEdit && (
                    <div className="row mt-4">
                        <div className="col-12 text-right">

                            {/* Save Changes button */}
                            <button className="btn btn-hover-shine text-light py-2" style={{ backgroundColor: "#0071a2", borderRadius: "8px" }} onClick={() => props.editUser()}>Save Changes</button>

                        </div>
                    </div>
                )}

            </div>
        </TransitionGroup>

    );

}

export default Employement_Details