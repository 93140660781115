import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router';
import PageHeader from '../../../assets/components/pageheader/PageHeader';
import ArrowLeft from '../../../assets/images/arrow_left.png'
import styles from '../../../assets/css/styling.module.css'
import styles2 from '../../../assets/css/rstyling.module.css'
import GroupuserIcon from '../../../assets/images/group_user_ico.png'
import CalenderIcon from '../../../assets/images/calender_icon.png'
import ReplyIcon from '../../../assets/images/group_reply_ico.png'
import ForumIcon from '../../../assets/images/forum_icon.png'
import { Card, CardBody } from 'reactstrap'
import classNames from 'classnames';
import Profile from '../../../assets/images/profile_image.jpg'
import DotsIcon from '../../../assets/images/dots_icon_gray.png'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { getActivity, getActivityImg } from '../../../actions/activities_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import ResizeDetector from 'react-resize-detector';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';



// ******* This component is used to preview the created general forum listing**********

const GeneralForumPreview = (props) => {

    const location = useLocation()
    const history = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [forumData, setForumData] = useState({})
    const [isLoading, setLoading] = useState(true)
const[GFImg, setGFImg] = useState({})
    useEffect(() => {

        dispatch(getActivityImg(params.activityid))
        .then((response) =>{
            try{
          
            setGFImg(response.payload.content.fileData) }
            catch(err){
                console.log('banner img not available')
            }
            
        })
        if (params.activityid !== 'undefined') {
            dispatch(getActivity(params.activityid))
                .then((response) => {
                    setForumData(response.payload.content)
                    setLoading(false)
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                })
        } else {
            setLoading(false)
        }

    }, [])

    const [currentWidth, setWidth] = useState(1026)
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>
                    <LoadingOverlay tag="div" active={isLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                                // top: '50%'
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>
                        <>
                            <div className="admin_header">
                                <PageHeader
                                    heading={"Forum Activity"} img={ForumIcon}
                                    btnGroup={true}
                                    btn1Click={() => setWidth(1026)}
                                    btn2Click={() => setWidth(470)}
                                    btn3Click={() => setWidth(400)}
                                />
                            </div>

                            {/* Back Icon */}
                            <span onClick={() => location.state && location.state.from === 'create' ?
                                history(`/admin/edit-generalforum/${params.activityid}`) :
                                history(-1)} style={{ cursor: 'pointer' }}>
                                <img src={ArrowLeft} alt="" width="8px" />
                                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                            </span>

                            <div className="container-fluid" style={{ width: currentWidth }}>
                                <div className={"pb-0 d-flex text-muted"} style={{ padding: '0.68rem', fontSize: '1.05rem', border: '2px solid #ddd', borderBottom: 0, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', backgroundColor: 'white', color: '#fff' }}>
                                    {/* Title */}
                                    {forumData.name}
                                </div>

                                {/* Project Box Body */}
                                <div className={styles.card_border} style={{ borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, }} >

                                    <div className={styles.card_border_body + " py-3 px-0 mx-0 text-justify"}>
                                        {/* Project Details */}
                                        <p className="px-3" style={{ color: '#666', fontSize: "0.93rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}>
                                            {forumData.description}
                                        </p>

                                        {/* Bottom Content */}
                                        <div className="clearfix pt-1 px-3 mx-0" style={{ borderTop: '1px solid #ddd' }}>
                                            <div className="float-left">

                                                <div className="d-flex pt-1">
                                                    <div className="d-inline-flex">
                                                        <img src={CalenderIcon} width="20px" alt='img'/>
                                                    </div>

                                                    <div className="d-inline-flex align-self-center mx-2">
                                                        <div style={{ fontSize: '0.95rem', color: '#666' }}>
                                                            <span style={{ fontSize: '0.9rem', fontWeight: '500', color: '#999' }}>{forumData.participants} Date</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-inline-flex">
                                                        <img src={GroupuserIcon} width="20px" alt='img' />
                                                    </div>

                                                    <div className="d-inline-flex align-self-center mx-2">
                                                        <div style={{ fontSize: '0.95rem', color: '#666' }}>
                                                            <span style={{ fontSize: '0.9rem', fontWeight: '500', color: '#999' }}>{forumData.audience && forumData.audience.members && forumData.audience.members.length} Members</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-inline-flex">
                                                        <img src={ReplyIcon} width="20px" alt='img'/>
                                                    </div>

                                                    <div className="d-inline-flex align-self-center mx-2">
                                                        <div style={{ fontSize: '0.95rem', color: '#666' }}>
                                                            <span style={{ fontSize: '0.9rem', fontWeight: '500', color: '#999' }}>{forumData.participants}Replies</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="my-3 ml-2" style={{ color: '#777', fontSize: '0.98rem', fontWeight: '600' }}>All Replies</div>

                                {/* Comments */}

                                <Card className="shadow-none mb-2" style={{ borderRadius: '0.4rem' }}>
                                    <CardBody className="p-0 custom_drop">
                                        {
                                            forumData.comments && forumData.comments.map(comment => {
                                                return <> <div className="widget-content-wrapper d-flex px-3 pt-3">
                                                    <div className="widget-content-left font-size-md" style={{ fontSize: '0.8rem' }}>
                                                        <img src={comment.profile} alt="profile" width={40} className="rounded-circle mr-2" />
                                                    </div>
                                                    <div className="widget-content-left mr-auto">
                                                        <div className="widget-subheading mt-1 opacity-10">
                                                            <b>{comment.userName}</b>  <span className={classNames({
                                                                'badge-success': comment.role === 'Admin',
                                                                'badge-warning': comment.role === 'Respondent',
                                                                'badge-danger': comment.role === 'Researcher',
                                                            }, 'badge badge-pill text-right')}>{comment.role}</span>
                                                        </div>

                                                    </div>
                                                    <div className="widget-content-right d-flex">
                                                        <div className="text-muted py-3" style={{ fontSize: '0.8rem', fontWeight: 500 }}>
                                                            {comment.date}
                                                        </div>
                                                        <UncontrolledButtonDropdown className="mb-2 mr-2 ml-2 participant_btn" style={{ zIndex: '15' }}>
                                                            <DropdownToggle>
                                                                <img className="ml-2 mr-2" src={DotsIcon} height="12px" alt="" />
                                                            </DropdownToggle>
                                                            <DropdownMenu style={{ marginLeft: '-13rem' }}>
                                                                <DropdownItem>Edit</DropdownItem>
                                                                <DropdownItem>Delete</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>
                                                    <div className="widget-content-wrapper mb-3 px-3">
                                                        <p className="mb-0 pb-1 pt-3 d-block">{comment.text}</p>
                                                    </div>

                                                    <hr className="pb-3" />

                                                    {
                                                        comment.replies.map(reply => {
                                                            return <> <div className="widget-content-wrapper d-flex px-5">
                                                                <div className="widget-content-left font-size-md" style={{ fontSize: '0.8rem' }}>
                                                                    <img src={reply.profile} alt="profile" width={40} className="rounded-circle mr-2" />
                                                                </div>
                                                                <div className="widget-content-left mr-auto">
                                                                    <div className="widget-subheading mt-1 opacity-10">
                                                                        <b>{reply.userName}</b>  <span className={classNames({
                                                                            'badge-success': reply.role === 'Admin',
                                                                            'badge-warning': reply.role === 'Respondent',
                                                                            'badge-danger': reply.role === 'Researcher',
                                                                        }, 'badge badge-pill text-right')}>{reply.role}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="widget-content-right d-flex">
                                                                    <div className="text-muted py-3" style={{ fontSize: '0.8rem', fontWeight: 500 }}>
                                                                        {reply.date}
                                                                    </div>
                                                                    <UncontrolledButtonDropdown className="mb-2 mr-2 ml-2 participant_btn" style={{ zIndex: '15' }}>
                                                                        <DropdownToggle>
                                                                            <img className="ml-2 mr-2" src={DotsIcon} height="12px" alt="" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu style={{ marginLeft: '-13rem' }}>
                                                                            <DropdownItem>Edit</DropdownItem>
                                                                            <DropdownItem>Delete</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                                <div className="widget-content-wrapper mb-3 px-5">
                                                                    <p className="mb-0 pb-1 pt-3 d-block">{reply.text}</p>
                                                                </div>
                                                            </>
                                                        })
                                                    }

                                                    <hr />

                                                    {/* Reply Comment */}
                                                    <div className="row mx-4 py-3">

                                                        <div className="d-inline-flex align-self-center">
                                                            <img src={Profile} className="rounded-circle" style={{ backgroundColor: "#e8e6e5", width: '38px', height: '38px' }} alt='img' />
                                                        </div>

                                                        <div className="col align-self-center">

                                                            <div className="input-group" style={{ border: '2px solid #ddd', borderRadius: '8px', backgroundColor: "#f7f6f2", padding: '0.2rem' }}>
                                                                <input type="text" className={"form-control " + styles.reply_comment_textbox} style={{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' }} placeholder="Write a comment" />
                                                                <div className="input-group-append">
                                                                    <span className={styles.upload_media_icon}></span>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="d-inline-flex align-self-center">
                                                            <button className={styles2.sent_blue_btn}>
                                                                <span className={styles.sent_icon}></span>
                                                            </button>
                                                        </div>

                                                    </div>
                                                </>

                                            })

                                        }

                                    </CardBody>
                                </Card>

                                {/* Add Comment */}
                                <div className={"d-block app-footer"}>
                                    <div className={'app-footer__inner'}>

                                        <div className="container-fluid mx-5 py-2">
                                            <div className="row mx-4">

                                                <div className="d-inline-flex align-self-center">
                                                    <img src={Profile} className="rounded-circle" style={{ backgroundColor: "#e8e6e5", width: '38px', height: '38px' }} alt='img' />
                                                </div>

                                                <div className="col align-self-center">

                                                    <div className="input-group" style={{ border: '2px solid #ddd', borderRadius: '8px', backgroundColor: "#f7f6f2", padding: '0.2rem' }}>
                                                        <input type="text" className={"form-control " + styles.reply_comment_textbox} style={{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' }} placeholder="Write a comment" />
                                                        <div className="input-group-append">
                                                            <span className={styles.upload_media_icon}></span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="d-inline-flex align-self-center">
                                                    <button className={styles2.sent_blue_btn}>
                                                        <span className={styles.sent_icon}></span>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </>
                    </LoadingOverlay>


                </>
            )}
        />
    )


}

export default GeneralForumPreview


