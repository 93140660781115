import React, { useState } from 'react';

import Tabs from 'react-responsive-tabs';
import ArrowLeft from '../../../../assets/images/arrow_left.png'
import { useNavigate, useParams } from 'react-router';
import CreateIcon from '../../../../assets/images/create_icon.png';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import ActivitiesReport from './ActivitiesReport';
import ParticiapntReport from './ParticipantReport';
import ExcerptReport from './ExcerptReport';
import { projectReport } from '../../../../actions/project_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'

const Report = (props) => {

    const history = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const tabsContent = [
        {
            title: 'Participants',
            content: <ParticiapntReport />
        },
        {
            title: 'Activities',
            content: <ActivitiesReport />
        },
        {
            title: 'Excerpts',
            content: <ExcerptReport />
        },
    ];

    function getTabs() {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index,
        }));
    }

    const exportReport = () => {
        Swal({
            title: "Please wait!",
            text: "Downloading report...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });

        dispatch(projectReport(params.projectid))
            .then((response) => {
               

                const url = response.payload.content.link;
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();

                Swal.stopLoading()
                Swal.close()

            })
            .catch(err => {
                Swal.stopLoading()
                Swal.close()
                Swal({
                    title: "Error",
                    text: "Please try again",
                    type: "error"
                })
            })
    }

    return (
        <>

            <div className="create_header">
                <PageHeader
                    heading="Reports" img={CreateIcon}
                    btnText="Export Project Report"
                    btnClick={() => exportReport()}
                />
            </div>

            {/* Back Icon */}
            <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <div className="tab_blue">
                <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false}
                    items={getTabs()}
                />
            </div>
        </>
    )

}

export default Report;