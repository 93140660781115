import React, { useState, useEffect } from 'react'
import styles from '../../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../../assets/images/search_icon.png'
import { Card, CardBody } from "reactstrap";
import Listing from '../../../../assets/components/listing/Listing';
import GraphIcon from '../../../../assets/images/graph_icon.png'
import Eyeicon from '../../../../assets/images/eye_icon.png'
import ResizeDetector from 'react-resize-detector';
import Participant from './view/Participant/Participant';
import { useNavigate, useParams, useLocation } from 'react-router';
import AlertModal from '../../../../assets/components/modal/AlertModal';
import SettingIcon from '../../../../assets/images/settings_icon_sm.png'
import ReportIcon from '../../../../assets/images/report_icon.png'
import Filter from '../../../../assets/images/filter_icon.png'
import { UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import _ from 'lodash'
import ActivityView from './view/ActivityView';
import { delActivity, getProjectActivity, updateActivity } from '../../../../actions/activities_actions';
import Loader from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'

const Activity = () => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const param = useParams()
    const dispatch = useDispatch()
    const path = currentPath.slice(0, currentPath.lastIndexOf('/'))
    const activityState = useSelector( state => state.Activity )
    const [activityData, setActivityData] = React.useState([])

    const [activityOptions, setActivityOptions] = useState({ isShow: false, activeTab: '0' })
    const [isLoading, setLoading] = useState(true)
    const [delId, setDelID] = useState()
    const [archiveId, setArchiveId] = useState()
    const [publishId, setPublishId] = useState()
    const [unArchiveId, setUnArchiveId] = useState()

    const [isModalOpen, setModal] = useState(false)

    const [activityColumn, setColumn] = useState([
        { Name: 'Activity Name', Type: 'string', key: 'name' },
        // { Name: "Start Date", Type: 'activitystartdate', key: 'startDate' },
        // { Name: "End Date", Type: 'activityenddate', key: 'endDate' },
        { Name: "Dates", Type: 'activitydates', key: 'dates' },
        { Name: "Activity Type", Type: 'string', key: 'activityType' },
        { Name: "Status", Type: 'activity status', key: 'status' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    onClick: () => console.log("I'm clicked"),
                    type: 'dropdown',
                    droplist: [
                        { Name: 'Participants', id: param.projectid, tab: '1' },
                        // { Name: 'Excerpts', id: param.projectid, tab: '2' },
                        // { Name: 'Files', id: param.projectid, tab: '4' },
                        { Name: 'View Form', id: param.projectid, tab: '3' },
                        { Name: 'Script Form', id: param.projectid, tab: '5' },
                    ],
                    url: '/admin/project',
                    isDifferentUrl: true,
                    linkFor: 'view activity'
                },
                {
                    icon: GraphIcon,
                    url: "/admin/project",
                    type: 'button',
                    isDifferentUrl: true,
                    linkFor: 'analytics',
                    id: param.projectid
                },
                // {
                //     icon: ReportIcon,
                //     url: "/admin/project",
                //     type: 'button',
                //     isDifferentUrl: true,
                //     linkFor: 'activity report',
                //     id: param.projectid
                // },
                {
                    icon: SettingIcon,
                    onClick: () => console.log("I'm clicked"),
                    type: 'dropdown',
                    isDifferentUrl: true,
                    linkFor: 'activity settings',
                    url: '/admin/project',
                    droplist: [
                        { Name: 'Edit', id: param.projectid, linkFor: 'edit activity' },
                        // { Name: 'Archive', setID: setArchiveId },
                        // { Name: 'Unarchive', setID: setUnArchiveId },
                        // { Name: 'Copy', id: param.projectid, linkFor: 'copy activity' },
                        // { Name: 'Unpublish', setID: setPublishId },
                        { Name: 'Delete', setID: setDelID }
                    ]
                },
            ]
        }

    ])
   
    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    // For changing status of activity
    useEffect(() => {
        (archiveId || unArchiveId || publishId) && editActivity()
    }, [archiveId, unArchiveId, publishId])

    // Show or hide activity view options
    useEffect(() => {
        switch (path) {
            case '/admin/project/activity':
                setActivityOptions({ isShow: false, activeTab: '2' })
                break;

            default:
                setActivityOptions({ isShow: true, activeTab: '2' })
                break;
        }
    }, [path])

    const deleteActivity = () => {
        dispatch(delActivity(delId))
        .then(response => {
            setDelID()
            getActivityListing(activityState.currentPage, activityState.dataLimit)
            Swal({
                title: "Deleted",
                text: "Activity Deleted",
                icon: 'info'
            });
        })
        .catch(err => {
            Swal({
                title: err.content ? err.content : "Error",
                text: "Please try again!",
                icon: 'error'
            });
        })
    }

    // Changing activity status
    const editActivity = () => {
        const formData = new FormData()
        let id = ''
        if (archiveId) {
            formData.append('status', 'archive')
            id = archiveId
            setArchiveId()
        } else if (unArchiveId) {
            formData.append('status', 'scheduled')
            id = unArchiveId
            setUnArchiveId()
        } else if (publishId) {
            formData.append('status', 'archive')
            id = publishId
            setPublishId()
        }
        dispatch(updateActivity(id, formData))
        .then(response => {
            getActivityListing(activityState.currentPage, 5)
            Swal({
                title: "Updated",
                text: "Activity Updated",
                icon: 'info'
            });
        })
        .catch(err => {
            Swal({
                title: err.content ? err.content : "Error",
                text: "Please try again!",
                icon: 'error'
            });
        })
    }

    const [searchString, setSearchString] = useState('')
    const [searchedActivityResult, setSearchedActivityResult] = useState([])

    // Filtering data based on search values
    useEffect(() => {

        let search = new RegExp(searchString, 'i');
        let filteredActivity = activityData.filter(row => {
            return (
                search.test(row.name) ||
                search.test(row.activityType) ||
                search.test(row.startDate) ||
                search.test(row.status)
            );
        });
        setSearchedActivityResult(filteredActivity);
    }, [searchString]);

    // Filtering activities based on their status
    const filterActivities = (status) => {
        let filteredActivity = activityData.filter(row => {
            if (status === 'completed') {
                return row.status === 'completed'
            } else if (status === 'active') {
                return row.status === 'scheduled' || row.status === 'active'
            } else if (status === 'archived') {
                return row.status === 'archive'
            }
        });
        setSearchedActivityResult(filteredActivity);
    }

    const getActivityListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(getProjectActivity({page: currentPage, limit: dataLimit, projectId: param.projectid}))
            .then(response => {
                setActivityData(response.payload.content.activities)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }

    useEffect(() => {
        getActivityListing()
    }, [])

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>

                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                        {/* Delete Modal */}
                        <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={deleteActivity}/>

                        {
                            activityOptions.isShow ?
                                <ActivityView activeTab={activityOptions.activeTab} />
                                :
                                /* Header */
                                <Card className="main-card mb-3">
                                    <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                        <div className="text-white menu-header-title text-capitalize">Activities</div>

                                        <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                            <ul className="navbar-nav mr-auto">
                                            </ul>
                                            <form className="form-inline my-2 my-lg-0" style={{ marginRight: '-2rem' }}>
                                                <div className="input-group input-group-sm w-75">
                                                    <div className="input-group-prepend border-0">
                                                        <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                    </div>
                                                    <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }} onChange={(e) => setSearchString(e.target.value)} />
                                                </div>
                                            </form>
                                            {/* Filter Activity based on their status */}
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle className="btn btn-primary" style={{ borderRadius: '0.2rem', border: '1px solid white', color: 'white', backgroundColor: '#006FA7' }}>Sort By <img src={Filter} alt="" /></DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => filterActivities('active')}>Active</DropdownItem>
                                                    <DropdownItem onClick={() => filterActivities('archived')}>Archived</DropdownItem>
                                                    <DropdownItem onClick={() => filterActivities('expired')}>Expired</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </nav>

                                    {/* Listing */}
                                    <CardBody className="p-0">
                                        {/* Loader */}
                                        <LoadingOverlay tag="div" active={isLoading}
                                            styles={{
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: "#fff",
                                                    opacity: 0.5,
                                                }),
                                            }}
                                            spinner={
                                                <Loader color="#6c757d" active type="ball-pulse" />
                                            }>
                                            <CardBody className="p-0">

                                                {/* Listing */}
                                                <div className="table_border">
                                                    <Listing columnName={activityColumn}
                                                        data={(searchString.length > 0 || searchedActivityResult.length > 0) ? searchedActivityResult : activityData && activityData.length >0 ? activityData:<></>}
                                                        currentPage={activityState.currentPage ? activityState.currentPage : 1}
                                                        totalPages={activityState.totalPages ? activityState.totalPages : 1}
                                                        getListingData={(currentPage, dataLimit) => getActivityListing(currentPage, dataLimit)}
                                                        startLoading={() => setLoading(true)}
                                                    />
                                                </div>

                                            </CardBody>
                                        </LoadingOverlay>
                                    </CardBody>
                                </Card>
                        }

                    </TransitionGroup>

                </>
            )}
        />
    )
}

export default Activity
