import { AVAILABLE_REWARD } from "../actions/types";

let rewardDataState = {reward: [], totalPages: 0, currentPage: 1 };


export default function (state = rewardDataState, action) {
    switch (action.type) {
        case AVAILABLE_REWARD:
            if (state.reward.length === 0) {
                return {
                    ...state,
                    reward: action.payload.content.rewards,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.reward = state.reward.concat(action.payload.content.rewards);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    reward: concatResult.reward,
                    totalPages: concatResult.totalPages,
                    currentPage: concatResult.currentPage,
                }
            }

        // case AVAILABLE_QUICK_SURVEY:
        //     if (state.quickSurvey.length === 0) {
        //         return {
        //             ...state,
        //             quickSurvey: action.payload.content.activities,
        //             totalPages: action.payload.content.totalPages,
        //             currentPage: parseInt(action.payload.content.currentPage),
        //         }
        //     } else {
        //         let concatResult = {};
        //         concatResult.quickSurvey = state.activity.concat(action.payload.content.activities);
        //         concatResult.totalPages = action.payload.content.totalPages;
        //         concatResult.currentPage = parseInt(action.payload.content.currentPage);
        //         return {
        //             ...state,
        //             quickSurvey: concatResult.quickSurvey,
        //             totalPages: concatResult.totalPages,
        //             currentPage: concatResult.currentPage,
        //         }
        //     }
        // case AVAILABLE_RESPONDENT_ACTIVITY:
        //     if (state.respondentActivity.length === 0) {
        //         return {
        //             ...state,
        //             respondentActivity: action.payload.content.activities,
        //             totalPages: action.payload.content.totalPages,
        //             currentPage: parseInt(action.payload.content.currentPage),
        //         }
        //     } else {
        //         let concatResult = {};
        //         concatResult.respondentActivity = state.respondentActivity.concat(action.payload.content.activities);
        //         concatResult.totalPages = action.payload.content.totalPages;
        //         concatResult.currentPage = parseInt(action.payload.content.currentPage);
        //         return {
        //             ...state,
        //             respondentActivity: concatResult.respondentActivity,
        //             totalPages: concatResult.totalPages,
        //             currentPage: concatResult.currentPage,
        //         }
        //     }
        // case AVAILABLE_RESPONDENT_FORUM:
        //     // if (state.respondentForum.length === 0) {
        //         return {
        //             ...state,
        //             respondentForum: action.payload.content.activities,
        //             // totalPages: action.payload.content.totalPages,
        //             // currentPage: parseInt(action.payload.content.currentPage),
        //         }
           
        // case AVAILABLE_GENERAL_FORUM:
        //     if (state.activity.length === 0) {
        //         return {
        //             ...state,
        //             activity: action.payload.content.activities,
        //             totalPages: action.payload.content.totalPages,
        //             currentPage: parseInt(action.payload.content.currentPage),
        //         }
        //     } else {
        //         let concatResult = {};
        //         concatResult.activity = state.activity.concat(action.payload.content.activities);
        //         concatResult.totalPages = action.payload.content.totalPages;
        //         concatResult.currentPage = parseInt(action.payload.content.currentPage);
        //         return {
        //             ...state,
        //             activity: concatResult.activity,
        //             totalPages: concatResult.totalPages,
        //             currentPage: concatResult.currentPage,
        //         }
        //     }
        default:
            return state;
    }
}