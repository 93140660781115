import React, { useState, useEffect, useRef } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { Card, CardBody, Label, Input, FormGroup, Button } from "reactstrap";
import styles from '../../../../assets/css/rstyling.module.css'
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';

const Finalization = (props) => {
    const { previous } = props

    const formRef = useRef()
    const calculateEndDate = (startDateString, duration) => {
       // console.log("start date string" +startDateString)
        if(startDateString !== '' && duration !== '') {
            
            const startDate = new Date(startDateString).getTime();
            const day_milli = 1000 * 60 * 60 * 24;
            const endDate = new Date(startDate + day_milli * (duration -1));
            formRef.current.setFieldValue('endDate', endDate);
        }
    }

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <Card className="main-card mb-3 mt-3">
                <CardBody>
                    <div className={" row"} >

                        <div className='col-12'>
                            <Formik
                                initialValues={props.data}
                                enableReinitialize={true}
                                innerRef={formRef}
                                onSubmit={(values, { setSubmitting }) => {

                                    setSubmitting(true);
                                    props.next(values)
                                  

                                }}

                                const validationSchema={Yup.object().shape({
                                    // compensation: Yup.string().required("* Compensation is required"),
                                    // activeDays: Yup.string().required("* Duration is required"),
                                     //duration: Yup.string().required("* Duration is required"),
                                     //startDate: Yup.date().required("* Project Start Date is required"),
                                     //endDate: Yup.date().required("* Project End Date is required").min(Yup.ref('startDate'), "End date be before start date"),
                                })}

                            >
                                {props => {
                                    const {
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit
                                    } = props;

                                    
                                    const endDate = new Date(values.endDate)
                                    const startDate = new Date(values.startDate)
                                    values.startDate = startDate.getFullYear()  + "-" + ("0" + (startDate.getMonth()+1)).slice(-2) + "-" + ("0" + (startDate.getDate())).slice(-2)
                                    values.endDate = endDate.getFullYear() + "-" + ("0" + (endDate.getMonth() +1)).slice(-2) + "-" + ("0" + (endDate.getDate())).slice(-2)
                                    
                                     
                                    return (
                                        <form autoComplete="off" onSubmit={handleSubmit}>

                                            {/* Start of form */}
                                            <div className='row'>
                                                <div className="col-md-6 col-sm-12">
                                                    <FormGroup>
                                                        <Label for="compensation">Compensation</Label>
                                                        <Input type="text" name="compensation" id="compensation"
                                                            className={errors.compensation && touched.compensation ? styles.is_invalid : 'valid'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.compensation}
                                                        />
                                                        {errors.compensation && touched.compensation && (
                                                            <div>
                                                                {errors.compensation}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <FormGroup>
                                                        <Label for="durationDays">Duration Days</Label>
                                                        <Input type="number" name="activeDays" id="durationDays" className={errors.activeDays && touched.activeDays ? styles.is_invalid : 'valid'}
                                                            onChange={(e) => { setFieldValue("activeDays", e.target.value); calculateEndDate(values.startDate, e.target.value) }}
                                                            onBlur={handleBlur}
                                                            value={values.activeDays}
                                                        />
                                                        {errors.activeDays && touched.activeDays && (
                                                            <div>
                                                                {errors.activeDays}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <FormGroup>
                                                        <Label for="durationPerDay">Duration Per Day ( Minutes )</Label>
                                                        <Input type="number" name="duration" id="durationPerDay"
                                                            className={errors.duration && touched.duration ? styles.is_invalid : 'valid'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.duration}
                                                        />
                                                        {errors.duration && touched.duration && (
                                                            <div>
                                                                {errors.duration}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-12">

                                                    <FormGroup>
                                                        <Label for="projectStartDate">Project Start Date</Label>
                                                        <Input type="date" name="startDate" id="projectStartDate"
                                                            className={errors.startDate && touched.startDate ? styles.is_invalid : 'valid'}
                                                            onChange={(e) => { setFieldValue('startDate', e.target.value); calculateEndDate(e.target.value, values.activeDays)
                                                             }}
                                                            onBlur={handleBlur}
                                                            value={values.startDate}
                                                        />
                                                        {errors.startDate && touched.startDate && (
                                                            <div>
                                                                {errors.startDate}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FormGroup>
                                                        <Label for="projectEndDate">Project End Date</Label>
                                                        <Input type="date" name="endDate" id="projectEndDate" 
                                                            className={errors.endDate && touched.endDate ? styles.is_invalid : 'valid'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.endDate}
                                                        />
                                                        {errors.endDate && touched.endDate && (
                                                            <div>
                                                                {errors.endDate}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>

                                                <div className='col-md-6' />

                                                <div className='col-12'>
                                                    <Button className={` bg-light text-muted float-left ml-3`} type='button' onClick={() => previous(values)}>
                                                        Previous
                                                    </Button>
                                                    <Button className={` ${styles.bg_blue} float-right mr-3`} type='submit'>
                                                        Finish
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </CardBody>
            </Card>

        </TransitionGroup>
    )
}

export default Finalization
