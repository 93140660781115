import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { getActivity, activityQuestionsCategories, createCategories, getAllCategories, scriptCategories, findCategoryAndDelete } from '../../../../actions/activities_actions'
import { useDispatch } from 'react-redux'
import Select from 'react-select';
import SecureLS from 'secure-ls';
import Swal from 'sweetalert';
import { Card, CardBody, FormGroup, Input, Label, Button } from 'reactstrap';
import styles from '../../../../assets/css/styling.module.css'
import { MdDelete } from "react-icons/md"
export default function Scripting() {
    const params = useParams()
    const ls = new SecureLS()
    const dispatch = useDispatch()
    const [questions, setQuestions] = useState([])
    const [dropDown, setDropDown] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([])
    const [surveyId, setSurveyId] = useState('')
    const [categoryName, setCategoryName] = useState('');
    const [scriptForm, setScriptForm] = useState([])
    const [scripted, set_scripted] = useState(false)
    const [allCategories, setAllCategories] = useState([]) // array of obj
    const [CategoriesNames, setCategoriesNames] = useState([])
    const [selectedOpt, setSelectedOpt] = useState([])
    const [CatQuestions, setCatQuestions] = useState([])
    const [conditionQuestionType, setConditionQuestionType] = useState('')
    const [options, setOptions] = useState([])
    const [conditionQuestion, setConditionQuestion] = useState('')
    const [conditionOption, setConditionOption] = useState('')
     const [isChecked, setIsChecked] = useState(false);
    const [isCheckedScript, setIsCheckedScript] = useState(false);


    useEffect(() => {
        dispatch(getActivity(params.activityid))
            .then(response => {

                set_scripted(response.payload.content.survey.scriptEnabled)
                setSurveyId(response.payload.content.survey._id)

                setQuestions(response.payload.content.survey.pages[0].questions)
            })
            .catch(err => {
                console.log('')
            })


    }, [])

    const addCategory = () => {

        setDropDown([...dropDown, { id: dropDown.length + 1, value: '' }]);

    }
    const clearCategory = () => {
        setDropDown([])
    }
    const addScript = () => {
        setScriptForm([...scriptForm, { id: scriptForm.length + 1, value: '' }])
    }
    const clearScript = () => {
        setScriptForm([])
    }

    const handleChange = (selectedOptions) => {

        setSelectedQuestions(selectedOptions);
    };


    const onSelectCategory = (catId) => {

        let questions = [{}];
        allCategories.map((category) => {
            if (category._id === catId) {
                category.questions.map(question =>
                    questions.push({ question: question.questionText, questionId: question._id, questionOptions: question.options, questionType: question.questionType })
                )
            }
        }

        )

        setCatQuestions(questions)

    }

    const onSelectCategory2 = (catId) => {

        const conditionOption = selectedOpt.map(x => x.value)
        let finalScriptObj = {
            categoryToEdit: catId,
            conditionQuestion: conditionQuestion,
            conditionOption: conditionOption,
            conditionQuestionType: conditionQuestionType
        }

        dispatch(scriptCategories(params.activityid, surveyId, finalScriptObj))
            .then((response) => {
                // setAllCategories(response.payload.content)
                if (response.payload.message === 'Section Scripted') {
                    Swal({
                        title: 'Section Scripted',

                        icon: 'success'
                    })
                }

            }
            )
    }

    const onSelectQuestion = (questionIdd) => {
        //console.log('question id selected ->', questionId)
        setConditionQuestion(questionIdd)

        CatQuestions.map((question) => {

            if (question.questionId === questionIdd) {
                setConditionQuestionType(question.questionType)
                setOptions(question.questionOptions)
            }
        }

        )

    }

    const onSelectOption = (option) => {
        setSelectedOpt(option)

        //setConditionOption(option)

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const categoryObject = {
            questionnaire: surveyId,
            categoryName: categoryName,
            questions: selectedQuestions
        };


        dispatch(createCategories(params.activityid, categoryObject))
            .then((response) => {
                if (response.payload.message === 'Category created successfully') {
                    Swal({
                        title: 'Section Created',

                        icon: 'success'
                    })

                }
                else if (response.payload.content === 'Category not created') {
                    Swal({
                        title: 'Something went wrong',

                        icon: 'error'
                    })
                }
            })
        // .then(res=>
        //     console.log('respons ->', res.payload.content)
        //     )

    };
    useEffect(() => {

        dispatch(getAllCategories(params.activityid, surveyId))
            .then((response) => {

                setAllCategories(response.payload.content)

            }
            )
            .catch(err => {
                console.log('')
            })

    }, [surveyId, allCategories])

    const deleteSection = (catId) => {


        dispatch(findCategoryAndDelete(catId))
            .then((response) => {

                if (response.payload.message === 'One Category deleted successfully') {
                    Swal({
                        title: 'Section Deleted',

                        icon: 'success'
                    })
                }
            })
            .catch(err => { })
    }

    const getQuestionText = (conditionQuestion_id) => {
        const foundQuestion = questions.find((question) => question._id === conditionQuestion_id);

        if (foundQuestion) {

            const cleanText = foundQuestion.questionText
                .replace(/<\/?span>/g, "")        // Remove <span> and </span>
                .replace(/&nbsp;/g, "")           // Remove &nbsp;
                .replace(/<\/?strong>/g, "");
            return cleanText;
        }

        return "";
    }

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setIsChecked(checked);

        if (checked) {
            addCategory();
        }
        if (!checked) {
            clearCategory();
        }
    };

    const handleCheckboxChange2 = (event) => {
        const checked = event.target.checked;
        setIsCheckedScript(checked);

        if (checked) {
            addScript();
        }
        if (!checked) {
            clearScript();
        }
    }

    return (
        // <>
        //     <div style={{ height: '150rem' }}>
        //         <Button className='my-5 mx-5' onClick={() => addCategory()}>Add a Section</Button>
        //         {dropDown.map((dropDown, index) => (
        //             <div key={index} className='mx-5 my-5'>
        //                 <form onSubmit={handleSubmit}>
        //                     <div className="form-group">
        //                         <label>Section Name</label>
        //                         <input
        //                             type="text"
        //                             className="form-control"
        //                             placeholder="Enter Section Name"
        //                             value={categoryName}
        //                             onChange={(e) => setCategoryName(e.target.value)}
        //                         />
        //                     </div>

        //                     <Select
        //                         isMulti
        //                         name="Questions"
        //                         options={questions.map(question => ({ value: question._id, label: question.questionText && question.questionText != undefined ? question.questionText.replace(/<span>|<\/span>/g, '') : '', questionOptions: question.options, questionType: question.questionType, required: question.required, questionRows: question.rows, questionCol: question.columns, questionFile: question.questionFile && question.questionFile }))}
        //                         placeholder='Select Question'
        //                         className="basic-multi-select"
        //                         classNamePrefix="Select"
        //                         onChange={handleChange}
        //                     />

        //                     <Button type="submit" className='my-2'>Submit</Button>
        //                 </form>
        //             </div>
        //         ))}

               
        //         <Button className='my-5 mx-5' onClick={() => addScript()}>Script a Section</Button>
        //         {scriptForm.map((form, index) => (
        //             <div className="form-group container my-5">
        //                 <div>
        //                     <label>If a Respondent </label>
        //                 </div>
        //                 <label >In a Section:</label>

        //                 <Input type="select" name="category" id="category"
        //                     className={`form-control`}
        //                     onInput={(e) => onSelectCategory(e.target.value)}
        //                 >
        //                     <option value="">Select</option>

        //                     {
        //                         allCategories && allCategories.map((category, index) => {
        //                             return <option key={index} value={category._id}>{category.categoryName}</option>
        //                         })
        //                     }
        //                 </Input>

        //                 <label className='mt-3'>In a Question:</label>

        //                 <Input type="select" name="category" id="category"
        //                     className={`form-control`}
        //                     onInput={(e) => onSelectQuestion(e.target.value)}
        //                 >
        //                     <option value="">Select</option>

        //                     {
        //                         CatQuestions && CatQuestions.map((question, index) => {

        //                             return <option key={index} value={question.questionId}>{question.question && question.question != undefined ? question.question.replace(/<span>|<\/span>/g, '') : ''}</option>
        //                         })
        //                     }
        //                 </Input>
                       
        //                 <label className='mt-3'>Selects Option</label>

                      
        //                 <Select

        //                     isMulti
        //                     name="interests"

        //                     options={options.map(opt => ({ value: opt, label: opt }))}
        //                     value={selectedOpt}
        //                     placeholder='Select options'
        //                     className="basic-multi-select"
        //                     classNamePrefix="Select"
        //                     onChange={onSelectOption}

        //                 />


        //                 <label className='mt-3'>Show Section</label>

        //                 <Input type="select" name="category" id="category"
        //                     className={`form-control`}
        //                     onInput={(e) => onSelectCategory2(e.target.value)}
        //                 >
        //                     <option value="">Select</option>

        //                     {
        //                         allCategories && allCategories.map((category, index) => {
        //                             return <option key={index} value={category._id}>{category.categoryName}</option>
        //                         })
        //                     }
        //                 </Input>

        //             </div>


        //         ))}
        //         {scripted === true &&
        //             <div className='col-12'>
        //                 <Label for="sysCountries">All Sections</Label>
        //                 <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
        //                     {
        //                         allCategories && allCategories.map(category => {
        //                             return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
        //                                 <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{category.categoryName}</h6>


        //                             </div>
        //                         })
        //                     }

        //                 </div>
        //             </div>
        //         }
        //     </div>


        // </>

        <>
        <div style={{ height: '150rem' }}>
            {/* <Button className='my-5 mx-5' onClick={() => addCategory()}>Add a Section</Button> */}
            <div className='d-flex justify-content-center align-items-center space-around my-4'>
                <div>
                    <input className='mx-4'
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <Label>Add a Section</Label>
                </div>
                <div>
                    <input className='mx-4'
                        type="checkbox"
                        checked={isCheckedScript}
                        onChange={handleCheckboxChange2}
                    />
                    <Label>Script a Section</Label>
                </div>
            </div>
            {dropDown.map((dropDown, index) => (
                <div key={index} className='mx-5'>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className='mt-4' style={{ fontSize: '1.2em', fontWeight: '500' }}>Section Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Section Name"
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                            />
                        </div>

                        <Select
                            isMulti
                            name="Questions"
                            options={questions.map(question => ({ value: question._id, label: question.questionText && question.questionText != undefined ? question.questionText.replace(/<span>|<\/span>/g, '') : '', questionOptions: question.options, questionType: question.questionType, required: question.required, sec: question.sec, questionRows: question.rows, questionCol: question.columns, questionFile: question.questionFile && question.questionFile, questionFilesLimit: question.filesLimit, questionFilesSize: question.filesSize }))}
                            placeholder='Select Question'
                            className="basic-multi-select"
                            classNamePrefix="Select"
                            onChange={handleChange}
                        />

                        <Button type="submit" className='my-2'>Submit</Button>
                    </form>
                </div>
            ))}

            {/* scripting */}
            {/* <Button className='my-5 mx-5' onClick={() => addScript()}>Script a Section</Button> */}
            {scriptForm.map((form, index) => (
                <div className="form-group container my-5">
                    {/* <div>
                        <label>If a Respondent Comes</label>
                    </div> */}
                    <label style={{ fontSize: '1.2em', fontWeight: '500' }}>If a Respondent Comes in a Section:</label>

                    <Input type="select" name="category" id="category"
                        className={`form-control`}
                        onInput={(e) => onSelectCategory(e.target.value)}
                    >
                        <option value="">Select a Section</option>

                        {
                            allCategories && allCategories.map((category, index) => {
                                return <option key={index} value={category._id}>{category.categoryName}</option>
                            })
                        }
                    </Input>

                    <label className='mt-4' style={{ fontSize: '1.2em', fontWeight: '500' }}>Having a Question:</label>

                    <Input type="select" name="category" id="category"
                        className={`form-control`}
                        onInput={(e) => onSelectQuestion(e.target.value)}
                    >
                        <option value="">Select a Question</option>

                        {
                            CatQuestions && CatQuestions.map((question, index) => {

                                return <option key={index} value={question.questionId}>{question.question && question.question != undefined ? question.question.replace(/<span>|<\/span>/g, '') : ''}</option>
                            })
                        }
                    </Input>
                    {/* show dropdown of multiple selection instead of single dropdown */}
                    <label className='mt-4' style={{ fontSize: '1.2em', fontWeight: '500' }}>Selects Option/Options</label>

                    {/* <Input type="select" name="category" id="category"
                        className={`form-control`}
                        onInput={(e) => onSelectOption(e.target.value)}
                    >

                        
                        <option value="">Select</option>

                        {
                            options && options.map((option, index) => {
                                return <option key={index} value={option}>{option}</option>
                            })
                        }
                    </Input> */}
                    <Select

                        isMulti
                        name="interests"

                        options={options.map(opt => ({ value: opt, label: opt }))}
                        value={selectedOpt}
                        placeholder='Select options'
                        className="basic-multi-select"
                        classNamePrefix="Select"
                        onChange={onSelectOption}

                    />


                    <label className='mt-4' style={{ fontSize: '1.2em', fontWeight: '500' }}>Then Show Section:</label>

                    <Input type="select" name="category" id="category"
                        className={`form-control`}
                        onInput={(e) => onSelectCategory2(e.target.value)}
                    >
                        <option value="">Select a Section</option>

                        {
                            allCategories && allCategories.map((category, index) => {
                                return <option key={index} value={category._id}>{category.categoryName}</option>
                            })
                        }
                    </Input>

                </div>


            ))}
            {scripted === true &&
                <div className='col-12'>
                    <Label className='mt-4' style={{ fontSize: '1.5em', fontWeight: '500' }}>All Sections</Label>
                    <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem', height: 'auto' }}>
                        {



                            allCategories && allCategories.map(category => {
                                return (

                                    <div
                                        className="pr-5 pl-2 mx-2 my-4 badge text-secondary text-capitalize text-left d-flex flex-column"
                                        style={{
                                            backgroundColor: '#BCE5FF',
                                            height: 'auto',
                                            overflowWrap: 'break-word',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'normal',
                                            maxWidth: '100%'
                                        }}
                                    >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="p-0 mb-4" style={{ fontWeight: '500', overflowWrap: 'break-word', color: '#0069AB' }}>
                                                {category.categoryName}
                                            </h4>
                                            <MdDelete size={25} onClick={() => { deleteSection(category._id) }} style={{ cursor: 'pointer' }} />
                                        </div>
                                        {category.questions.map((question, index) => {
                                            return (
                                                <h6 key={index} style={{ overflowWrap: 'break-word', color: '#0069AB' }}>
                                                    {`Q${index + 1} - ${question.questionText
                                                        .replace(/&nbsp;/g, '')
                                                        .replace(/<\/?strong>/g, '')}`}
                                                </h6>

                                            );
                                        })}
                                        {category.conditionQuestion &&
                                            <div className='mt-4'>
                                                <p style={{ fontSize: '0.9rem', overflowWrap: 'break-word', color: '#0069AB' }}>Current section is scripted for:</p>
                                                <h6 style={{ fontSize: '0.9rem', overflowWrap: 'break-word', color: '#0069AB' }}>{`Q. ${getQuestionText(category.conditionQuestion)}`}</h6>
                                                <ul style={{ paddingLeft: '1.2rem', margin: '0', overflowWrap: 'break-word', color: '#0069AB' }}>
                                                    {category.conditionOption.map((option, index) => {
                                                        return (
                                                            <li key={index} style={{ fontSize: '0.8rem', fontWeight: '500', color: '#0069AB', listStyleType: 'disc' }}>
                                                                {option}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                    </div>


                                );
                            })

                        }

                    </div>
                </div>
            }
        </div>


    </>
    )
}
