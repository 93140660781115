import React, { useState, useEffect } from 'react';
import styles3 from '../../css/newStyling.module.css'
import styles from '../../css/styling.module.css';
import YouTube from 'react-youtube';
import Dropzone from "react-dropzone";
import { getActivityVideo, getActivityQuestionImage } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendActivityReplyAnswers, sendSharableSurveyReplyAnswers, getActivityReplyVideos, sendResponseVideoOnCloud } from '../../../actions/activities_reply_actions';
import SecureLS from 'secure-ls';
import { FaLocationArrow } from "react-icons/fa"
const Question_With_Video_Text = (props) => {

    const [comment, setComment] = useState('')
    const [readComment, setReadComment] = useState('')
    const [videoPreview, setVideoPreview] = useState(null);
    const handleInputChange = (e) => {

        setComment(e.target.value);
    };
    // On Video Select
    const onVideoSelect = (file) => {

        if (file) {

            // Remove Error Message
            document.getElementById(`error-msg-${props.id}`).innerHTML = "";

            for (let i = 0; i < file.length; i++) {

                // File Extension Check
                let fileExtension = file[i].name.split('.').pop();

                if (fileExtension === "mp4") {
                    const filee = file[i];
                    const reader = new FileReader();
                    reader.onload = () => {
                        setVideoPreview(reader.result);
                    };
                    reader.readAsDataURL(filee);

                    


                    props.allContent.pages[props.pageIndex].questions[props.questionIndex].video = [filee]

                    props.setContent({ ...props.allContent });
                    

                }
            }


        }


        else {

            document.getElementById(`error-msg-${props.id}`).innerHTML = `
                <div class="my-2">
                    <div class="alert alert-danger mb-0">
                        Invalid file type
                    </div>
                </div>
            `;
        }

    }

    // on Cancel Video
    const onCancelVideo = (index) => {

        props.allContent.pages[props.pageIndex].questions[props.questionIndex].video.splice(index, 1);
        props.setContent({ ...props.allContent });
        setVideoPreview(null)
        if (index != null) {
            setCancelV('video is cancelled')
        }

    }

    // On Change Text
    // const onChangeText = (value) => {

    //     // props.allContent.pages[props.pageIndex].questions[props.questionIndex].answer = props.textLimit && props.textLimit.max && (!Number.isNaN(parseInt(props.textLimit.max)) ? e.target.value.substring(0, parseInt(props.textLimit.max)) : e.target.value);
    //     // props.setContent({ ...props.allContent });
    //     props.allContent.pages[props.pageIndex].questions[props.questionIndex].answer = (props.textLimit && !Number.isNaN(parseInt(props.textLimit.max))) ? value.substring(0, parseInt(props.textLimit.max)) : value;
    //     props.setContent({ ...props.allContent });

    // }
    const onChangeText = (e) => {
   
      

         props.allContent.pages[props.pageIndex].questions[props.questionIndex].answer = e.target.value
        props.setContent({ ...props.allContent });
        //const value = e.target.value
//setAnswerValue(value)

    }

    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };

    const params = useParams();
    const userId = params.UserId
    const dispatch = useDispatch();
    const pageIndex = props.pageIndex
    const questionIndex = props.questionIndex
    const ls = new SecureLS()
    const user = ls.get('accessUser').user._id
    const [qVideo, setQVideo] = useState({})
    const [videoUpload, setVideoUpload] = useState('')
    const [qImage, setQImage] = useState({})
    const [cancelV, setCancelV] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (params.surveyid || params.ActivityId || params.activityid) {
            dispatch(getActivityVideo(params.surveyid || params.ActivityId || params.activityid, pageIndex, questionIndex))
                .then((response) => {
                    try {
                        //console.log('question video in respodant side survey->', response.payload.content.fileData)
                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('video not available') }
                    setIsLoading(false);

                })
            // dispatch(getActivityQuestionImage(params.ActivityId || params.surveyid || params.activityid))
            //     .then((response) => {
            //         try {
            //            // console.log('question image in respondant side survey->', response.payload.content.fileData)
            //             setQImage(response.payload.content.fileData)
            //         } catch (err) { console.log('image in questionnaire not available') }
            //         setIsLoading(false);
            //     })



        }
        else if (params.evaluationid) {
            dispatch(getActivityVideo(params.evaluationid, pageIndex, questionIndex))
                .then((response) => {
                    try {

                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('video not available') }
                    setIsLoading(false);

                })
            // dispatch(getActivityQuestionImage(params.evaluationid))
            //     .then((response) => {
            //         try {
            //             //console.log('question image long text in respondant side survey->', response.payload.content.fileData)
            //             setQImage(response.payload.content.fileData)
            //         } catch (err) { console.log('image in questionnaire not available') }
            //         setIsLoading(false);
            //     })
        }
    }, []);
  
    const makeLinksOpenInNewTab = (htmlContent) => {
        // Create a DOM element to parse the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;

        // Find all anchor tags within the parsed HTML content
        const anchorTags = tempElement.getElementsByTagName('a');

        // Iterate through the anchor tags and set the target attribute to '_blank'
        for (const anchorTag of anchorTags) {
            anchorTag.setAttribute('target', '_blank');
            // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
            anchorTag.setAttribute('rel', 'noopener noreferrer');
        }

        // Return the modified HTML content
        return tempElement.innerHTML;
    }
    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}
                        {/* {props.questionText} */}
                        {props.questionText &&
                            <span style={{ color: '#000', fontSize: '27px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }
                        {/* Question video */}
                        {props.questionVideo && (
                            <div>

                                <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>

                            </div>
                        )}
                    </p>

                    {/* Question Video */}
                    {
                        props.questionFile &&
                        <>
                            {
                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">
                                                {/* <video width={300} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                    <source src={props.questionFile} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video> */}
                                                {isLoading ? (
                                                    <b>Loading Video...</b>
                                                ) : qVideo.data ? (
                                                    <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                        <ReactPlayer
                                                            url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                            controls
                                                            width='100%'
                                                        />
                                                    </div>
                                                ) : (
                                                    <b>Loading Video...</b>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    /* Question Image */
                                    <div className="mb-3 mt-3">

                                        {qVideo.data &&

                                            // Image Display
                                            <div>
                                                <div className="position-relative">
                                                    <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                            }
                        </>


                    }
                    <div className="mt-3 mx-3">

                        {/* File Limit and File Size */}
                        <small className="text-danger">
                            Max files Limit: {props.filesLimit ? props.filesLimit : "1"} | Max file Size: {props.filesSize ? props.filesSize : "6"} MB
                        </small>

                        {/* Video Display & Dropzone  */}
                        {props.video && props.video.length > 0 ? (

                            // Video Display
                            <div className="row pt-3">

                                {props.video.map((videoSrc, index) => {
                                    return (
                                        <div key={index} className="position-relative mx-3 mb-3">
                                            <span className="questionVideo_close" style={{ marginLeft: '275px', zIndex: '2' }} onClick={(index) => onCancelVideo(index)}>&times;</span>
                                            {videoPreview && videoPreview != null &&
                                                <video width={300} controls className="img-fluid- border" style={{ maxHeight: 500 }}>
                                                    <source src={videoPreview} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            }
                                           
                                        </div>
                                    )
                                })}

                            </div>

                        ) : (

                            // Video Drop Zone 
                            < div className="dropzone-wrapper dropzone-wrapper-sm mb-3">
                                <Dropzone
                                    maxFiles={!Number.isNaN(parseInt(props.filesLimit)) ? parseInt(props.filesLimit) : 0}
                                    maxSize={!Number.isNaN(parseFloat(props.filesSize)) ? (parseFloat(props.filesSize) * 1048576) : (6 * 1048576)}
                                    multiple={!Number.isNaN(parseInt(props.filesLimit)) ? (parseInt(props.filesLimit) > 0 ? true : false) : false}
                                    accept={".mp4"}
                                    onDrop={file => onVideoSelect(file)}

                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="dropzone-content" style={{ cursor: 'pointer' }}>

                                                {videoPreview != null ? (
                                                    // <video controls style={{ width: '50%', height: '50%' }}>
                                                    //     <source src={''} type="video/mp4" />
                                                    //     Your browser does not support the video tag.
                                                    // </video>
                                                    <div></div>
                                                ) : (
                                                    <div className="d-flex">
                                                        <span className="pe-7s-video ml-1 mr-3" style={{ fontSize: "2rem", verticalAlign: "middle" }}></span>
                                                        <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Click to select file to upload.</span>
                                                    </div>)}

                                                {/* <div className="d-flex">
                                                    <span className="pe-7s-video ml-1 mr-3" style={{ fontSize: "2rem", verticalAlign: "middle" }}></span>
                                                    <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Click to select file to upload.</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>

                        )}

                        {/* Text  Area */}
                        <div className="form-group mt-1 mb-3">
                            <label>Answer Field</label>

                            {/* Text Limit */}
                            {props.textLimit && !Number.isNaN(parseInt(props.textLimit.max)) && (
                                <small className="text-danger mx-1">(Max Character Limit: {props.textLimit.max})</small>
                            )}

                            <input className="form-control" type="text" value={props.answer} onChange={onChangeText} placeholder="Write here" />
                        </div>




                    </div>


                    {/* <div className="mt-3 mx-3">
                        {console.log('cancel video ->', cancelV)}
                        {cancelV === '' && readComment ? (
                            <div>
                                <p>{readComment}</p>
                            </div>
                        ) : null}
                        <form onSubmit={handleSubmit} className={`${styles3.comment_form}`}>
                            <input
                                type="text"
                                id={props.id}
                                placeholder="Write an answer"
                                value={comment}
                                onChange={handleInputChange}
                                className={`${styles3.comment_input}`}
                            />
                            <button type="submit" className={`${styles3.submit_button}`}>
                                <span><FaLocationArrow size={23} /></span>
                            </button>
                        </form>
                        </div> */}
                        {/* File Limit and File Size */}
                        {/* <small className="text-danger">
                            Max files Limit: {props.filesLimit ? props.filesLimit : "1"} | Max file Size: {props.filesSize ? props.filesSize : "3"} MB
                        </small> */}

                        {/* Video Display & Dropzone */}


                   

                    {/* Upload related error */}
                    <div id={`error-msg-${props.id}`}></div>

                    {/* Required Answer error */}
                    {props.required && !props.isAnswered && (
                        <div id={`error-msg`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div >
    )

}

export default Question_With_Video_Text;