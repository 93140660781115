import axios from 'axios';
import { BACK_SERVER_URL } from '../config/server.js';
import {REWARD_REQUEST, AVAILABLE_REWARD} from './types.js'



export const create_reward_request = (data,id) => {

    let url = `${BACK_SERVER_URL}api/rewards/create-reward-request/${id}`;

    const request = axios.post(url,data)
        .then(response => response.data);

    return {
        type: REWARD_REQUEST,
        payload: request
    }
}

export const get_reward_requests = (data) => {

    let url = `${BACK_SERVER_URL}api/rewards/get-reward-requests`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_REWARD,
        payload: request
    }
}

export const update_reward_requests = (data,id) => {

    let url = `${BACK_SERVER_URL}api/rewards/update-reward-request/${id}`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: REWARD_REQUEST,
        payload: request
    }
}