import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { Card, CardBody, Label, Input, FormGroup, CardHeader, Button } from "reactstrap";
import styles from '../../../../assets/css/rstyling.module.css'
import { Typeahead } from "react-bootstrap-typeahead";
import DataTable from '../../../../assets/components/datatable/DataTable';
import ResizeDetector from "react-resize-detector";
import { Formik } from 'formik';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { getSpecificOption } from '../../../../actions/settings_actions';
import Swal from 'sweetalert'


const Criteria = (props) => {
    const { previous } = props
    //const params = useParams()
    const dispatch = useDispatch()
    const systemOptionsState = useSelector(state => state.SystemSettings)
    const [Cities, setCities] = useState([]);
    const [CWE, setCWE] = useState([]);
    const Gender = ['Male', 'Female'];

    //const projectState = useSelector(state => state.Project)
    const [error, setErr] = React.useState({
        toAgeErr: "",
        fromAgeErr: ""
    });

    const [age, setAge] = useState({});
    const [selectedGender, setSelectedGender] = React.useState([]);
    const [selectedCities, setSelectedCities] = React.useState([]);
    const [selectedCWE, setSelectedCWE] = React.useState([]);
    const [selectedAgeRange, setAgeRange] = React.useState([]);
    const [tableShow, setTableShow] = React.useState(false);

    const AgeRanges = [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70];

    const [data, setData] = useState({
        gender: {
            options: [],
            selectedIndex: 0
        },
        cities: {
            options: [],
            selectedIndex: 0
        },
        ageRange: {
            options: []
        },
        incomeRange: {
            options: []
        },
        gridSets: []
    });


    // For display grid and initialize grid sets based on Income Ranges (as row) and Age Ranges (as column)
    const setGridSets = () => {

        const cities = data.cities.options.map(city => {
            if (data.gender.options.includes(city.name))
                return city._id
        })
        const income = data.incomeRange.options.map(income => {
            if (data.incomeRange.options.includes(income.name))
                return income._id
        })

        setData({ ...data, cities: { ...data.cities, options: cities }, incomeRange: { ...data.income, options: income } })

        if (data.gridSets.length > 0) {
            const tempVal = [...data.gridSets]
            for (let i = 0; i < selectedGender.length; i++) {

                for (let j = 0; j < selectedCities.length; j++) {

                    // If exists
                    if (data.gridSets[i] && data.gridSets[i].selectedGender === i && data.gridSets[i].selectedCity === j) {

                        for (let row = 0; row < selectedGender.length * selectedCities.length; row++) {

                            if (data.gridSets[row] && data.gridSets[row].incomeAgeGrid) {

                                // if selected CWE are not equal to grid CWE
                                if (data.gridSets[row].incomeAgeGrid.length !== selectedCWE.length) {

                                    const rowCount = parseInt(selectedCWE.length) - parseInt(data.gridSets[row].incomeAgeGrid.length)
                                    // Adding Rows
                                    if (rowCount > 0) {
                                        for (let newRow = 0; newRow < rowCount; newRow++) {
                                            tempVal[row].incomeAgeGrid.push(new Array(selectedCWE.length).fill(0))
                                        }
                                        data.incomeRange.options = selectedCWE.map(cwe => cwe.name)
                                    } else {
                                        // for deleting row
                                        const CWE = selectedCWE.map(cwe => cwe.name)
                                        let deleteRowIndex
                                        // Finding Index
                                        data.incomeRange.options.map((incomeRange, index) => {
                                            if (!CWE.includes(incomeRange)) {
                                                deleteRowIndex = index
                                            }
                                        })

                                        // removing value
                                        data.gridSets.map((grid, option) => {
                                            tempVal[option].incomeAgeGrid.splice(deleteRowIndex, 1)
                                        })
                                        data.incomeRange.options = CWE
                                    }

                                }


                                for (let col = 0; col < selectedAgeRange.length; col++) {

                                    if (data.gridSets[row].incomeAgeGrid[col]) {

                                        // If selected age ranges are not equal to grid age ranges
                                        if (data.gridSets[row].incomeAgeGrid[col].length !== selectedAgeRange.length) {
                                            const colCount = parseInt(selectedAgeRange.length) - parseInt(data.gridSets[row].incomeAgeGrid[col].length)

                                            // Add column
                                            if (colCount > 0) {
                                                for (let newCol = 0; newCol < colCount; newCol++) {
                                                    tempVal[row].incomeAgeGrid[col].push(0)
                                                }
                                                data.ageRange.options = selectedAgeRange

                                            } else {
                                                // for deleting col
                                                let deleteColIndex
                                                // Finding Index
                                                data.ageRange.options.map((ageRange, index) => {
                                                    if (!selectedAgeRange.includes(ageRange)) {
                                                        deleteColIndex = index
                                                    }
                                                })

                                                // removing value
                                                data.gridSets.map((grid, option) => {
                                                    grid.incomeAgeGrid.map((rows, rowIndex) => {
                                                        tempVal[option].incomeAgeGrid[rowIndex].splice(deleteColIndex, 1)
                                                    })
                                                })
                                                data.ageRange.options = selectedAgeRange
                                            }

                                        }

                                    } else {
                                        // Fill empty col if new col (age Range) added
                                        if (tempVal[row].incomeAgeGrid.length < selectedCWE.length) {
                                            tempVal[row].incomeAgeGrid.push(new Array(selectedCWE.length).fill(0))
                                        }
                                    }

                                }
                            } else {
                                // Fill empty rows if new row (cwe) added
                                const found = tempVal.some(el => el.selectedCity === j && el.selectedGender === i);
                                if (!found) {
                                    tempVal.push({
                                        selectedGender: i,
                                        selectedCity: j,
                                        incomeAgeGrid: (new Array(selectedCWE.length)).fill().map(function () { return new Array(selectedAgeRange.length).fill(0); })
                                    })

                                }

                            }

                        }
                    } else {

                        const found = tempVal.some(el => el.selectedCity === j && el.selectedGender === i);

                        if (!found) {
                            tempVal.push({
                                selectedGender: i,
                                selectedCity: j,
                                incomeAgeGrid: (new Array(selectedCWE.length)).fill().map(function () { return new Array(selectedAgeRange.length).fill(0); })
                            })

                        }


                    }

                }

            }

            tempVal.sort((a, b) => (a.selectedGender > b.selectedGender) ? 1 : -1)
            data.gridSets = tempVal
        }

        const emptyGrid = []
        // When grid is empty
        if (data.gridSets.length === 0) {
            for (let i = 0; i < selectedGender.length; i++) {

                for (let j = 0; j < selectedCities.length; j++) {
                    emptyGrid.push({
                        selectedGender: i,
                        selectedCity: j,
                        incomeAgeGrid: (new Array(selectedCWE.length)).fill().map(function () { return new Array(selectedAgeRange.length).fill(0); })
                    })

                }
            }
            data.gridSets = emptyGrid
            data.ageRange = {
                selectedIndex: 0,
                options: selectedAgeRange
            }
            data.incomeRange = {
                selectedIndex: 0,
                options: selectedCWE.map(city => city.name ? city.name : city)
            }
        }

        setData({ ...data });

    }
    
    const getSystemOptions = () => {
        dispatch(getSpecificOption({ options: ["Income", "Cities"] }))
            .then(response => {
                setCities(response.payload.content.cities)
                setCWE(response.payload.content.incomeRange)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                });
            })
    }

    useEffect(() => {
        getSystemOptions()
    }, [])

    // Getting data for edit or clone 
    useEffect(() => {
        if (props.isEdit || props.isCopy) {
            const project = props.data.criteria
            const selectedCities = systemOptionsState.specificOptions && systemOptionsState.specificOptions.cities.map(city => {
                if (project.cities.includes(city.name)) {
                    return city
                }
            })
            const selectedIncome = systemOptionsState.specificOptions && systemOptionsState.specificOptions.incomeRange.map(income => {
                if (project.incomeRanges.includes(income.name)) {
                    return income
                }
            })
            // setData({ ...data, gender: {...data.gender, options: project.genders}, cities:{...data.cities, options: project.cities}, incomeRange: project.incomeRanges, ageRange: project.ageRanges, gridSets: project.gridSets })
            setSelectedCities(selectedCities.filter(c => c))
            setSelectedGender(project.genders)
            setSelectedCWE(selectedIncome.filter(i => i))
            setAgeRange(project.ageRanges)

            const grid = {
                gender: {
                    options: project.genders,
                    selectedIndex: 0
                },
                cities: {
                    options: project.cities,
                    selectedIndex: 0
                },
                ageRange: {
                    options: project.ageRanges
                },
                incomeRange: {
                    options: project.incomeRanges
                },
                gridSets: project.gridSets
            }
            setData(grid)
            project.gridSets.length > 0 && setTableShow(true)

        }
    }, [systemOptionsState]);

    // For removing errors
    const resetErr = () => {
        setErr({
            toAgeErr: "",
            fromAgeErr: ""
        })
    }
    // For add Age Ranges
    const addAgeRange = () => {
        resetErr()
        if ((age.from === "" || age.from === null || age.from === undefined) && (age.to === "" || age.to === null || age.to === undefined)) {
            setErr({ ...error, toAgeErr: 'To Age is required', fromAgeErr: 'From Age is req' })
        } else if (age.from === "" || age.from === null || age.from === undefined) {
            setErr({ ...error, fromAgeErr: 'From Age is required', toAgeErr: "" })
        } else if (age.to === "" || age.to === null || age.to === undefined) {
            setErr({ ...error, toAgeErr: 'To Age is required', fromAgeErr: "" })
        } else if (age.from >= age.to) {
            setErr({ ...error, fromAgeErr: 'From age cannot be greater than to age', toAgeErr: 'To age cannot be smaller than from age' })
        } else {
            if (selectedAgeRange.indexOf(`${age.from} - ${age.to}`) === -1) {
                setAgeRange([...selectedAgeRange, `${age.from} - ${age.to}`])
            } else {
                setErr({ ...error, fromAgeErr: 'Cannot add same value twice', toAgeErr: 'Cannot add same value twice' })
            }
        }
    }

    // For Remove Age Range
    const removeAgeRange = (ageRange) => {
        const updatedAgeRange = selectedAgeRange.filter(value => {
            return value !== ageRange;
        });

        setAgeRange(updatedAgeRange)
    }

    // Call when a tab change
    const onTabChange = (index, whichData) => {
        if (whichData === 'gender')
            setData({ ...data, gender: { ...data.gender, selectedIndex: index } });
        else if (whichData === 'cities')
            setData({ ...data, cities: { ...data.cities, selectedIndex: index } });
        else if (whichData === 'age range')
            setData({ ...data, ageRange: { ...data.ageRange, selectedIndex: index } });
        else if (whichData === 'income range')
            setData({ ...data, incomeRange: { ...data.incomeRange, selectedIndex: index } });
    }

    // Apply changes to data when gender values change
    useEffect(() => {
        let _temp_gridSets = [...data.gridSets];
        let b = selectedGender
        if (data.gender.options.length > selectedGender.length) {
            let index
            let a = data.gender.options
            a.filter(gender => {
                if (!b.includes(gender))
                    index = a.indexOf(gender);
            });
            // For remove city
            for (let i = 0; i < _temp_gridSets.length; i++) {

                if (_temp_gridSets[i].selectedGender === index)
                    _temp_gridSets.splice(i, 1);
                // Sort gridsets by selectedCity and SelectedGender
                _temp_gridSets.sort((a, b) => a.selectedGender - b.selectedGender);
                _temp_gridSets.sort((a, b) => a.selectedCity - b.selectedCity);
                // // // For re-arrange selectedCity indexes
                for (let j = 0; j < selectedGender.length; j++) {
                    let c = 0;
                    for (let k = 0; k < _temp_gridSets.length; k++) {
                        if(_temp_gridSets[k].selectedGender === j) {
                            _temp_gridSets[k].selectedGender = j
                            _temp_gridSets[k].selectedCity = c;
                            c++;
                        }
                    
                    }
                }
            }

        }
        setData({ ...data, gridSets: [..._temp_gridSets], gender: { ...data.gender, options: b } });
    }, [selectedGender])
    // Apply changes to data when Cities values change
    useEffect(() => {
        let _temp_gridSets = [...data.gridSets];
        let b = selectedCities.map(city => city.name);
        if (data.cities.options.length > selectedCities.length) {
            let index
            let a = data.cities.options
            a.filter(city => {
                if (!b.includes(city))
                    index = a.indexOf(city);
            });
            // For remove city
            for (let i = 0; i < _temp_gridSets.length; i++)
                if (_temp_gridSets[i].selectedCity === index)
                    _temp_gridSets.splice(i, 1);
            // Sort gridsets by selectedCity and SelectedGender
            _temp_gridSets.sort((a, b) => a.selectedCity - b.selectedCity);
            _temp_gridSets.sort((a, b) => a.selectedGender - b.selectedGender);
            // For re-arrange selectedCity indexes
            for (let j = 0; j < selectedGender.length; j++) {
                let c = 0;
                for (let k = 0; k < _temp_gridSets.length; k++) {
                    if (_temp_gridSets[k].selectedGender === j) {
                        _temp_gridSets[k].selectedCity = c;
                        c++;
                    }
                }
            }
        }
        setData({ ...data, gridSets: [..._temp_gridSets], cities: { ...data.cities, options: b } });
    }, [selectedCities]);

    // Apply changes to data when Age range values change
    // useEffect(() => {
    //     setData({ ...data, ageRange: { ...data.ageRange, options: selectedAgeRange } })
    // }, [selectedAgeRange])

    // Apply changes to data when income range values change
    // useEffect(() => {
    //     setData({ ...data, incomeRange: { ...data.incomeRange, options: selectedCWE.map(city => city.name ? city.name : city) } })
    // }, [selectedCWE])

    const [isCalculate, setCal] = useState(false)
    const calculateCriteria = (type, selectedGenderIndex, selectedCityIndex) => {

        if (isCalculate) {
            if (type === 'all') {
                let sum = 0;
                if (data.gridSets.length > 0) {
                    for (let i = 0; i < data.gridSets.length; i++) {
                        for (let j = 0; j < data.gridSets[i].incomeAgeGrid.length; j++) {
                            sum += parseInt(_.sum(data.gridSets[i].incomeAgeGrid[j]))
                        }

                    }
                }
                return sum
            } else if (type === 'gender') {
                let sum = 0;
                if (data.gridSets.length > 0) {
                    for (let i = 0; i < data.gridSets.length; i++) {
                        if (data.gridSets[i].selectedGender === selectedGenderIndex) {
                            for (let j = 0; j < data.gridSets[i].incomeAgeGrid.length; j++) {
                                sum += parseInt(_.sum(data.gridSets[i].incomeAgeGrid[j]))
                            }
                        }

                    }
                }
                return sum
            } else {
                let sum = 0
                if (data.gridSets.length > 0) {
                    for (let i = 0; i < data.gridSets.length; i++) {
                        if (data.gridSets[i].selectedGender === selectedGenderIndex && data.gridSets[i].selectedCity === selectedCityIndex) {
                            for (let j = 0; j < data.gridSets[i].incomeAgeGrid.length; j++) {
                                sum += parseInt(_.sum(data.gridSets[i].incomeAgeGrid[j]))
                            }

                        }

                    }
                }
                return sum
            }
        }


    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                    transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false} className="mb-3">

                    <Card className="main-card mb-3 mt-3">
                        <CardHeader className={styles.text_blue}>Grid Criteria Setup</CardHeader>
                        <CardBody>
                            <div className="row">
                                <div className='col-12'>
                                    <Formik
                                        initialValues={props.data}
                                        enableReinitialize={true}
                                        onSubmit={(values, { setSubmitting }) => {

                                            setSubmitting(true);

                                            const newValues = {
                                                criteria: {
                                                    genders: data.gender.options,
                                                    cities: data.cities.options,
                                                    ageRanges: data.ageRange.options,
                                                    incomeRanges: data.incomeRange.options,
                                                    gridSets: data.gridSets,
                                                }
                                            }
                                            props.next(newValues)

                                        }}

                                    >
                                        {props => {
                                            const {
                                               
                                                
                                                
                                                handleSubmit
                                            } = props;
                                            return (
                                                <form autoComplete="off" onSubmit={handleSubmit}>

                                                    {/* Start of form */}
                                                    <div className='row'>

                                                        <div className="col-md-12 col-sm-12">
                                                            <FormGroup className="light_typeahead">
                                                                <Label for="fromAge">Age Ranges</Label><br />
                                                                {
                                                                    selectedAgeRange.map(ageRange => {
                                                                        return <span key={ageRange} className="mb-2 mr-2 rbt-token" style={{ backgroundColor: 'rgb(232, 240, 254)' }}>
                                                                            {ageRange}
                                                                            <span className="ml-2" onClick={() => removeAgeRange(ageRange)} style={{ cursor: 'pointer' }}>x</span>
                                                                        </span>
                                                                    })
                                                                }
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <FormGroup>
                                                                <Label for="fromAge">From Age</Label>
                                                                <Input type="select" name="fromAge" id="fromAge" className="form-control valid" onChange={(e) => { setAge({ ...age, from: e.target.value }) }}>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        AgeRanges.map(ageRange => {
                                                                            return <option key={ageRange} value={ageRange}>{ageRange}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                                {
                                                                    error.fromAgeErr &&
                                                                    <small className="pl-1 text-danger">{error.fromAgeErr}</small>
                                                                }
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-5 col-sm-12">
                                                            <FormGroup>
                                                                <Label for="toAge">To Age</Label>
                                                                <Input type="select" name="toAge" id="toAge" className='form-control valid' onChange={(e) => { setAge({ ...age, to: e.target.value }) }}>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        AgeRanges.map(ageRange => {
                                                                            return <option key={ageRange} value={ageRange}>{ageRange}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                                {
                                                                    error.toAgeErr &&
                                                                    <small className="pl-1 text-danger">{error.toAgeErr}</small>
                                                                }
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-1 align-self-center pt-3">
                                                            <Button onClick={() => addAgeRange()} className={styles.bg_blue}>Add</Button>
                                                        </div>
                                                        <div className="divider"></div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <FormGroup className="light_typeahead custom_drop">
                                                                <Label for="gender">Gender</Label>
                                                                <Typeahead
                                                                    id="gender"
                                                                    labelKey="gender"
                                                                    multiple
                                                                    onChange={setSelectedGender}
                                                                    selected={selectedGender}
                                                                    options={Gender}
                                                                    placeholder="Gender"
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup className="light_typeahead custom_drop">
                                                                <Label for="city">City</Label>
                                                                <Typeahead
                                                                    id="city"
                                                                    multiple
                                                                    onChange={setSelectedCities}
                                                                    options={Cities}
                                                                    placeholder="City"
                                                                    selected={selectedCities}
                                                                    labelKey={(option) => `${option.name}`}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup className="light_typeahead custom_drop">
                                                                <Label for="CWE">CWE Income Range (SEC)</Label>
                                                                <Typeahead
                                                                    id="CWE"
                                                                    multiple
                                                                    onChange={setSelectedCWE}
                                                                    options={CWE}
                                                                    placeholder="CWE Income Range"
                                                                    selected={selectedCWE}
                                                                    labelKey={(option) => `${option.name}`}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-12 align-self-center pt-3 text-right" >
                                                            <Button className={width <= 350 ? `${styles.bg_blue} px-5 w-100` : ` ${styles.bg_blue} px-5`} onClick={() => {
                                                                setTableShow(true);
                                                                setGridSets()
                                                            }}>Generate Grid</Button>

                                                        </div>

                                                        {
                                                            tableShow &&
                                                            <div className='col-12'>
                                                                <Button className={` bg-light text-muted float-left ml-3`} type='button' onClick={() => previous({
                                                                    criteria: {
                                                                        genders: data.gender.options,
                                                                        cities: data.cities.options,
                                                                        ageRanges: data.ageRange.options,
                                                                        incomeRanges: data.incomeRange.options,
                                                                        gridSets: data.gridSets,
                                                                    }
                                                                })}>
                                                                    Previous
                                                                </Button>
                                                                <Button className={` ${styles.bg_blue} float-right mr-3`} type='submit'>
                                                                    Next
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>

                                                </form>

                                            )
                                        }}


                                    </Formik>
                                </div>

                            </div>
                        </CardBody>
                    </Card>

                    {
                        tableShow &&

                        <>
                            {/* Data Table Component */}
                            <DataTable
                                data={data}
                                setData={setData}
                                onTabChange={onTabChange}
                                width={width}
                                isEditable={true}
                            />

                            <div className="container-fluid mt-2">
                                <div className="row">
                                    {width > 470 &&
                                        <>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </>
                                    }
                                    <div className={`${styles.text} col text-right`} style={{ backgroundColor: "#eee", color: "b" }}>
                                        Total {Gender.length > 0 && Gender[data.gender.selectedIndex]}s in {Cities.length > 0 && Cities[data.cities.selectedIndex].name}
                                    </div>
                                    <div className={`${styles.text} col text-left`} style={{ backgroundColor: "#eee" }}>
                                        {calculateCriteria('city', data.gender.selectedIndex, data.cities.selectedIndex)}
                                    </div>

                                </div>
                                <div className="row">
                                    {width > 470 &&
                                        <>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </>
                                    }
                                    <div className={`${styles.text} col text-right`} style={{ backgroundColor: "#eee" }}>
                                        Total {Gender.length > 0 && Gender[data.gender.selectedIndex]}s
                                    </div>
                                    <div className={`${styles.text} col text-left`} style={{ backgroundColor: "#eee" }}>
                                        {calculateCriteria('gender', data.gender.selectedIndex)}
                                    </div>


                                </div>
                                <div className="row">
                                    {width > 470 &&
                                        <>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </>
                                    }
                                    <div className={`${styles.text} col text-right`} style={{ backgroundColor: "#eee", color: "b" }}>
                                        Total Respondents
                                    </div>
                                    <div className={`${styles.text} col text-left`} style={{ backgroundColor: "#eee" }}>
                                        {calculateCriteria('all')}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 align-self-center pt-3 text-right">
                                <Button className={`${styles.bg_blue} mr-2`} onClick={() => setCal(true)}>Save Criteria</Button>
                            </div>
                        </>


                    }

                </TransitionGroup>
            )}
        />
    )
}

export default Criteria