import React, {useState, useEffect} from 'react'
import Listing from "../../../assets/components/listing/Listing";
import Eyeicon from '../../../assets/images/eye_icon.png';
import ResizeDetector from "react-resize-detector";
import { TransitionGroup } from 'react-transition-group';
import styles from '../../../assets/css/rstyling.module.css'
import Projecticon from '../../../assets/images/project_header_icon.png'
import { Card, CardBody } from "reactstrap";
import PageHeader from '../../../assets/components/pageheader/PageHeader'
import LoadingOverlay from 'react-loading-overlay-ts';
import Loader from "react-loaders";
import { useDispatch, useSelector } from 'react-redux';
import {get_reward_requests, update_reward_requests} from '../../../actions/rewards_actions'
import SettingIcon from '../../../assets/images/settings_icon_sm.png'


export default function RewardsTable() {
    const dispatch = useDispatch()
const [isLoading, setLoading] = useState(true)
const [processing, set_processing] = useState({status: false, user:'', rewardId:''})
const [completed ,set_completed] = useState({status: false, user:'', rewardId:''})
const [rejected ,set_rejected] = useState({status:false, user:'', rewardId:''})
const [scheduled, set_scheduled] = useState({status:false, user:'', rewardId:''})
const [updateResults, set_updateResults] = useState(false)
const [rewardsData, set_rewardsData] = useState([])
 const rewardState = useSelector(state => state.Reward)


    const [rewardsCol, setRewardsColumn] = useState([
        
        { Name: "Email", Type: 'string', key: 'rewardEmail', fixWidth:100 },
        { Name: "Redeem Points", Type: 'number', key: 'rewardPoints' },
        { Name: "Redeem Cash", Type: 'string', key: 'rewardCash' },
        { Name: "Status", Type: 'string', key: 'rewardStatus' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon, // to view user details
                   
                    type: 'dropdown',
                    droplist: [
                        { Name: 'View User Details', viewType:'reward-user-details', url: "/admin/view-user/rewards" },
                        // { Name: 'Respondents', url: "/admin/project/participants" },
                        //{ Name: 'View Details', viewType:'survey-details', url: "/admin/minisurvey-activity" },
                       
                    ]
                },
                
                {
                    icon: SettingIcon, // this will be used to change status
                    type: 'dropdown',
                    droplist:[
                        { Name: 'Processing', viewType:'approve-reward',  setProcessing: set_processing, setCompleted: set_completed, setRejected: set_rejected, setScheduled: set_scheduled},
                        { Name: 'Completed', viewType:'complete-reward', setCompleted: set_completed, setProcessing: set_processing, setRejected: set_rejected,  setScheduled: set_scheduled},
                        { Name: 'Rejected', viewType:'reject-reward',  setRejected: set_rejected, setProcessing: set_processing, setCompleted: set_completed, setScheduled: set_scheduled},
                        { Name: 'Scheduled', viewType:'schedule-reward',  setScheduled: set_scheduled, setProcessing: set_processing, setCompleted: set_completed, setRejected: set_rejected},
                    ]
                },
                // {
                //     icon: TickIcon,
                //     setID: setTickID,
                //     type: 'complete'
                // }
               
               
               
                
            ]
        },
    ])


   

        const getRewardsListing =(currentPage = 1, dataLimit = 5) =>{
      
        dispatch(get_reward_requests({ page: currentPage, limit: dataLimit }))
        .then((response) => {
        
            //console.log('initial render ->',response.payload.content.rewards)
            set_rewardsData([...response.payload.content.rewards])
            set_completed({status: false, user:'', rewardId:''})
                set_processing({status: false, user:'', rewardId:''})
                set_rejected({status: false, user:'', rewardId:''})
                set_scheduled({status: false, user:'', rewardId:''})
            
            setLoading(false)
           
        } )
        .catch((error) => {
            console.error(error)
            setLoading(false)
        } )

    }
      
    useEffect(() => {
        getRewardsListing()
    },[])


const statuses = [
    { key: 'Processing', data: processing },
    { key: 'Completed', data: completed },
    { key: 'Rejected', data: rejected },
    { key: 'Scheduled', data: scheduled },
  ];

  statuses.forEach(({ key, data }) => {
    if (data.status === true && data.user !== '' && data.rewardId !== '') {
      const payload = {
        status: key,
        rewardId: data.rewardId,
      };
  
      dispatch(update_reward_requests(payload, data.user))
        .then((response) => {
           
            
            getRewardsListing(rewardState.currentPage, 5)
            // dispatch(get_reward_requests({ page: currentPage, limit: dataLimit }))
            // .then((response) => {
            
            //     console.log('second render ->',response.payload.content.rewards)
            //     set_rewardsData(response.payload.content.rewards)
            //     set_completed({status: false, user:'', rewardId:''})
            //     set_processing({status: false, user:'', rewardId:''})
            //     set_rejected({status: false, user:'', rewardId:''})
            //     set_scheduled({status: false, user:'', rewardId:''})
                
            //     setLoading(false)
               
            // } )
            // .catch((error) => {
            //     console.error(error)
            //     setLoading(false)
            // } )
          //set_rewardsData(response.payload.content.allRewards)
        
       // empty all stats states

        })
        .catch((error) => {
          //console.error(`${key} update failed`, error);
        });
    
    }
    else{
        //console.log('No status update')
    }
  });






  
  
  




  return (
    <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>


                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                        {/* Delete Modal */}
                        {/* <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={deleteActivity}/> */}

                        {
                            <>
                                <div className="project_header">
                                    <PageHeader
                                        heading="Rewards" img={Projecticon}
                                       
                                    />
                                </div>
                                <Card className="main-card mb-3">
                                    <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                        <div className="text-white menu-header-title text-capitalize">Reward Requests List</div>

                                        <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                            <ul className="navbar-nav mr-auto">
                                            </ul>
                                            <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.5rem' }}>
                                                <div className="input-group input-group-sm w-75">
                                                    {/* <div className="input-group-prepend border-0">
                                                        <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                    </div> */}
                                                    {/* <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search"
                                                        onChange={(e) => setSearchString(e.target.value)} /> */}
                                                </div>

                                            </div>
                                        </div>
                                    </nav>

                                   {/* Loader */}
                                   <LoadingOverlay tag="div" active={isLoading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: "#fff",
                                                opacity: 0.5,
                                            }),
                                        }}
                                        spinner={
                                            <Loader color="#6c757d" active type="ball-pulse" />
                                        }>
                                           
                                        <CardBody className="p-0">

                                            {/* Listing */}
                                            <div className="table_border">
                                                <Listing columnName={rewardsCol}
                                                    data={ rewardsData}
                                                     currentPage={rewardState.currentPage ? rewardState.currentPage : 1}
                                                     totalPages={rewardState.totalPages ? rewardState.totalPages : 1}
                                                     getListingData={(currentPage, dataLimit) => getRewardsListing(currentPage, dataLimit)}
                                                    startLoading={() => setLoading(true)}
                                                />
                                            </div>

                                        </CardBody>
                                    </LoadingOverlay>
                                </Card>
                            </>
                        }

                    </TransitionGroup>
                </>
            )}
        />
  )
}
