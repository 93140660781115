import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { TransitionGroup } from 'react-transition-group';
import CreateIcon from '../../../../assets/images/create_icon.png'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import { Typeahead } from 'react-bootstrap-typeahead';
import styles from '../../../../assets/css/rstyling.module.css'
import SecureLS from 'secure-ls';
import { createSendBroadcastMsg } from '../../../../actions/broadcast_msg_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import { getSpecificOption } from '../../../../actions/settings_actions';
import { getAllActiveProjects } from '../../../../actions/project_actions';

const CreateBroadcastMessage = () => {

    const [selectedVariable, setVariable] = useState([])
    // const [systemVariables, setSystemVariables] = useState([])
    const [variableList, setVariableList] = useState([])
    const [typingTimer, setTypingTimer] = useState(null);
    const [activeProjects, setActiveProjects] = useState([])
    const [projectList, setProjectList] = useState([])
    const [selectedSender, setSender] = useState([])
    const [selectedSender_emails, set_selectedSender_emails] = useState([])

    //const settingsState = useSelector(state => state.SystemSettings)
    const ls = new SecureLS()
    const dispatch = useDispatch()
    const history = useNavigate();
    //const [isUserVar, setUserVar] = useState(false)
    const [broadcastMsg, setBroadcastMsg] = useState({
        title: '',
        subject: '',
        //dynamicVariables: [],
        //variableValue: '',
        receiver: '',
        ProjectList: [],
        emailContent: '',
        receiver_specificUsers: [],
        author: ls.get('accessUser') && ls.get('accessUser').user ? ls.get('accessUser').user._id : ''
    })

    // for saving ckeditor data
    const [emailTemplateContent, setEmailTemplateContent] = useState('')
    const onEditorStateChange = (e) => {
        setEmailTemplateContent(e.editor.getData())
    }

    const [err, setError] = useState({
        title: '',
        subject: '',
        emailContent: '',
        sendTo: '',
        dynamicVariables: ''
    })

    const removeErr = () => {
        setError({
            title: '',
            subject: '',
            emailContent: '',
            sendTo: '',
            dynamicVariables: ''
        })
    }

    const checkValidation = (broadcastMsgData) => {
        removeErr()
        let isErr = false
        if (broadcastMsgData.subject === '') {
            setError((prevState) => ({ ...prevState, subject: 'Subject is required.' }))
            isErr = true
        }
        if (broadcastMsgData.emailContent === '') {
            setError((prevState) => ({ ...prevState, emailContent: 'Content is required.' }))
            isErr = true
        }
        if (selectedSender.length === 0) {
            setError((prevState) => ({ ...prevState, sendTo: 'Send to is required.' }))
            isErr = true
        }


        if (isErr === false) {
            return true
        }
    }

    const onSendBroadcastMsg = () => {
        let emailContent = emailTemplateContent

        for (let i = 0; i < selectedVariable.length; i++) {
            emailContent = emailContent.replaceAll(selectedVariable[i].appendable, `{{${selectedVariable[i].appendable}}}`)
        }

        const projects = activeProjects.map(project => {
            if (selectedSender.includes(project.title)) {
                return project._id
            }
        }) 

        const dynamicVar = selectedVariable.map(variable => {
            // variable.name === 'username' ? setUserVar(true) : setUserVar(false)
            return variable._id
        })

        const broadcastMsgData = {
            title: broadcastMsg.title,
            subject: broadcastMsg.subject,
            //dynamicVariables: dynamicVar.filter(v => v),
            //variableValue: broadcastMsg.variableValue,
            receiver_specificUsers: selectedSender_emails,
            receiver: selectedSender_emails.length > 0 && selectedSender.length > 0 ? ['specific users', 'project']: selectedSender_emails.length > 0 && selectedSender.length === 0 ? ['specific users'] : selectedSender_emails.length == 0 && selectedSender.length > 0 ? ['project']:[],
            projectList: projects.filter(p => p),
            emailContent: emailContent,
            author: ls.get('accessUser') && ls.get('accessUser').user ? ls.get('accessUser').user._id : ''
        }
      
        if (checkValidation(broadcastMsgData)) {
            Swal({
                title: "Please wait!",
                text: "Saving data...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });
            dispatch(createSendBroadcastMsg(broadcastMsgData))
                .then(response => {
                    if (response.payload.message === 'Sending email to all users are not allowed') {
                        Swal({
                            title: "Error",
                            text: "Sending email to all users are not allowed",
                            icon: 'error'
                        });
                    }
                    else {
                        Swal.stopLoading()
                        Swal.close()
                        Swal({
                            title: err.content ? err.content : "Sent",
                            text: response.message ? response.message : "Message sent",
                            icon: 'success'
                        });
                        history('/admin/broadcast-message')
                    }
                })
                .catch(err => {
                    Swal.stopLoading()
                    Swal.close()
                    Swal({
                        title: err.content ? err.content : "Error",
                        text: "Please try again!",
                        icon: 'error'
                    });
                })
        }
    }

    // Getting system's active projects to show in drop down
    const getActiveProjects = () => {
        dispatch(getAllActiveProjects())
            .then(response => {
                setActiveProjects(response.payload.content) // for later comparison
                const project = response.payload.content.map(project => project.title)
                setProjectList(['Specific Users', ...project]) // to show list in dropdown
            })
            .catch(err => {
                console.log(err);
                setProjectList(['Specific Users'])
            })
    }

    // Getting Template variables of the system to show in drop down
    const getSystemOptions = () => {
        dispatch(getSpecificOption({ options: ["Template Variables"] }))
            .then(response => {
                // setSystemVariables(response.payload.content.templateVariables)
                // const variables = response.payload.content.templateVariables.map(variable => variable.name);
                setVariableList(response.payload.content.templateVariables)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getSystemOptions()
        getActiveProjects()
    }, []);
    const handleEditorChange = (event, editor) => {
        const newContent = editor.getData();
        setEmailTemplateContent(newContent)
        clearTimeout(typingTimer);
        const newTypingTimer = setTimeout(() => {
            setBroadcastMsg({ ...broadcastMsg, emailContent: newContent })
        }, 2000);
        setTypingTimer(newTypingTimer)

    }

    return (
        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <div className="create_header">
                <PageHeader heading="Create Broadcast Message" img={CreateIcon} />
            </div>
            <div className="container-fluid">
                <div className="row">
                    {/* Start of Form */}
                    <div className="col-12">
                        <FormGroup>
                            <Label for="subject">Subject</Label>
                            <Input type="text" name="subject" id="subject" placeholder='Subject' value={broadcastMsg.subject}
                                className={err.subject ? styles.is_invalid : 'valid'}
                                onChange={(e) => setBroadcastMsg({ ...broadcastMsg, subject: e.target.value })} />
                            <small className="text-danger">
                                {err.subject}
                            </small>
                        </FormGroup>
                    </div>
                    <div className="col-12">
                        <FormGroup className="light_typeahead custom_drop">
                            <Label for="sendto">Send to</Label>
                            <Typeahead
                                id="sendto"
                                multiple
                                onChange={setSender}
                                selected={selectedSender}
                                options={projectList}
                                placeholder="Send to"
                                className={err.sendTo ? styles.is_invalid : 'valid'}
                            />
                            <small className="text-danger">
                                {err.sendTo}
                            </small>
                        </FormGroup>
                    </div>
                  
                    {selectedSender.includes('Specific Users') ? <div className="col-12">
                        <FormGroup className="light_typeahead custom_drop">
                            <Label for="sendto">Type emails of specific user</Label>
                            {/* <Typeahead
                                id="sendto"
                                multiple
                                onChange={set_selectedSender_emails}
                                selected={selectedSender_emails}
                                options={[]}
                                placeholder="Send to"
                                allowNew // Enable creation of new selections
                                newSelectionPrefix="" // Optional: prefix for new entries
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.target.value) {
                                        const newEmail = e.target.value.trim();
                                        if (newEmail) {
                                            set_selectedSender_emails([...selectedSender_emails, newEmail]);
                                            e.target.value = ''; // Clear input field
                                        }
                                    }
                                }}
                            //className={err.sendTo ? styles.is_invalid : 'valid'}
                            /> */}
                            <Typeahead
                                id="sendto"
                                multiple
                                onChange={(selected) => {
                                    // Extract the 'label' values and update the state as an array of strings
                                    const emailStrings = selected.map((item) =>
                                        typeof item === 'string' ? item : item.label
                                    );
                                    set_selectedSender_emails(emailStrings);
                                }}
                                selected={selectedSender_emails.map((email) => ({ label: email }))}
                                options={[]}
                                placeholder="Send to"
                                className={'valid'}
                                allowNew
                                
                            />

                            {/* <small className="text-danger">
                                {err.sendTo}
                            </small> */}
                        </FormGroup>
                    </div> : ''}
                    {/* {
                        selectedVariable.length > 0 &&
                        <div className="col-12">
                            <FormGroup className="light_typeahead custom_drop">
                                <Label for="sendto">Avaiable Strings</Label><br />
                                {
                                    selectedVariable.map(variable => {
                                        return <span key={variable.appendable} className="mb-2 mr-2 rbt-token" style={{ backgroundColor: 'rgb(232, 240, 254)' }}>
                                            {variable.appendable}
                                        </span>
                                    })
                                }
                            </FormGroup>
                        </div>
                    } */}
                    {/* <div className="col-12">
                        <FormGroup className="light_typeahead custom_drop">
                            <Label for="sendto">Variables</Label>
                            <Typeahead
                                id="variables"
                                multiple
                                onChange={setVariable}
                                selected={selectedVariable}
                                options={variableList}
                                placeholder="Variables"
                                labelKey={(option) => `${option.name}`}
                            />
                            <small className="text-danger">
                                {err.dynamicVariables}
                            </small>
                        </FormGroup>
                    </div> */}

                    {/* {
                        selectedVariable.length > 0 &&
                        <div className="col-6">
                            <FormGroup className="light_typeahead custom_drop">
                                <Label for="varValue">{"Please provide a value for selected variable"}</Label>
                                <Input type='text' value={broadcastMsg.variableValue} onChange={(e) => setBroadcastMsg({ ...broadcastMsg, variableValue: e.target.value })} />
                            </FormGroup>
                        </div>
                    } */}
                    <div className="col-12">
                        <FormGroup>
                            <Label for="emailTemplate">Email Content</Label>
                            <div className="text_editor">

                                <CKEditor
                                    className={err.emailContent ? styles.is_invalid : 'valid'}
                                    data={emailTemplateContent}
                                    onChange={handleEditorChange}
                                    //onChange={(e) => setBroadcastMsg({ ...broadcastMsg, emailContent: e.editor.getData() })}
                                    editor={ClassicEditor}


                                />
                                {/* <Input type="text"
                                    className={'valid'}
                                    name="description"
                                    placeholder="Email Template"
                                    onChange={(e) => onEditorStateChange(e)}
                                //={handleBlur}
                                //value={values.query}
                                /> */}

                                <small className="text-danger">
                                    {err.emailContent}
                                </small>
                            </div>
                        </FormGroup>
                    </div>
                    <div className="col-12 text-right">
                        <button className={` ${styles.bg_blue} text-white mr-3 btn`} onClick={() => onSendBroadcastMsg()}>
                            Send
                        </button>
                        <button className={` bg-light btn`}
                            style={{ border: '1px solid #6c757d ' }} onClick={() => history('/admin/broadcast-message')}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </TransitionGroup>

    )
}

export default CreateBroadcastMessage
