import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { Button, Card, CardBody, Label, Input, FormGroup } from "reactstrap";
import styles from '../../../../assets/css/rstyling.module.css'

const Contact = (props) => {
    const { userDetails, setUserDetails, isEdit } = props
    const createdDate = new Date(userDetails.created_at)

    const [err, setError] = useState({
        firstName: '',
        lastName: '',
        userName: ''
    })

    const removeErr = () => {
        setError({
            firstName: '',
            lastName: '',
            userName: ''
        })
    }


    const checkValidation = () => {
        removeErr()
        if (userDetails.firstName === '') {
            setError((prevState) => ({ ...prevState, firstName: 'First Name is required.' }))
        }
        if (userDetails.lastName === '') {
            setError((prevState) => ({ ...prevState, lastName: 'Last Name is required.' }))
        }
        if (userDetails.userName === '') {
            setError((prevState) => ({ ...prevState, userName: 'Username is required.' }))
        }
        else {
            return true
        }
    }

    const onSaveContactDetails = () => {
        if (checkValidation()) {
            props.editUser()
        }
    }

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <Card className="main-card mb-3">
                <CardBody>
                    <div className="row" >
                        <div className="col-md-6 col-sm-12">
                            <FormGroup>
                                <Label for="referralCode">Referral Code</Label>
                                <Input type="text" name="referralCode" id="referralCode" readOnly value={userDetails.referralCode} />
                            </FormGroup>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <FormGroup>
                                <Label for="firstName">First Name</Label>
                                <Input type="text" name="firstName" id="firstName" readOnly={!isEdit} value={userDetails.firstName}
                                    onChange={(e) => setUserDetails({ ...userDetails, firstName: e.target.value })}
                                    className={err.firstName ? styles.is_invalid : 'valid'}
                                />
                                <small className="text-danger">
                                    {err.firstName}
                                </small>
                            </FormGroup>
                        </div>
                        <div className="col-md-6 col-12">
                            <FormGroup>
                                <Label for="lastName">Last Name</Label>
                                <Input type="text" name="lastName" id="lastName" readOnly={!isEdit} value={userDetails.lastName}
                                    onChange={(e) => setUserDetails({ ...userDetails, lastName: e.target.value })}
                                    className={err.lastName ? styles.is_invalid : 'valid'}
                                />
                                <small className="text-danger">
                                    {err.lastName}
                                </small>
                            </FormGroup>
                        </div>
                        <div className="col-md-6 col-12">
                            <FormGroup>
                                <Label for="username">Username</Label>
                                <Input type="text" name="username" id="username" readOnly={!isEdit} value={userDetails.userName}
                                    onChange={(e) => setUserDetails({ ...userDetails, userName: e.target.value })}
                                    className={err.userName ? styles.is_invalid : 'valid'}
                                />
                                <small className="text-danger">
                                    {err.userName}
                                </small>
                            </FormGroup>
                        </div>
                        <div className="col-md-6 col-12">
                            <FormGroup>
                                <Label for="email">Email Address</Label>
                                <Input type="text" name="email" id="email" readOnly={true} value={userDetails.email}
                                    onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-12">
                            <FormGroup>
                                <Label for="phone">Phone</Label>
                                <Input type="number" name="phone" id="phone" readOnly={!isEdit} value={userDetails.phone}
                                    onChange={(e) => setUserDetails({ ...userDetails, phone: e.target.value })}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-12">
                            <FormGroup>
                                <Label for="date">Date of Joining</Label>
                                <Input type="string" name="date" id="date" readOnly={true} value={createdDate.toLocaleDateString()}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-12">
                            <FormGroup>
                                <Label for="date">Points Earned</Label>
                                <Input type="string" name="date" id="date" readOnly={true} value={userDetails.logs.pointsEarned}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-12">
                            <FormGroup>
                                <Label for="date">Activities Done</Label>
                                <Input type="string" name="date" id="date" readOnly={true} value={userDetails.logs.activitiesDone}
                                />
                            </FormGroup>
                        </div>

                        {
                            isEdit &&
                            <div className="col-12 text-right">
                                <Button className={` ${styles.bg_blue} text-white mr-3 btn`} onClick={() => onSaveContactDetails()}>Save</Button>
                            </div>
                        }


                    </div>
                </CardBody>
            </Card>
        </TransitionGroup>
    )
}

export default Contact
