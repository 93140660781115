import React, { useState, Children, useEffect } from 'react'
import AttachementIcon from '../../../../../assets/images/attachement_header_icon.png'
import { TransitionGroup } from 'react-transition-group';
import { useNavigate, useParams } from 'react-router';
import PageHeader from '../../../../../assets/components/pageheader/PageHeader';
import ArrowLeft from '../../../../../assets/images/arrow_left.png'
import { Card, CardBody } from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Listing from '../../../../../assets/components/listing/Listing';
import ResizeDetector from 'react-resize-detector';
import styles from '../../../../../assets/css/rstyling.module.css'
import Search from '../../../../../assets/images/search_icon.png'
import ArrowRight from '../../../../../assets/images/arrow_right.png'
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Component } from 'react'
import { Button } from 'reactstrap';
import { getPostReports } from '../../../../../actions/activities_reply_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
const localizer = momentLocalizer(moment);

const JournalReports = () => {

    const history = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const [isLoading, setLoading] = useState(true)

    const [column] = useState([
        { Name: 'Username', Type: 'image text', key: 'author', value: 'userName', image: 'profileImage' },
        { Name: "Entries", Type: 'number', key: 'entries' },
        { Name: "Last Edit Date", Type: 'date' },
    ]);

    const [participants, setParticipants] = useState([])
    const [report, setReport] = useState([])

    const [events, setEvents] = useState([])

    // Month and year of the event
    let monthNames = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const [month, setMonth] = useState('')

    // Event day styling
    const ColoredDateCellWrapper = ({ children, value }) =>
        React.cloneElement(Children.only(children), {
            style: {
                ...children.style,
                backgroundColor: (events.length > 0 && value >= events[0].start && value < events[0].end) ? '#eee' : 'white',
            },
        });


    const getReport = () => {
        dispatch(getPostReports({ activityId: params.activityid }))
            .then((response) => {
                setParticipants(response.payload.content.reports[0].participants)
                setReport(response.payload.content.reports)

                let events = []
                response.payload.content.reports.map((report, index) => {
                    events.push({
                        id: index,
                        title: "Diary Entries",
                        allDay: false,
                        start: new Date(report.date).toLocaleDateString("en-US", options),
                        end: new Date(report.date).toLocaleDateString("en-US", options),
                    })
                })
                setEvents(events) // saving events to show on calender

                const date = new Date(response.payload.content.reports[0].date)
                setMonth(monthNames[date.getMonth() + 1] + "," + [date.getFullYear()])
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }
    useEffect(() => {
        getReport()
    }, [])

    // change participants listing on click of specific event
    const onSelectEvent = (date) => {
        const selectedDate = date
        report.map((report, index) => {
            if (new Date(report.date).toLocaleDateString() === selectedDate) {
                setParticipants(report.participants)

            }
        })
    }

    // Searching
    const [searchString, setSearchString] = useState('')
    const [searchedResult, setSearchedResult] = useState([])


    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredData = participants.filter(row => {
           
            return (
                search.test(row.author.userName)
            );
        });
        setSearchedResult(filteredData);
    }, [searchString]);

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>
                    <LoadingOverlay tag="div" active={isLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>
                        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                            {/* Page Header */}
                            <div className="project_header"><PageHeader
                                heading="Reports" img={AttachementIcon}
                            /></div>

                            <div className="container-fluid">

                                {/* Back Icon */}
                                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                                    <img src={ArrowLeft} alt="" width="8px" />
                                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                                </span>

                                {/* Calender */}
                                <p className="mr-auto" style={{ fontWeight: 600, fontSize: '1rem' }}>{month}</p>

                                <div className="mb-3 journal_activity">
                                    {
                                        !isLoading &&
                                        <Calendar
                                            views={['month']}
                                            selectable={true}
                                            onSelectEvent={event => onSelectEvent(event.start)}
                                            components={{
                                                toolbar: Toolbar,
                                                dateCellWrapper: ColoredDateCellWrapper,
                                            }}
                                            localizer={localizer} events={events} startAccessor="start" endAccessor="end"
                                        />
                                    }

                                </div>

                                <Card className="main-card mb-3">
                                    <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                        <div className="text-white menu-header-title text-capitalize">Users List</div>

                                        <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                            <ul className="navbar-nav mr-auto">
                                            </ul>
                                            <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.5rem' }}>
                                                <div className="input-group input-group-sm w-75">
                                                    <div className="input-group-prepend border-0">
                                                        <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                    </div>
                                                    <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search"
                                                        onChange={(e) => setSearchString(e.target.value)} />
                                                </div>

                                            </div>
                                        </div>
                                    </nav>

                                    {/* Users Listing */}
                                    <CardBody className="p-0">
                                        <div className="table_border">
                                            <Listing
                                                data={(searchString.length > 0 || searchedResult.length > 0) ? searchedResult : participants}
                                                columnName={column}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>

                            </div>

                        </TransitionGroup >
                    </LoadingOverlay>



                </>
            )}
        />

    )
}

export default JournalReports


// Calender components 
const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
};

const propTypes = {
    view: PropTypes.string.isRequired,
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.node.isRequired,
    localizer: PropTypes.object,
    onNavigate: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired
};

// Customized Toolbar of calender
export class Toolbar extends Component {
    static propTypes = propTypes;
    render() {
        let {
            //localizer: { messages },
            // label,
            // date
        } = this.props;

        return (
            <>
                <div className="d-flex" style={{ marginTop: '-3rem' }}>
                    <p className="mr-auto" style={{ fontWeight: 600, fontSize: '1rem', visibility: 'hidden' }}>September, 2021</p>
                    <div className="float-right">
                        <button onClick={this.navigate.bind(null, navigate.PREVIOUS)} className="btn btn-outline-light">
                            <img src={ArrowLeft} alt="" width="8px" />
                        </button>
                        <button onClick={this.navigate.bind(null, navigate.NEXT)} className="btn btn-outline-light">
                            <img src={ArrowRight} alt="" width="8px" />
                        </button>
                    </div>
                </div>
            </>
        );
    }

    toThisDay = date => {
        this.props.onView('day');
        // give it just a tick to 'set' the view, prior to navigating to the proper date
        setTimeout(() => {
            this.props.onNavigate(navigate.DATE, date);
        }, 100);
    };

    navigate = action => {
        this.props.onNavigate(action);
    };

    view = view => {
        this.props.onView(view);
    };

    viewNamesGroup(messages) {
        let viewNames = this.props.views;
        const view = this.props.view;

        if (viewNames.length > 1) {
            return viewNames.map(name => (
                <Button
                    key={name}
                    className={cn({
                        active: view === name,
                        'btn-primary': view === name
                    })}
                    onClick={this.view.bind(null, name)}
                >
                    {messages[name]}
                </Button>
            ));
        }
    }
}
