import React, {useEffect, useState} from 'react';
import { getActivityVideo, getActivityQuestionImage } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from '../../css/styling.module.css';
import YouTube from 'react-youtube';
import Dropzone from "react-dropzone";
import { sendActivityReplyAnswers, sendSharableSurveyReplyAnswers } from '../../../actions/activities_reply_actions';
const Question_With_Image = (props) => {




    // On image Select
    const onImageSelect = (file) => {
//console.log('image file answer func ->', file)
        if (file) {

            // Remove Error Message
            document.getElementById(`error-msg-${props.id}`).innerHTML = "";

            for (let i = 0; i < file.length; i++) {

                // File Extension Check
                let fileExtension = file[i].name.split('.').pop();

                if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {

                    if (props.allContent.pages[props.pageIndex].questions[props.questionIndex].image) {
                        // Set All Content
                       // console.log('image here 1? =>', file[i])
                        props.allContent.pages[props.pageIndex].questions[props.questionIndex].image.push(file[i]);
                    } else {
                      


                        props.allContent.pages[props.pageIndex].questions[props.questionIndex].image = [file[i]]
                    }
                    //console.log('set content ->', props.allContent)
                    props.setContent({ ...props.allContent });

                }
            }

        }

        else {

            document.getElementById(`error-msg-${props.id}`).innerHTML = `
                <div class="my-2">
                    <div class="alert alert-danger mb-0">
                        Invalid file type
                    </div>
                </div>
            `;
        }

    }

    // on Cancel Image
    const onCancelImg = (index) => {
        props.allContent.pages[props.pageIndex].questions[props.questionIndex].image.splice(index, 1);
        props.setContent({ ...props.allContent });
    }

    // On Comment
    const onComment = (e) => {

        props.allContent.pages[props.pageIndex].questions[props.questionIndex].comment = e.target.value;
        props.setContent({ ...props.allContent });

    }

    const opts = {
        width: '640',
        height: '360',
        playerVars: {
          autoplay: 0
        },
    };

    const params = useParams();
    const userId = params.UserId
    const dispatch = useDispatch();
    const pageIndex = props.pageIndex
    const questionIndex = props.questionIndex
    const [qVideo, setQVideo] = useState({})
    const [qImage, setQImage] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if(params.surveyid || params.ActivityId || params.activityid){
    dispatch(getActivityVideo(params.surveyid || params.ActivityId || params.activityid , pageIndex, questionIndex ))
    .then((response) =>{
        try{
            //console.log('question image in img type with video->', response.payload.content.fileData)
        setQVideo(response.payload.content.fileData)}catch(err){console.log('video not available')}
        setIsLoading(false);
        
    })
    .catch(err=>err)

    // dispatch(getActivityQuestionImage(params.ActivityId || params.surveyid || params.activityid))
    // .then((response) => {
    //     try {
    //         //console.log('question image in img type 2->', response.payload.content.fileData)
    //         setQImage(response.payload.content.fileData)
    //     } catch (err) { console.log('image in questionnaire not available') }
    //     setIsLoading(false);
    // })
    // .catch(err=>err)



}
    else if(params.evaluationid){
        dispatch(getActivityVideo(params.evaluationid, pageIndex, questionIndex ))
    .then((response) =>{
        try{
            //console.log('question image in img type 1->', response.payload.content.fileData)
        setQVideo(response.payload.content.fileData)}catch(err){console.log('video not available')}
        setIsLoading(false);
        
    })
    .catch(err=>err)
    // dispatch(getActivityQuestionImage(params.evaluationid))
    //             .then((response) => {
    //                 try {
    //                     //console.log('question image in img type->', response.payload.content.fileData)
    //                     setQImage(response.payload.content.fileData)
    //                 } catch (err) { console.log('image in questionnaire not available') }
    //                 setIsLoading(false);
    //             })
    //             .catch(err=>err)
    }
}, []);

const makeLinksOpenInNewTab = (htmlContent) => {
    // Create a DOM element to parse the HTML content
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;

    // Find all anchor tags within the parsed HTML content
    const anchorTags = tempElement.getElementsByTagName('a');

    // Iterate through the anchor tags and set the target attribute to '_blank'
    for (const anchorTag of anchorTags) {
        anchorTag.setAttribute('target', '_blank');
        // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
        anchorTag.setAttribute('rel', 'noopener noreferrer');
    }

    // Return the modified HTML content
    return tempElement.innerHTML;
}

    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}
                        {props.questionText &&
                          <span  style={{ color: '#000', fontSize: '27px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }


                      {/* Question video */}
                      {props.questionVideo && (
                        <div>
                            
                            <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>
                            
                        </div>
                    )}
                    </p>

                    {/* Question Video */}
                    {
                        props.questionFile &&
                        <>
                            {
                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">
                                                {/* <video width={300} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                    <source src={props.questionFile} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video> */}
                                                {isLoading ? (
                                                   <b>Loading Video...</b>
                                                       ) : qVideo.data ? (
                                                        <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                        <ReactPlayer
                                                            url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                            controls
                                                            width ='100%'
                                                        />
                                                        </div>
                                                     ) : (
                                         <b>Loading Video...</b>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                <div className="mx-3 mt-2 mb-4">

                                {/* Question Image Display */}
                                <div className="mb-3 mt-3">
                                    
                                    {qVideo.data &&

                                        // Image Display
                                        <div>
                                            <div className="position-relative">
                                                <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                            }
                        </>


                    }

                    <div className="mt-3 mx-3">

                        {/* File Limit and File Size */}
                        <small className="text-danger">
                            Max files Limit: {props.filesLimit ? props.filesLimit : "1"} | Max file Size: {props.filesSize ? props.filesSize : "1"} MB
                        </small>

                        {/* Image Display & Dropzone */}
                        {props.image && props.image.length > 0 ? (

                            // Image Display
                            <div className="row pt-3">

                                {props.image.map((imgSrc, index) => {
                                    return (
                                        <div key={index} className="position-relative mx-3 mb-3">
                                            <span className="questionImg_close" onClick={() => onCancelImg(index)}>&times;</span>
                                            <img src={typeof imgSrc === 'string' ? imgSrc : URL.createObjectURL(imgSrc)} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="survey-img" width="200px" />
                                        </div>
                                    )
                                })}

                            </div>

                        ) : (

                            // Image Drop Zone 
                            < div className="dropzone-wrapper dropzone-wrapper-sm mb-3">
                                <Dropzone
                                
                                    maxFiles={!Number.isNaN(parseInt(props.filesLimit)) ? parseInt(props.filesLimit) : 0}
                                    maxSize={!Number.isNaN(parseFloat(props.filesSize)) ? (parseFloat(props.filesSize) * 1048576) : (1048576)}
                                    multiple={!Number.isNaN(parseInt(props.filesLimit)) ? (parseInt(props.filesLimit) > 0 ? true : false) : false}
                                    accept={".jpg, .jpeg, .png"}
                                    onDrop={file => onImageSelect(file)}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                               
                                                <div className="d-flex">
                                                    <span className={styles.upload_media_icon} style={{ width: 100, height: 100, backgroundSize: 55, verticalAlign: "middle" }}></span>
                                                    <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Try dropping image here, or click to select image to upload.</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>

                        )}


                         {/* Image Display & Dropzone */}
            {/* {props.image && props.image.length > 0 ? (
                // Image Display
                <div className="row pt-3">
                    {props.image.map((imgSrc, index) => (
                        <div key={index} className="position-relative mx-3 mb-3">
                            <span className="questionImg_close" onClick={() => onCancelImg(index)}>&times;</span>
                            <img src={typeof imgSrc === 'string' ? imgSrc : URL.createObjectURL(imgSrc)} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="survey-img" width="200px" />
                        </div>
                    ))}
                </div>
            ) : (
                // Image Drop Zone
                <div className="dropzone-wrapper dropzone-wrapper-sm mb-3">
                    <Dropzone
                        maxFiles={props.filesLimit ? parseInt(props.filesLimit) : 1}
                        maxSize={props.filesSize ? (parseFloat(props.filesSize) * 1048576) : 1048576}
                        multiple={props.filesLimit ? (parseInt(props.filesLimit) > 1) : false}
                        accept=".jpg, .jpeg, .png"
                        onDrop={onImageSelect}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} style={{ cursor: 'pointer' }}>
                                <input {...getInputProps()} />
                                <div className="dropzone-content">
                                    <div className="d-flex">
                                        <span className={styles.upload_media_icon} style={{ width: 100, height: 100, backgroundSize: 55, verticalAlign: "middle" }}></span>
                                        <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Try dropping image here, or click to select image to upload.</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
            )} */}

                        {/* Comment */}
                        {props.isComment && props.isComment && (
                            <div className="form-group mt-1 mb-3">
                                <label>Comment</label>
                                <input className="form-control" type="text" value={props.comment} onChange={onComment} placeholder="Write here" />
                            </div>
                        )}

                    </div>

                    {/* Upload related error */}
                    <div id={`error-msg-${props.id}`}></div>

                    {/* Required Answer error */}
                    {props.required && !props.isAnswered && (
                        <div id={`error-msg`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div >
    )

}

export default Question_With_Image;