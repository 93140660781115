import React, { useState, useEffect } from 'react';
import { Input, Button, Card, CardBody } from 'reactstrap';
import styles from '../../../../../assets/css/rstyling.module.css'
import ArrowLeft from '../../../../../assets/images/arrow_left.png';
import InpersonForumICon from '../../../../../assets/images/inperson_forum_icon.png'
import { useNavigate, useParams } from 'react-router';
import { getActivity, getActivityImg, getRespondentActivityDetails } from '../../../../../actions/activities_actions';
import Swal from 'sweetalert'
import { useDispatch } from 'react-redux';
import ResizeDetector from 'react-resize-detector';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import Back from '../../../../../assets/components/back/Back';


// This is activity type focus group discussion


const Forum = (props) => {

    const history = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [forumDetails, setForumDetails] = useState({})
const[bannerImg, setBannerImg] = useState({})
    useEffect(() => {
        if (params.forumid !== 'undefined') {
            dispatch(getActivityImg(params.forumid))
        .then((response) =>{
            try{
            
            setBannerImg(response.payload.content.fileData)}catch(err){console.log('img not available')}
            
        })
            dispatch(getRespondentActivityDetails(params.forumid))
                .then((response) => {
                 
                    setForumDetails(response.payload.content)
                    setLoading(false)
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        } else {
            setLoading(false)

        }

    }, [])

    const openMap = () => {
        window.open("https://maps.google.com?q="+forumDetails.location ); 
    }

    const [currentWidth, setWidth] = useState()
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // top: '50%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>
                    <>
                       

                        {/* Back Icon */}
                        <Back />

                        <div className="container-fluid mt-4" style={{ width: currentWidth }}>
                            <h5 style={{ color: "#000", fontWeight: 500, fontSize: "20px" }}  className="mx-3">{forumDetails.name}</h5>

                            {/* Image Display */}
                            {
                                forumDetails.bannerImage &&
                                <div className="mx-3 mb-4">
                                    <div className="pt-3">
                                        <div className="position-relative">
                                            <img src={`data:${bannerImg.type} ;base64, ${bannerImg.data}`} className={`img-fluid border ${styles.banner_image}`} alt="banner-image" width={200} />
                                        </div>
                                    </div>
                                </div>
                            }


                            <Card className="main-card mx-3 mt-3 mb-4">
                                <CardBody>
                                    <p style={{ color: '#000', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}>{forumDetails.description}</p>
                                    
                                </CardBody>
                            </Card>

                            <div className="row mx-2 py-4">


                                <div className="col-sm-6 col-12 py-3">
                                    <div  style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Duration (Days):</div>
                                    <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{forumDetails.days}</div>
                                </div>
                                <div className="col-sm-6 col-12 py-3">
                                    <div   style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Duration Per Day (Minutes):</div>
                                    <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{forumDetails.duration}</div>
                                </div>
                                <div className="col-sm-6 col-12 py-3">
                                    <div style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Priority:</div>
                                    <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{forumDetails.priority}</div>
                                </div>

                                <div className="col-sm-6 col-12 py-3">
                                    <div style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Start date:</div>
                                    <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{new Date(forumDetails.startDate).toLocaleDateString()}</div>
                                </div>

                                <div className="col-sm-6 col-12 py-3">
                                    <div  style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Audience:</div>
                                    <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{forumDetails.audience ? forumDetails.audience.title : 'All Paricipants'}</div>
                                </div>
                            </div>

                            <div className="row mt-5 mx-2">
                                <div className="col-12 py-3">
                                    <div  style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Interview Meeting Link</div>
                                    <div className="mb-2" style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>Easily copy pasting the link to join the meeting</div>
                                    <div className="d-flex m-0">
                                        <div className="col-md-10 col-8 p-0">
                                            <input className="form-control" type="text" disabled value={forumDetails.meetingLink} />

                                        </div>
                                        <div className="col-md-2 col-5">
                                        <Button style={{ backgroundColor:'#0069AB', border:'none'}}><a className="text-white" href={forumDetails.meetingLink} target="_blank">Join Meeting</a></Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </>
                </LoadingOverlay>


            )}
        />
    )


}

export default Forum


