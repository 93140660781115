import React, { useState } from 'react'
import PageHeader from '../../../assets/components/pageheader/PageHeader'
import { TransitionGroup } from 'react-transition-group';
import {
    Label,
    Input,
    FormGroup,
    Button
} from "reactstrap";
import styles from '../../../assets/css/rstyling.module.css'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux'
import CreateIcon from '../../../assets/images/create_icon.png'
import ResearcherInvite from '../../ResearcherInvite/ResearcherInvite';
import { signupThroughAdmin } from '../../../actions/auth_actions';
import Swal from 'sweetalert'
import ArrowLeft from '../../../assets/images/arrow_left.png'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2';

const AddUser = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [phone, setPhone] = useState('');
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        userName: '',
        role: '',
        company: '',
        password: '',
        phone:''
    })

    


    const onSave = (values) => {

        dispatch(signupThroughAdmin(values))
            .then(response => {
               
                Swal({
                    title: "Added",
                    text: response.payload.message ? response.payload.message : "User Added",
                    icon: 'success'
                }).then(() => history('/admin/user'))
            }).catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }


    return (
        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <div className="create_header">
                <PageHeader heading="Add New User" img={CreateIcon} />
            </div>

            {/* Back Icon */}
            <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <div className="container-fluid mt-3">
                <div className="row">

                    <Formik
                        initialValues={userData}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true)
                            onSave(values)

                        }}


                        const validationSchema={Yup.object().shape({
                            firstName: Yup.string().required("First Name is required"),
                            lastName: Yup.string().required("Last Name is required"),
                            email: Yup.string().email('Invalid email format').required('Email is Required'),
                            userName: Yup.string().required("Username is required"),
                            role: Yup.string().required("User Role is required"),
                            phone: Yup.string().required("Phone Number is required"),
                            password: Yup.string().required("Password is required")
                                .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/, "Password must contain 1 uppercase, 1 lowercase, 2 numbers and a special character"),
                        })}


                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            } = props;



                            return (
                                <form autoComplete="off" onSubmit={handleSubmit}>

                                    {/* Start of form */}

                                    <div className='row'>
                                        <div className="col-md-6 col-12">
                                            <FormGroup>
                                                <Label for="firstName">First Name</Label>
                                                <Input type="text"
                                                    className={errors.firstName && touched.firstName ? styles.is_invalid : 'valid'}
                                                    name="firstName"
                                                    placeholder="First Name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.firstName}
                                                />

                                                {errors.firstName && touched.firstName && (
                                                    <small className='text-danger'>
                                                        {errors.firstName}
                                                    </small>
                                                )}

                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <FormGroup>
                                                <Label for="lastName">Last Name</Label>
                                                <Input type="text"
                                                    className={errors.lastName && touched.lastName ? styles.is_invalid : 'valid'}
                                                    name="lastName"
                                                    placeholder="Last Name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.lastName}
                                                />

                                                {errors.lastName && touched.lastName && (
                                                    <small className='text-danger'>
                                                        {errors.lastName}
                                                    </small>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <FormGroup>
                                                <Label for="lastName">Email Address</Label>
                                                <Input type="text"
                                                    className={errors.email && touched.email ? styles.is_invalid : 'valid'}
                                                    name="email"
                                                    placeholder="Email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                />

                                                {errors.email && touched.email && (
                                                    <small className='text-danger'>
                                                        {errors.email}
                                                    </small>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <FormGroup>
                                                <Label for="username">Username</Label>
                                                <Input type="text"
                                                    className={errors.userName && touched.userName ? styles.is_invalid : 'valid'}
                                                    name="userName"
                                                    placeholder="Username"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.userName}
                                                />

                                                {errors.userName && touched.userName && (
                                                    <small className='text-danger'>
                                                        {errors.userName}
                                                    </small>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-6 col-12'>
                                        <Label for="phone">Phone</Label>
                                            <PhoneInput
                                                inputStyle={{ width: '100%', height: '38px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                country={'pk'}
                                                value={phone}
                                                onChange={(phone, country) => {
                                                  
                                                    setFieldValue('phone', phone)
                                                }}
                                                enableSearch={true}

                                            />
                                            {errors.phone && touched.phone && (
                                                <small className='text-danger'>
                                                    {errors.phone}
                                                </small>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <FormGroup>
                                                <Label for="type">Type</Label>
                                                <Input type="select"
                                                    className={errors.role && touched.role ? styles.is_invalid : 'form-control valid'}
                                                    name="role"
                                                    // placeholder="Username"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.role}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="3">Participant</option>
                                                    <option value="2">Researcher</option>
                                                    <option value="1">Admin</option>
                                                </Input>
                                                {errors.role && touched.role && (
                                                    <small className='text-danger'>
                                                        {errors.role}
                                                    </small>
                                                )}
                                            </FormGroup>
                                        </div>
                                        {
                                            values.role !== '3' &&
                                            <div className="col-md-6 col-12">
                                                <FormGroup>
                                                    <Label for="company">Company</Label>
                                                    <Input type="text"
                                                        className={errors.company && touched.company ? styles.is_invalid : 'valid'}
                                                        name="company"
                                                        placeholder="Company"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.company}
                                                    />

                                                    {errors.company && touched.company && (
                                                        <small className='text-danger'>
                                                            {errors.company}
                                                        </small>
                                                    )}
                                                </FormGroup>
                                            </div>
                                        }
                                        <div className="col-md-6 col-12">
                                            <FormGroup>
                                                <Label for="company">Password</Label>
                                                <Input type="text"
                                                    className={errors.password && touched.password ? styles.is_invalid : 'valid'}
                                                    name="password"
                                                    placeholder="Password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                />

                                                {errors.password && touched.password && (
                                                    <small className='text-danger'>
                                                        {errors.password}
                                                    </small>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className="col-12 align-self-center pt-3 text-right">
                                            <Button className={`${styles.bg_blue} mr-2`} type="submit">Save</Button>
                                            <Button className={`${styles.bg_gray} text-dark`} type="button" onClick={() => history("/admin/user")}>Cancel</Button>
                                        </div>

                                    </div>
                                </form>
                            )
                        }}

                    </Formik>



                </div>
            </div>
        </TransitionGroup >
    )
}

export default AddUser
