import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { Button, Card, CardBody, Label, Input, FormGroup } from "reactstrap";
import { getSpecificOption } from '../../../../actions/settings_actions';
import styles from '../../../../assets/css/rstyling.module.css'
import Swal from 'sweetalert'

const Demographics = (props) => {

    const { userDetails, setUserDetails, isEdit } = props
    const dispatch = useDispatch()
    const systemOptionsState = useSelector(state => state.SystemSettings)
    const [options, setOptions] = useState([])

    const getSystemOptions = () => {
        dispatch(getSpecificOption({ options: ["Education", "Employment", "Marital Status", "Relationship", "Income", "Countries", "Seniority", "Cities"] }))
            .then(response => {
              
                // setOptions(response)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                });
            })
    }
    useEffect(() => {
        if (systemOptionsState.specificOptions.length === 0) {
            getSystemOptions()
        }
    }, [])

    useEffect(() => {
        setOptions(systemOptionsState.specificOptions)
    }, [systemOptionsState])

    const [ageErr, setAgeErr] = useState('')

    // check for min age 13
    const onSelectDOB = (dateString) => {
        setAgeErr()
        if (dateString !== "") {
            const today = new Date();
            const birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if (age < 13) {
                setAgeErr("Min age required 13")

            } else {
                setUserDetails({ 
                    ...userDetails, 
                    birthday: birthDate.getFullYear() + "-" + ("0" + (birthDate.getMonth() + 1)).slice(-2) + "-" + birthDate.getDate(), 
                    age: age 
                })

            }
        }
    }
    const [birthday, setBirthday] = useState()

    // saving birth date in input date format
    useEffect(() => {
        const birthDate = new Date(userDetails.birthday)
        setBirthday(birthDate.getFullYear() + "-" + ("0" + (birthDate.getMonth() + 1)).slice(-2) + "-" + ("0" + (birthDate.getDate())).slice(-2) )
    }, [userDetails.birthday])

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <Card className="main-card mb-3">


                <CardBody>
                    <div className="row" >
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="city">City</Label>
                                <Input type="select" name="city" id="city" className={`form-control ${'form-control valid'}`}  readOnly={!isEdit} value={userDetails.city}
                                    onChange={(e) => setUserDetails({ ...userDetails, city: e.target.value })}
                                >
                                    <option value="">Select</option>
                                    {
                                        options.cities && options.cities.map((city, index) => {
                                            return <option key={index} value={city.name}>{city.name}</option>
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="country">Country</Label>
                                <Input type="select" name="country" id="country" className={`form-control ${'form-control valid'}`} value={userDetails.country} readOnly={!isEdit}
                                    onChange={(e) => setUserDetails({ ...userDetails, country: e.target.value })}>
                                    <option value="">Select</option>
                                    {
                                        options.countries && options.countries.map((country, index) => {
                                            return <option key={index} value={country.name}>{country.name}</option>
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="area">Area</Label>
                                <Input type="text" name="area" id="area" readOnly={!isEdit} value={userDetails.address}
                                    onChange={(e) => setUserDetails({ ...userDetails, address: e.target.value })}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="gender">Gender</Label>
                                {
                                    !isEdit ?
                                        <Input type="text" name="gender" id="gender" readOnly={!isEdit} value={userDetails.gender} /> :
                                        <Input type="select" name="gender" id="gender" className={`form-control ${'form-control valid'}`} value={userDetails.gender}
                                            onChange={(e) => setUserDetails({ ...userDetails, gender: e.target.value })}>
                                            <option value="">Select</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </Input>
                                }
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="age">Age</Label>
                                <Input type="number" name="age" id="age" readOnly={true} value={userDetails.age}
                                    onChange={(e) => setUserDetails({ ...userDetails, age: e.target.value })}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="dateOfBirth">Date of Birth</Label>
                                {
                                    isEdit ?
                                        <Input type="date" name="dateOfBirth" id="dateOfBirth" readOnly={!isEdit} value={birthday}
                                            onChange={(e) => onSelectDOB(e.target.value)}
                                        /> :
                                        <Input type="string" name="dateOfBirth" id="dateOfBirth" readOnly={!isEdit} value={birthday}
                                        />

                                }
                                <small className='text-danger'>{ageErr}</small>

                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="maritalStatus">Marital Status</Label>
                                {
                                    !isEdit ?
                                        <Input type="text" name="maritalStatus" id="maritalStatus" readOnly={!isEdit} value={userDetails.maritalStatus} /> :
                                        <Input type="select" name="maritalStatus" id="maritalStatus" className={`form-control ${'form-control valid'}`} value={userDetails.maritalStatus}
                                            onChange={(e) => setUserDetails({ ...userDetails, maritalStatus: e.target.value })}>
                                            <option value="">Select</option>
                                            {
                                                options.maritalStatus && options.maritalStatus.map((maritalStatus, index) => {
                                                    return <option key={index} value={maritalStatus.name}>{maritalStatus.name}</option>
                                                })
                                            }
                                        </Input>
                                }
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="education">Education</Label>
                                {
                                    !isEdit ?
                                        <Input type="text" name="education" id="education" readOnly={!isEdit} value={userDetails.education} /> :
                                        <Input type="select" name="education" id="education" className={`form-control ${'form-control valid'}`} value={userDetails.education}
                                            onChange={(e) => setUserDetails({ ...userDetails, education: e.target.value })}>
                                            <option value="">Select</option>
                                            {
                                                options.educationLevel && options.educationLevel.map((education, index) => {
                                                    return <option key={index} value={education.name}>{education.name}</option>
                                                })
                                            }
                                        </Input>
                                }
                            </FormGroup>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <FormGroup>
                                <Label for="status">Selection the option that best describes your status</Label>
                                {
                                    !isEdit ?
                                        <Input type="text" name="status" id="status" readOnly={!isEdit} value={userDetails.employment} /> :
                                        <Input type="select" name="status" id="status" className={`form-control ${'form-control valid'}`} value={userDetails.employment}
                                            onChange={(e) => setUserDetails({ ...userDetails, employment: e.target.value })}>
                                            <option value="">Select</option>
                                            {
                                                options.employment && options.employment.map((employment, index) => {
                                                    return <option key={index} value={employment.name}>{employment.name}</option>
                                                })
                                            }
                                        </Input>
                                }
                            </FormGroup>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <FormGroup>
                                <Label for="status">Seniority</Label>
                                {

                                    <Input type="select" name="seniority" id="status" className={`form-control ${'form-control valid'}`} value={userDetails.seniority} readOnly={!isEdit}
                                        onChange={(e) => setUserDetails({ ...userDetails, seniority: e.target.value })}>
                                        <option value="">Select</option>
                                        {
                                            options.seniority && options.seniority.map((seniority, index) => {
                                                return <option key={index} value={seniority.name}>{seniority.name}</option>
                                            })
                                        }
                                    </Input>
                                }
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="occupation">Occupation</Label>
                                <Input type="text" name="occupation" id="occupation" readOnly={!isEdit} value={userDetails.occupation}
                                    onChange={(e) => setUserDetails({ ...userDetails, occupation: e.target.value })} />
                            </FormGroup>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <FormGroup>
                                <Label for="monthlyIncome">Monthly Income</Label>
                                <Input type="select" name="monthlyIncome" id="monthlyIncome" className={`form-control ${'form-control valid'}`} readOnly={!isEdit} value={userDetails.income}
                                    onChange={(e) => setUserDetails({ ...userDetails, income: e.target.value })}>
                                    <option value="">Select</option>
                                    {
                                        options.incomeRange && options.incomeRange.map((income, index) => {
                                            return <option key={index} value={income.name}>{income.name}</option>
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </div>
                    </div>

                    {
                        isEdit &&
                        <div className="col-12 text-right">
                            <Button className={` ${styles.bg_blue} text-white px-3 btn`} onClick={() => props.editUser()}>Save</Button>
                        </div>
                    }
                </CardBody>
            </Card>
        </TransitionGroup>
    )
}

export default Demographics
