import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from '../../css/newStyling.module.css'
import pointsBadge from '../../../assets/images/medal.png'

const RedeemModal = (props) => {

    const toggle = () => {
        props.setModal()
    }
    const [selectedOption, setSelectedOption] = useState(null); // Selected radio option
    const [confirmedOption, setConfirmedOption] = useState(null); // Confirmed radio option
    const options = [
        { value: 1000, label: "PKR 1,000" },
        { value: 1500, label: "PKR 3,000" },
        { value: 3500, label: "PKR 5,000" },
    ];
    // if(props.variations === true && props.totalPoints >= 1000 && props.totalPoints < 1500){
    //     options.splice(1,2)

    // }
    // else if(props.variations === true && props.totalPoints >= 1500 && props.totalPoints < 3500){
    //     options.splice(2,1)
    // }

    const handleOptionChange = (option) => {

        setSelectedOption(option); // Store the selected option temporarily
    };
    const handleConfirm = () => {
        if (selectedOption) {
            setConfirmedOption(selectedOption); // Store the confirmed option
            props.onClick(selectedOption); // Pass the confirmed option to parent function
        } else {
            //alert("Please select an option before confirming."); // Alert if no option is selected
        }
    };

    const isDisabled = (value) => {
        if (props.totalPoints >= 1500) {
            return value > props.totalPoints;
        } else {
            return value > props.totalPoints;
        }
    };


    return (

        <Modal isOpen={props.isModalOpen} toggle={toggle} id={`ImageModal`} className="modal-dialog-centered shadow-none" >
            <ModalHeader className="bg-white" toggle={toggle}>Redeem Confirmation</ModalHeader>
            <ModalBody className="text-center">
                {props.variations ? <div>
                    <div className="redeem-options">
                        <p>{`Choose the amount of points you want to redeem for ${props.brand}`}</p>
                        {options.map((option) => (
                            <div key={option.value} className="form-check mb-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id={`redeem-${option.value}`}
                                    name="redeemOption"
                                    value={option.value}
                                    disabled={isDisabled(option.value)}
                                    checked={selectedOption?.value === option.value}
                                    onChange={() => handleOptionChange(option)} // Update selected option
                                />
                                <label className="form-check-label" htmlFor={`redeem-${option.value}`}>
                                    <img
                                        src={pointsBadge}
                                        alt={`${option.value} points`}
                                        className={`${styles.reward_badge}`}
                                    /> {`${option.value}  - ${option.label}`}
                                </label>
                            </div>
                        ))}

                        <button
                            className="btn btn-primary mt-3"
                            onClick={handleConfirm}
                            disabled={!selectedOption} // Disable if no option is selected
                        >
                            Confirm
                        </button>

                        {confirmedOption && (
                            <div className="mt-3 alert alert-success">
                                Confirmed: {`${confirmedOption.value} - ${confirmedOption.label}`}
                            </div>
                        )}
                    </div>
                </div> : <></>}
                {/* <p> {props.msgText ? props.msgText : "Are you sure want to redeem points?"} </p> */}
                {/* <Button className={` ${styles.bg_blue} mr-3`} onClick={() => props.onClick()}>
                    Yes
                </Button> */}
                {/* <Button className={` bg-light text-muted ml-3`}
                    style={{ border: '1px solid #6c757d ' }} onClick={toggle}>
                    No
                </Button> */}
            </ModalBody>
        </Modal>

    )
}

export default RedeemModal
