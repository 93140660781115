import React, { useState, useEffect } from 'react'
import Listing from '../../../../../../assets/components/listing/Listing'
import styles from '../../../../../../assets/css/rstyling.module.css'
import { Modal, ModalHeader, ModalBody, Button, Input, Label, FormGroup } from "reactstrap";
import { TransitionGroup } from 'react-transition-group';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import EditIcon from '../../../../../../assets/images/edit_icon.png'
import CallIcon from '../../../../../../assets/images/call_icon.png'
import Deleteicon from '../../../../../../assets/images/delete_icon.png'
import ResizeDetector from "react-resize-detector";
import AlertModal from '../../../../../../assets/components/modal/AlertModal';
import TickIcon from '../../../../../../assets/images/user_icon.png'
import Swal from 'sweetalert'
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { createActivityMeeting, deleteMeeting, editMeeting, getAllActivityMeetings, meetingDetails } from '../../../../../../actions/activities_actions';

const Meeting = (props) => {

    const { audiences, participants } = props

    const activityMeetingState = useSelector(state => state.ActivityMeetings)
    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const params = useParams()
    const path = currentPath
    const dispatch = useDispatch()

    const [delId, setDelID] = useState('')
    const [editId, setEditId] = useState('')
    const [changeStatusId, setChangeStatusId] = useState('')
    const [isEdit, setEditMode] = useState(false)
    const [isModalOpen, setModal] = useState(false)
    const [isLoading, setLoading] = useState(true)

    const [newMeeting, setNewMeeting] = useState({
        participantId: '',
        audience: '',
        startDate: '',
        duration: '',
        activityName: ''
    })

    const [participantColumn, setParticipantColumn] = useState([])
    const [participantData, setParticipantData] = useState([])

    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide delete modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    // get meeting details for edit form
    useEffect(() => {
        if (editId) {
            dispatch(meetingDetails(editId))
                .then((response) => {
                   
                    let meeting = {
                        duration: response.payload.content.duration,
                        startDate: response.payload.content.startDate,
                    }
                    setNewMeeting({ ...newMeeting, ...meeting })
                    setEditMode(true)
                    props.setMediaModal(true)
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                })
        }
    }, [editId])

    const listingName = () => {
        if (path === `/admin/project/online-interview-activity/${params.projectid}/${params.activityid}`) {
            return 'Online In-Depth Interview'
        } else if (path === `/admin/project/inperson-forum-activity/${params.projectid}/${params.activityid}`) {
            return 'Focus Group Discussion'
        }
    }

    // Column values based on activity type
    useEffect(() => {
        if (path === `/admin/project/online-interview-activity/${params.projectid}/${params.activityid}`) {
            setParticipantColumn([
                { Name: "Topic", Type: 'text', key: 'topic' },
                { Name: 'Respondent Name', Type: 'text', key: 'respondent.userName' },
                { Name: "Duration", Type: 'text', key: 'duration' },
                { Name: "Start Date", Type: 'date', key: 'startDate' },
                { Name: "Start Time", Type: 'time', key: 'startDate' },
                { Name: "Status", Type: 'text', key: 'status' },
                {
                    Name: "Action", Type: 'action', options: [
                        {
                            icon: CallIcon,
                            type: 'join meeting'
                        },
                        {
                            icon: EditIcon,
                            setID: setEditId,
                            type: 'delete'
                        },
                        {
                            icon: TickIcon,
                            setID: setChangeStatusId,
                            type: 'delete'
                        },
                        {
                            icon: Deleteicon,
                            setID: setDelID,
                            type: 'delete'
                        },


                    ]
                }
            ])
        } else if (path === `/admin/project/inperson-forum-activity/${params.projectid}/${params.activityid}`) {
            setParticipantColumn([
                { Name: "Topic", Type: 'text', key: 'topic' },
                { Name: 'Audience', Type: 'text', key: 'audience.title' },
                { Name: "Duration", Type: 'text', key: 'duration' },
                { Name: "Start Date", Type: 'date', key: 'startDate' },
                { Name: "Start Time", Type: 'time', key: 'startDate' },
                { Name: "Status", Type: 'text', key: 'status' },
                {
                    Name: "Action", Type: 'action', options: [
                        {
                            icon: CallIcon,
                            type: 'join meeting'
                        },
                        {
                            icon: EditIcon,
                            setID: setEditId,
                            type: 'delete'
                        },
                        {
                            icon: TickIcon,
                            setID: setChangeStatusId,
                            type: 'delete'
                        },
                        {
                            icon: Deleteicon,
                            setID: setDelID,
                            type: 'delete'
                        },


                    ]
                }
            ])
        }
    }, [])


    const getMeetings = (page = 1) => {
        dispatch(getAllActivityMeetings({ page: page, limit: 5, activityId: params.activityid }))
            .then((response) => {
              
                setParticipantData(response.payload.content.meetings)
                setLoading(false)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
                setLoading(false)
            })
    }

    useEffect(() => {
        getMeetings()
    }, [])


    const delMeeting = () => {
        dispatch(deleteMeeting(delId))
            .then((response) => {
                setDelID()
                getMeetings(activityMeetingState.currentPage)
                Swal({
                    title: "Deleted",
                    text: "Response Deleted",
                    icon: 'success'
                })
            })
            .catch(err => {
                setDelID()
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })

    }

    const editMeetingDetails = (id, data) => {
        dispatch(editMeeting(id, data))
            .then((response) => {

                getMeetings(activityMeetingState.currentPage)
                props.setMediaModal(false)
                setEditMode(false)
                
                Swal({
                    title: "Edited",
                    text: "Changes saved",
                    icon: 'success'
                }).then(() => setNewMeeting({
                    participantId: '',
                    audience: '',
                    startDate: '',
                    duration: '',
                    activityName: ''
                }))

            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })

    }

    // For changing status of the interview
    useEffect(() => {
        if (changeStatusId) {
            editMeetingDetails(changeStatusId, { status: 'completed' })
        }
    }, [changeStatusId])


    return (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <>
                        {/* Delete Modal */}
                        <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={delMeeting} />

                        <Modal isOpen={props.showMediaModal} toggle={() => props.setMediaModal(!props.showMediaModal)} className="modal-dialog-centered modal-lg shadow-none" >
                            <ModalHeader className="bg-white" toggle={() => props.setMediaModal(!props.showMediaModal)}>{isEdit ? 'Edit Meeting' : 'Create Meeting'}</ModalHeader>


                            <Formik
                                initialValues={newMeeting}
                                enableReinitialize={true}
                                onSubmit={(values, { setSubmitting }) => {
                                   
                                    setSubmitting(true);
                                    if (isEdit) {
                                        editMeetingDetails(editId, { duration: values.duration, startDate: values.startDate })
                                    } else {
                                        let newValues = {
                                            activityId: params.activityid,
                                            activityType: listingName(),
                                            ...values
                                        }


                                        Swal({
                                            title: "Please wait!",
                                            text: "Saving data...",
                                            type: "info",
                                            buttons: false,
                                            closeModal: false,
                                            closeOnClickOutside: false
                                        });

                                        dispatch(createActivityMeeting(newValues))
                                            .then((response) => {
                                                Swal.stopLoading()
                                                Swal.close()
                                                setNewMeeting({})
                                                Swal({
                                                    title: "Saved",
                                                    text: "Meeting Saved",
                                                    icon: 'success'
                                                }).then(() => {
                                                    getMeetings()
                                                    setNewMeeting({
                                                        participantId: '',
                                                        audience: '',
                                                        startDate: '',
                                                        duration: '',
                                                        activityName: ''
                                                    })
                                                    props.setMediaModal(false)
                                                })
                                            })
                                            .catch(err => {
                                                Swal.stopLoading()
                                                Swal.close()
                                                Swal({
                                                    title: err.response ? err.response.data.name : "Error",
                                                    text: err.response ? err.response.data.content : "Something went wrong",
                                                    icon: 'error'
                                                })
                                            })
                                    }

                                }}


                                const validationSchema={Yup.object().shape({
                                    // participantId: Yup.string().required("Participant is required"),
                                    // audience: Yup.string().required("Audience is required"),
                                    startDate: Yup.date().required("Start Date is required"),
                                    duration: Yup.string().required("Duration is required"),

                                })}


                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue
                                    } = props;


                                    if (isEdit) {
                                        const startDate = new Date(values.startDate)
                                        values.startDate = new Date(startDate.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19)
                                    }

                                    return (

                                        <ModalBody>
                                            <div className='container'>
                                                <form autoComplete="off" onSubmit={handleSubmit}>

                                                    {/* Start of form */}

                                                    <div className='row'>
                                                        {
                                                            !isEdit &&
                                                            <>
                                                                <div className='col-md-6 col-sm-12'>
                                                                    <FormGroup>
                                                                        <Label for='duration'>Title</Label>
                                                                        <Input type="text"
                                                                            className={errors.activityName && touched.activityName ? styles.is_invalid : 'valid'}
                                                                            name="activityName"
                                                                            placeholder="Title"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.activityName}
                                                                        />

                                                                        {errors.activityName && touched.activityName && (
                                                                            <div>
                                                                                {errors.activityName}
                                                                            </div>
                                                                        )}
                                                                    </FormGroup>
                                                                </div>

                                                                {
                                                                    listingName() === 'Online In-Depth Interview' &&
                                                                    <div className='col-md-6 col-sm-12'>
                                                                        <FormGroup>
                                                                            <Label for='respondent'>Participant</Label>
                                                                            <Input type="select"
                                                                                className={errors.participantId && touched.participantId ? styles.is_invalid : 'valid'}
                                                                                name="participantId"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.participantId}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {
                                                                                    participants.map(participant => {
                                                                                        return <option value={participant._id}>{participant.userName}</option>
                                                                                    })
                                                                                }
                                                                            </Input>

                                                                            {errors.participantId && touched.participantId && (
                                                                                <div>
                                                                                    {errors.participantId}
                                                                                </div>
                                                                            )}

                                                                        </FormGroup>
                                                                    </div>
                                                                }
                                                                {

                                                                    listingName() === 'Focus Group Discussion' &&
                                                                    <div className='col-md-6 col-sm-12'>
                                                                        <FormGroup>
                                                                            <Label for='audience'>Audience</Label>
                                                                            <Input type="select"
                                                                                className={errors.audience && touched.audience ? styles.is_invalid : 'valid'}
                                                                                name="audience"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.audience}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {
                                                                                    audiences.map(audience => {
                                                                                        return <option value={audience._id}>{audience.title}</option>
                                                                                    })
                                                                                }
                                                                            </Input>

                                                                            {errors.audience && touched.audience && (
                                                                                <div>
                                                                                    {errors.audience}
                                                                                </div>
                                                                            )}
                                                                        </FormGroup>
                                                                    </div>
                                                                }
                                                            </>
                                                        }


                                                        <div className='col-md-6 col-sm-12'>
                                                            <FormGroup>
                                                                <Label for='duration'>Duration</Label>
                                                                <Input type="number"
                                                                    className={errors.duration && touched.duration ? styles.is_invalid : 'valid'}
                                                                    name="duration"
                                                                    placeholder="Duration"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.duration}
                                                                />

                                                                {errors.duration && touched.duration && (
                                                                    <div>
                                                                        {errors.duration}
                                                                    </div>
                                                                )}
                                                            </FormGroup>
                                                        </div>

                                                        <div className='col-md-6 col-sm-12'>
                                                            <FormGroup>
                                                                <Label for='startDate'>Start Date</Label>
                                                                <Input type="date"
                                                                    className={errors.startDate && touched.startDate ? styles.is_invalid : 'valid'}
                                                                    name="startDate"
                                                                    placeholder="Start Date"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.startDate}
                                                                />

                                                                {errors.startDate && touched.startDate && (
                                                                    <div>
                                                                        {errors.startDate}
                                                                    </div>
                                                                )}
                                                            </FormGroup>
                                                        </div>

                                                        <div className='col-12'>
                                                            <div className="text-right mt-2">
                                                                <Button className={` ${styles.bg_blue} mr-3`} type="submit">
                                                                    Save
                                                                </Button>
                                                                <Button className={` bg-light text-muted`}
                                                                    style={{ border: '1px solid #6c757d ' }}
                                                                    onClick={() => props.setMediaModal(!props.showMediaModal)} type="button">
                                                                    Cancel
                                                                </Button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </ModalBody>
                                    )
                                }}
                            </Formik>



                        </Modal>

                        <LoadingOverlay tag="div" active={isLoading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "#fff",
                                    opacity: 0.5,
                                }),
                            }}
                            spinner={
                                <Loader color="#6c757d" active type="ball-pulse" />
                            }>

                            {/* Listing */}
                            <div className="table_border">
                                <Listing columnName={participantColumn}
                                    data={participantData}
                                    currentPage={activityMeetingState.currentPage ? activityMeetingState.currentPage : 1}
                                    totalPages={activityMeetingState.totalPages ? activityMeetingState.totalPages : 1}
                                    getListingData={(currentPage, dataLimit) => getMeetings(currentPage, dataLimit)}
                                    startLoading={() => setLoading(true)}
                                />
                            </div>

                        </LoadingOverlay>
                    </>
                )}

            />
        </TransitionGroup>


    )
}

export default Meeting
