import React, { useEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';

import SurveyFormSteps from '../Project/Activity/create/SurveyForm/SurveyFormSteps';

import Question_With_Radio from '../../../assets/components/survey-questions/Question_With_Radio';
import Question_With_Checkbox from '../../../assets/components/survey-questions/Question_With_Checkbox';
import Question_With_LongText from '../../../assets/components/survey-questions/Question_With_LongText';
import Question_With_Image from '../../../assets/components/survey-questions/Question_With_Image';
import Question_With_Image_Text from '../../../assets/components/survey-questions/Question_With_Image_Text';
import Question_With_Video from '../../../assets/components/survey-questions/Question_With_Video';
import Question_With_Video_Text from '../../../assets/components/survey-questions/Question_With_Video_Text';
import Question_With_Grid from '../../../assets/components/survey-questions/Question_With_Grid';
import PageHeader from '../../../assets/components/pageheader/PageHeader';
import SurveyIcon from '../../../assets/images/survey_icon.png';
import Banner from '../../../assets/images/interview_banner.jpg'
import styles from '../../../assets/css/rstyling.module.css'
import ArrowLeft from '../../../assets/images/arrow_left.png'
import Question_With_Spectrum from '../../../assets/components/survey-questions/Question_With_Spectrum';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getActivity, getActivityImg } from '../../../actions/activities_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import ResizeDetector from 'react-resize-detector';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import SecureLS from 'secure-ls';
import CryptoJS from 'crypto-js';
import Question_With_Slider from '../../../assets/components/survey-questions/Question_With_Slider';




// ********** This component is used to show how the survey form will look to participants ****************


const MiniSurveyPreview = (props) => {
    const ls = new SecureLS()
    const [steps, setSteps] = useState([]);
    const [currentPage, setCurrentPage] = useState();
    const history = useNavigate()
    const [audience, set_Audience] = useState('')
    const params = useParams()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    // Json Response
    const [surveyContent, set_surveyContent] = useState({});
    const [surveyDetails, setSurveyDetails] = useState({})
    const [qsImg, setqsImg] = useState({})
    const location = useLocation();
    

    useEffect(() => {

        setSteps([]);

        let quesNo = 0;

        // Set Pages Content
        surveyContent.pages && surveyContent.pages.map((page, i) => {

            let pageContent = <React.Fragment>

                {page.questions.map((question, index) => {

                    quesNo++;

                    switch (question.questionType) {

                        case 'radio':

                            return <Question_With_Radio
                                key={index}
                              id={'radio' + CryptoJS.lib.WordArray.random(5)}
                                name={page.pageName + Math.floor(Math.random() * 100) + (index + 1)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                otherText={question.otherText}
                                isOtherAllowed={question.isOtherAllowed}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />
                            case 'radio script':

                            return <Question_With_Radio
                                key={index}
                              id={'radio-script' + CryptoJS.lib.WordArray.random(5)}
                                name={page.pageName + Math.floor(Math.random() * 100) + (index + 1)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                otherText={question.otherText}
                                isOtherAllowed={question.isOtherAllowed}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                        case 'checkbox':

                            return <Question_With_Checkbox
                                key={index}
                                id={'check' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionFile={question.questionFile}
                                questionVideo={question.questionVideo}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                otherText={question.otherText}
                                isOtherAllowed={question.isOtherAllowed}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                            case 'checkbox script':

                            return <Question_With_Checkbox
                                key={index}
                                id={'check-script' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionFile={question.questionFile}
                                questionVideo={question.questionVideo}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                otherText={question.otherText}
                                isOtherAllowed={question.isOtherAllowed}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                        case 'long text':

                            return <Question_With_LongText
                                key={index}
                                id={'longText' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionFile={question.questionFile}
                                questionVideo={question.questionVideo}
                                mediaType={question.mediaType}
                                answer={question.answer}
                                textLimit={question.textLimit}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                        case 'image':

                            return <Question_With_Image
                                key={index}
                                id={'image' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionFile={question.questionFile}
                                questionVideo={question.questionVideo}
                                mediaType={question.mediaType}
                                image={question.image}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                comment={question.comment}
                                isComment={question.isComment}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                        case 'image text':

                            return <Question_With_Image_Text
                                key={index}
                                id={'image-text' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionFile={question.questionFile}
                                questionVideo={question.questionVideo}
                                mediaType={question.mediaType}
                                image={question.image}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                answer={question.answer}
                                textLimit={question.textLimit}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                        case 'video':

                            return <Question_With_Video
                                key={index}
                                id={'video' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionFile={question.questionFile}
                                questionVideo={question.questionVideo}
                                mediaType={question.mediaType}
                                video={question.video}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                comment={question.comment}
                                isComment={question.isComment}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                        case 'video text':

                            return <Question_With_Video_Text
                                key={index}
                                id={'video-text' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                video={question.video}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                answer={question.answer}
                                textLimit={question.textLimit}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                        case 'grid':

                            return <Question_With_Grid
                                key={index}
                                id={'grid' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionFile={question.questionFile}
                                questionVideo={question.questionVideo}
                                mediaType={question.mediaType}
                                rows={question.rows}
                                columns={question.columns}
                                selected={question.selected}
                                comment={question.comment}
                                isComment={question.isComment}
                                uniqueSelected={question.uniqueSelected}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                        case 'spectrum':

                            return <Question_With_Spectrum
                                key={index}
                                id={"spectrum" + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionFile={question.questionFile}
                                questionVideo={question.questionVideo}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />


                            case 'slider':

                            return <Question_With_Slider
                                key={index}
                                id={"slider" + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionFile={question.questionFile}
                                questionVideo={question.questionVideo}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent.survey}
                                setContent={set_surveyContent}
                                isAnswered={true}
                            />

                        default:
                            break;
                    }

                })}

            </React.Fragment>

            // Set Total Page Steps
            setSteps(oldSteps => [
                ...oldSteps,
                {
                    name: page.pageName,
                    component: pageContent,
                    percentage: parseInt(((i + 1) / surveyContent.pages.length) * 100),
                    currentPage,
                    setCurrentPage,
                    page
                }
            ]);

        });

    }, [surveyContent, currentPage]);


    // Getting survey from api
    useEffect(() => {
        if (params.activityid !== 'undefined') {
            dispatch(getActivity(params.activityid))
                .then((response) => {
                    setSurveyDetails(response.payload.content)
                    set_surveyContent(response.payload.content.survey)
                    
                    set_Audience(response.payload.content.activityAudience)
                    setLoading(false)
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        }
        dispatch(getActivityImg(params.activityid))
        .then((response) =>{
            try{
           
            setqsImg(response.payload.content.fileData)}catch(err){console.log('not img found')}
            
        })
        .catch(err=>{
            console.log(err)
        })
    }, [])

    const [currentWidth, setWidth] = useState()

    const layoutController = (width) => {

        width = parseInt(width);

        if (width <= 1660 && width >= 1426)
            return {
                column_1: { mainColClass: "col-12 mb-5", subColClass: "col-6", isShowReferFreind: false },
                column_2: { mainColClass: "col-9 mb-3", subColClass: "col-6" },
                column_3: { mainColClass: "col-3 mb-3", subColClass: "col-12" },
                isShowReferFreind: true
            }

        else if (width <= 1425 && width >= 1271)
            return {
                column_1: { mainColClass: "col-12 mb-5", subColClass: "col-6", isShowReferFreind: false },
                column_2: { mainColClass: "col-12 mb-3", subColClass: "col-6" },
                column_3: { mainColClass: "col-12 mb-3", subColClass: "col" },
                isShowReferFreind: true
            }

        else if (width <= 1270 && width >= 1011)
            return {
                column_1: { mainColClass: "col-12 mb-5", subColClass: "col-6", isShowReferFreind: false },
                column_2: { mainColClass: "col-12 mb-3", subColClass: "col-6" },
                column_3: { mainColClass: "col-12 mb-3", subColClass: "col-6" },
                isShowReferFreind: true
            }

        else if (width <= 1010)
            return {
                column_1: { mainColClass: "col-12 mb-5", subColClass: "col-6", isShowReferFreind: false },
                column_2: { mainColClass: "col-12 mb-3", subColClass: "col-12", },
                column_3: { mainColClass: "col-12 mb-3", subColClass: "col-12" },
                isShowReferFreind: true
            }

        else
            return {
                column_1: { mainColClass: "col-xl-3", subColClass: "col-xl-12", isShowReferFreind: true },
                column_2: { mainColClass: "col-xl-6", subColClass: "col-6" },
                column_3: { mainColClass: "col-xl-3", subColClass: "col-12" },
                isShowReferFreind: false
            }

    }

    const sharableLink = () => {
         
        if(audience === 'Minimal'){
            return `https://app.consumerconnect.net/sharable-survey/${params.activityid}?audience=minimal`
        }
         if(audience === 'Everyone'){
           return  `https://app.consumerconnect.net/sharable-survey/${params.activityid}?audience=everyone`
         }
         else{
            return  `https://app.consumerconnect.net/sharable-survey/${params.activityid}`
         }
        }  
        

    

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (


                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // top: '50%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>
                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                        {/* Header */}
                        <div className="admin_header">
                            <PageHeader
                                heading={surveyDetails.name} img={SurveyIcon}
                                isSubheading={true}
                                subHeadingText={surveyDetails.description}
                                btnGroup={true}
                                btn1Click={() => setWidth(width)}
                                btn2Click={() => setWidth(470)}
                                btn3Click={() => setWidth(400)}

                            />
                        </div>

                        {/* Back Icon */}
                        <span onClick={() => {
                            location.state && location.state.from === 'create' ?
                            history(`/researcher/edit-minisurvey/${params.activityid}`) :
                            history(-1)
                        }}
                            style={{ cursor: 'pointer' }}>
                            <img src={ArrowLeft} alt="" width="8px" />
                            <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                        </span>
                        {/* Image Display */}

                        {
                            surveyDetails.bannerImage &&
                            <div className="mx-3 mb-4">
                                <div className="pt-3">
                                    <div className="position-relative">
                                        <img src={`data:${qsImg.type} ;base64, ${qsImg.data}`} className={`img-fluid border ${styles.banner_image}`} alt="banner-image" width={200} />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="container-fluid" style={{ width: currentWidth }}>
                            {
                                steps.length > 0 && <SurveyFormSteps showNavigation={false} steps={steps} width={currentWidth} />
                            }

                        </div>
                    </TransitionGroup>

                   
                                     <div className="row">
                                        <div className="col-12">
                                        <div className="mt-3">
                        <span style={{ color: '#bbb', fontSize: '0.98rem', fontWeight: '500' }}>Share the Survey using the following link</span>
                    </div>
                                        <div className={styles.refer_link + " input-group my-3"}>
                                            <input id="copy-refer-link" type="text" className="form-control" placeholder="Refer Link" value={sharableLink()} disabled={true} />
                                        </div>
                                        <div className="input-group-prepend" style={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }} onClick={() => { navigator.clipboard.writeText(document.getElementById('copy-refer-link').value) }}>
                            <button className={styles.copy_txt_icon + " input-group-text"}>Copy link</button>
                        </div>
                                        </div>
                                    </div>
                                

                </LoadingOverlay>

            )}
        />
    )

}

export default MiniSurveyPreview;