import React from 'react'
import { Button, UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const Actions = (props) => {
    const history = useNavigate()
   //console.log('props in actions ->', props)
    // For getting Custom URL based on rows
    const getCustomBtnUrl = (url, id, linkFor) => {

        if (linkFor === 'analytics') {

            switch (props.row.activityType) {
                case 'Diary':
                    return history(url + "/journal-analytics/" + id + "/" + props.row._id)
                case 'Forum':
                    return history(url + "/forum-analytics/" + id + "/" + props.row._id)
                case 'Survey':
                    return history(url + "/survey-analytics/" + id + "/" + props.row._id)

                default:
                    break;
            }

        } else if (linkFor === 'activity report') {

            switch (props.row.activityType) {
                case 'Diary':
                    return history(url + "/journal-report/" + id + "/" + props.row._id)
                case 'Forum':
                    return history(url + "/forum-report/" + id + "/" + props.row._id)
                case 'Survey':
                    return history(url + "/survey-report/" + id + "/" + props.row._id)
                case 'Screener':
                    return history(url + "/survey-report/" + id + "/" + props.row._id)
                // case 'In-Depth Interview':
                //     return history(url + "/inperson-interview-report/" + id + "/" + props.row._id)
                // case 'Online In-Depth Interview':
                //     return history(url + "/online-interview-report/" + id + "/" + props.row._id)
                // case 'Focus Group Discussion':
                //     return history(url + "/inperson-forum-report/" + id + "/" + props.row._id)
                default:
                    break;
            }

        }
    }

    // // For getting Custom URL based on rows for dropdown options
    const getCustomDropUrl = (id, linkFor, url) => {

        if (linkFor === 'edit activity') {
            switch (props.row.activityType) {
                case 'Diary':
                    return history(url + "/edit-journal/" + id + "/" + props.row._id)
                case 'Forum':
                    return history(url + "/edit-forum/" + id + "/" + props.row._id)
                case 'Survey':
                    return history(url + "/edit-survey/" + id + "/" + props.row._id)
                case 'In-Depth Interview':
                    return history(url + "/edit-interview/" + id + "/" + props.row._id)
                case 'Online In-Depth Interview':
                    return history(url + "/edit-onlineinterview/" + id + "/" + props.row._id)
                case 'Focus Group Discussion':
                    return history(url + "/edit-inpersonforum/" + id + "/" + props.row._id)
                default:
                    break;
            }

        } else if (linkFor === 'copy activity') {

            switch (props.row.activityType) {
                case 'Diary':
                    return history(url + "/create-journal/" + id + "/" + props.row._id)
                case 'Forum':
                    return history(url + "/create-forum/" + id + "/" + props.row._id)
                case 'Survey':
                    return history(url + "/create-survey/" + id + "/" + props.row._id)
                case 'In-Depth Interview':
                    return history(url + "/create-interview/" + id + "/" + props.row._id)
                case 'Online In-Depth Interview':
                    return history(url + "/create-onlineinterview/" + id + "/" + props.row._id)
                case 'Focus Group Discussion':
                    return history(url + "/create-inpersonforum/" + id + "/" + props.row._id)
                default:
                    break;
            }

        }
    }

    const activityUrl = (id, tab, url) => {
        if (tab === '3') {
            switch (props.row.activityType) {
                case 'Diary':
                    return history(url + "/preview-journal/" + id + "/" + props.row._id)
                case 'Forum':
                    return history(url + "/preview-forum/" + id + "/" + props.row._id)
                case 'Survey':
                    return history(url + "/preview-survey/" + id + "/" + props.row._id)
                case 'In-Depth Interview':
                    return history(url + "/preview-interview/" + id + "/" + props.row._id)
                case 'Online In-Depth Interview':
                    return history(url + "/preview-onlineinterview/" + id + "/" + props.row._id)
                case 'Focus Group Discussion':
                    return history(url + "/preview-inpersonforum/" + id + "/" + props.row._id)
                default:
                    break;
            }
        } else {
            switch (props.row.activityType) {
                case 'Diary':
                    return history(url + "/journal-activity/" + id + "/" + props.row._id + "?tab=" + tab)
                case 'Forum':
                    return history(url + "/forum-activity/" + id + "/" + props.row._id + "?tab=" + tab)
                case 'Survey':
                    return history(url + "/survey-activity/" + id + "/" + props.row._id + "?tab=" + tab)
                case 'In-Depth Interview':
                    return history(url + "/interview-activity/" + id + "/" + props.row._id + "?tab=" + tab)
                case 'Online In-Depth Interview':
                    return history(url + "/online-interview-activity/" + id + "/" + props.row._id + "?tab=" + tab)
                case 'Focus Group Discussion':
                    return history(url + "/inperson-forum-activity/" + id + "/" + props.row._id + "?tab=" + tab)
                default:
                    break;
            }
        }

    }

    return (
        <div className="d-flex">
            {
                props.options.map((options, index) => {

                    if (options.type === 'button') {

                        // Button
                        return <Button key={index} style={{
                            backgroundColor: props.row.isArchived ? '#eee' : 'white',
                            border: props.row.isArchived ? '1px solid #eee' : '1px solid white'
                        }}>
                          
                            <img src={options.icon} alt="" onClick={() => options.onClick ? options.onClick() :
                                !options.isDifferentUrl ? history(options.url + "/" + (props.row._id ? props.row._id : props.row.author._id)) : 
                                    getCustomBtnUrl(options.url, options.id, options.linkFor)
                            }
                            /></Button>

                    } else if(options.type === 'quick-survey-analytics'){
                    if(!props.row.projectType){
                        return <Button key={index} style={{
                            backgroundColor: props.row.isArchived ? '#eee' : 'white',
                            border: props.row.isArchived ? '1px solid #eee' : '1px solid white'
                        }}>
                            <img src={options.icon} alt="" onClick={() => options.onClick ? options.onClick() :
                                !options.isDifferentUrl ? history(options.url + "/" + (props.row._id ? props.row._id : props.row.original[options.idObjectName]._id)) :
                                    getCustomBtnUrl(options.url, options.id, options.linkFor)
                            }
                            /></Button>
                    }
                    }
                    else if(options.type === 'quick-survey-edit'){
                        if(!props.row.projectType){
                            return <Button key={index} style={{
                                backgroundColor: props.row.isArchived ? '#eee' : 'white',
                                border: props.row.isArchived ? '1px solid #eee' : '1px solid white'
                            }}>
                                <img src={options.icon} alt="" onClick={() => options.onClick ? options.onClick() :
                                    !options.isDifferentUrl ? history(options.url + "/" + (props.row._id ? props.row._id : props.row.original[options.idObjectName]._id)) :
                                        getCustomBtnUrl(options.url, options.id, options.linkFor)
                                }
                                /></Button>
                        }
                        }
                        else if(options.type === 'project-edit'){
                            if(props.row.projectType){
                                return <Button key={index} style={{
                                    backgroundColor: props.row.isArchived ? '#eee' : 'white',
                                    border: props.row.isArchived ? '1px solid #eee' : '1px solid white'
                                }}>
                                    <img src={options.icon} alt="" onClick={() => options.onClick ? options.onClick() :
                                        !options.isDifferentUrl ? history(options.url + "/" + (props.row._id ? props.row._id : props.row.original[options.idObjectName]._id)) :
                                            getCustomBtnUrl(options.url, options.id, options.linkFor)
                                    }
                                    /></Button>
                            }
                            } 
                            else if(options.type === 'project-participants'){
                                if(props.row.projectType){
                                    return <Button key={index} style={{
                                        backgroundColor: props.row.isArchived ? '#eee' : 'white',
                                        border: props.row.isArchived ? '1px solid #eee' : '1px solid white'
                                    }}>
                                        <img src={options.icon} alt="" onClick={() => options.onClick ? options.onClick() :
                                            !options.isDifferentUrl ? history(options.url + "/" + (props.row._id ? props.row._id : props.row.original[options.idObjectName]._id)) :
                                                getCustomBtnUrl(options.url, options.id, options.linkFor)
                                        }
                                        /></Button>
                                }
                                }  
                    
                    else if (options.type === 'badge') {

                        // Badge
                        return <span key={index} className="mb-2 mr-2 rbt-token" style={{ backgroundColor: 'rgb(232, 240, 254)', color: '#006FA7', border: "1px solid #006FA7" }}>
                            {options.badgeText}
                        </span>

                    } else if (options.type === 'audio') {
                        // Audio Button
                        return <Button key={index} className="bg-white border border-white"><img src={options.icon} alt=""
                            onClick={() => options.setAudio(props.row.file)}
                        /></Button>

                    } else if (options.type === 'attachment') {
                        // Attachment Button
                        return <Button key={index} className="bg-white border border-white"><img src={options.icon} alt=""
                            onClick={() => options.setAttachment(props.row.attachment)}
                        /></Button>

                    } else if (options.type === 'join meeting') {
                        // Join Meeting Button
                        return <Button key={index} className="bg-white border border-white">
                            <a href={props.row.startUrl} target="_blank" ><img src={options.icon} alt="" /> </a>
                        </Button>

                    } else if (options.type === 'delete') {

                        // Delete Button
                        return <Button key={index} style={{ backgroundColor: props.row.isArchived ? '#eee' : 'white', border: props.row.isArchived ? '1px solid #eee' : '1px solid white' }}>
                            <img src={options.icon} alt=""
                                onClick={() => options.setID(props.row._id)}
                            /></Button>

                    }
                    else if (options.type === 'complete') {

                        // Delete Button
                        return <Button key={index} style={{ backgroundColor: props.row.isArchived ? '#eee' : 'white', border: props.row.isArchived ? '1px solid #eee' : '1px solid white' }}>
                            <img src={options.icon} alt=""
                                onClick={() => options.setID(props.row._id)}
                            /></Button>

                    } else {

                        return <div key={index} className="d-block w-100 text-center">
                            {/* Drop down */}
                            <UncontrolledButtonDropdown className="custom_drop">
                                <DropdownToggle color="link">
                                    <img src={options.icon} alt="details" />
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-hover-link" style={{ marginLeft: '-12.35rem' }}>
                                    {
                                        options.droplist.map((option, index) => {

                                            if (options.isDifferentUrl) {

                                                if (options.linkFor === 'view activity') {
                                                    // options according to activity type
                                                    if (props.row.activityType === 'Online In-Depth Interview' || props.row.activityType === 'In-Depth Interview' || props.row.activityType === 'Focus Group Discussion') {
                                                        if (option.Name !== 'Excerpts') {
                                                            return <DropdownItem key={option.Name}
                                                                onClick={() => option.onClick ? option.onClick() : activityUrl(option.id, option.tab, options.url)}>
                                                                <span>{option.Name}</span>
                                                            </DropdownItem>
                                                        }

                                                    } else if (props.row.activityType === 'Diary' || props.row.activityType === 'Survey') {
                                                        if (option.Name !== 'Files') {
                                                            return <DropdownItem key={option.Name}
                                                                onClick={() => option.onClick ? option.onClick() : activityUrl(option.id, option.tab, options.url)}>
                                                                <span>{option.Name}</span>
                                                            </DropdownItem>
                                                        }

                                                    } else if (props.row.activityType === 'Forum') {
                                                        return <DropdownItem key={option.Name}
                                                            onClick={() => option.onClick ? option.onClick() : activityUrl(option.id, option.tab, options.url)}>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    }

                                                } else if (options.linkFor === 'activity settings') {

                                                    if (option.linkFor) {
                                                        return <DropdownItem key={option.Name}
                                                            onClick={() => option.onClick ? option.onClick() : getCustomDropUrl(option.id, option.linkFor, options.url)}>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>

                                                    } else {
                                                        // Show hide archive unarchive based on activity status
                                                        if (props.row.status === 'archive') {

                                                            if (option.Name !== 'Archive') {
                                                                return <DropdownItem key={option.Name}
                                                                    onClick={() => option.setID(props.row._id)}>
                                                                    <span>{option.Name}</span>
                                                                </DropdownItem>
                                                            }

                                                        } else if (props.row.status === 'scheduled') {
                                                            if (option.Name !== 'Unarchive') {
                                                                return <DropdownItem key={option.Name}
                                                                    onClick={() => option.setID(props.row._id)}>
                                                                    <span>{option.Name}</span>
                                                                </DropdownItem>
                                                            }
                                                        } else if (props.row.status === 'draft') {
                                                            if (option.Name !== 'Unarchive') {
                                                                return <DropdownItem key={option.Name}
                                                                    onClick={() => option.setID(props.row._id)}>
                                                                    <span>{option.Name}</span>
                                                                </DropdownItem>
                                                            }
                                                        }

                                                        // } else {
                                                        //     if (props.row.status === 'archive') {

                                                        //         if (option.Name !== 'Archive' && option.Name !== 'Unpublish') {
                                                        //             return <DropdownItem key={option.Name}
                                                        //                 onClick={() => option.setID(props.row._id)}>
                                                        //                 <span>{option.Name}</span>
                                                        //             </DropdownItem>
                                                        //         }

                                                        //     } else {
                                                        //         if (option.Name !== 'Unarchive' && option.Name !== 'Unpublish') {
                                                        //             return <DropdownItem key={option.Name}
                                                        //                 onClick={() => option.setID(props.row._id)}>
                                                        //                 <span>{option.Name}</span>
                                                        //             </DropdownItem>
                                                        //         }
                                                        //     }
                                                        // }

                                                    }
                                                } else if (options.linkFor === 'general forum') {
                                                    if (option.linkFor === 'change status') {
                                                        // Show hide archive unarchive based on activity status
                                                        if (props.row.status === 'archive') {

                                                            if (option.Name !== 'Archive') {
                                                                return <DropdownItem key={option.Name}
                                                                    onClick={() => option.setID(props.row._id)}>
                                                                    <span>{option.Name}</span>
                                                                </DropdownItem>
                                                            }

                                                        } else if (props.row.status === 'scheduled' || props.row.status === 'draft') {
                                                            if (option.Name !== 'Unarchive') {
                                                                return <DropdownItem key={option.Name}
                                                                    onClick={() => option.setID(props.row._id)}>
                                                                    <span>{option.Name}</span>
                                                                </DropdownItem>
                                                            }
                                                        }
                                                    } else if (option.linkFor === 'getId') {
                                                        return <DropdownItem key={option.Name}
                                                            onClick={() => option.setID(props.row._id)}>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    } else {
                                                        return <DropdownItem key={option.Name}
                                                            onClick={() => option.onClick ? option.onClick() : history(option.url + "/" + props.row._id)}>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    }
                                                }

                                            } else {
                                               

                                                if (props.row.projectType) {
                                                    if (option.Name === 'View Details' && option.viewType === 'project-details') {
                                                        return <DropdownItem key={option.Name}

                                                            onClick={() => option.onClick ? option.onClick() : history(option.url + "/" + props.row._id)}>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    }
                                                }
                                                else {
                                                    if (option.Name === 'View Details' && option.viewType === 'survey-details') {
                                                        return <DropdownItem key={option.Name}

                                                            onClick={() => option.onClick ? option.onClick() : history(option.url + "/" + props.row._id)}>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    }
                                                }
                                                if (props.row.redeemPoints) {
                                                    if (option.Name === 'View User Details' && option.viewType === 'reward-user-details') {
                                                        return <DropdownItem key={option.Name}

                                                            onClick={() => option.onClick ? option.onClick() : history(option.url + "/" + props.row.userId._id)}>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    }
                                                    if (option.Name === 'Processing' && option.viewType === 'approve-reward') {
                                                        //console.log('on click option ->', option)
                                                        return <DropdownItem key={option.Name}
                                                         onClick={() => {
                                                            option.setProcessing({status:true, user: props.row.userId._id, rewardId: props.row._id})
                                                            option.setCompleted({status:false, user: '', rewardId: ''})
                                                            option.setRejected({status:false, user: '', rewardId: ''})
                                                            option.setScheduled({status:false, user: '', rewardId: ''})
                                                            
                                                            }}>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    }
                                                    if (option.Name === 'Completed' && option.viewType === 'complete-reward') {
                                                        //console.log('on click option ->', option)
                                                        return <DropdownItem key={option.Name}
                                                         onClick={() => 
                                                         {
                                                         option.setCompleted({status:true, user: props.row.userId._id, rewardId: props.row._id})
                                                         option.setProcessing({status:false, user: '', rewardId: ''})
                                                         option.setRejected({status:false, user: '', rewardId: ''})
                                                         option.setScheduled({status:false, user: '', rewardId: ''})
                                                         }
                                                        }>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    }
                                                    if (option.Name === 'Rejected' && option.viewType === 'reject-reward') {
                                                        //console.log('on click option ->', option)
                                                        return <DropdownItem key={option.Name}
                                                         onClick={() =>
                                                         {
                                                         option.setRejected({status:true, user: props.row.userId._id, rewardId: props.row._id})
                                                         option.setCompleted({status:false, user: '', rewardId: ''})
                                                         option.setProcessing({status:false, user: '', rewardId: ''})
                                                         option.setScheduled({status:false, user: '', rewardId: ''})
                                                         }
                                                        }>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    }
                                                    if (option.Name === 'Scheduled' && option.viewType === 'schedule-reward') {
                                                        //console.log('on click option ->', option)
                                                        return <DropdownItem key={option.Name}
                                                         onClick={() =>
                                                         {
                                                         option.setScheduled({status:true, user: props.row.userId._id, rewardId: props.row._id})
                                                         option.setCompleted({status:false, user: '', rewardId: ''})
                                                         option.setProcessing({status:false, user: '', rewardId: ''})
                                                         option.setRejected({status:false, user: '', rewardId: ''})
                                                         }
                                                        }>
                                                            <span>{option.Name}</span>
                                                        </DropdownItem>
                                                    }
                                                }
                                            }
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>

                        </div>
                    }

                })

            }
        </div>
    )
}

export default Actions
