import React, { useState, useEffect } from 'react'
import Projecticon from '../../../assets/images/project_header_icon.png'
import Eyeicon from '../../../assets/images/eye_icon.png'

import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png'
import { Card, CardBody } from "reactstrap";
import PageHeader from '../../../assets/components/pageheader/PageHeader'
import { useNavigate } from 'react-router'
import ResizeDetector from "react-resize-detector";
import Listing from '../../../assets/components/listing/Listing'

import AlertModal from '../../../assets/components/modal/AlertModal'

import { delActivity, getAllGeneralForum, updateActivity } from '../../../actions/activities_actions'
import Loader from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'
import SettingIcon from '../../../assets/images/settings_icon_sm.png'
import GraphIcon from '../../../assets/images/graph_icon.png'

const GeneralForumListing = () => {

    const activityState = useSelector( state => state.Activity )
    
    const dispatch = useDispatch()
    const history = useNavigate()

    const [isLoading, setLoading] = useState(true)
    const [isModalOpen, setModal] = useState(false)

    const [delId, setDelID] = useState()
    const [archiveId, setArchiveId] = useState()
    const [publishId, setPublishId] = useState()
    const [unArchiveId, setUnArchiveId] = useState()


    const [forumColumn] = useState([
        { Name: 'Activity Name', Type: 'string', key: 'name' },
        { Name: 'Description', Type: 'string', key: 'description', fixWidth:80 },
        { Name: "Dates", Type: 'qsdates', key: 'dates' },
        // { Name: "Activity Type", Type: 'string', key: 'activityType' },
        // { Name: "Status", Type: 'activity status', key: 'status' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    type: 'button',
                    url: '/admin/generalforum-activity'
                },
                {
                    icon: GraphIcon,
                    url: "/admin/generalforum-analytics",
                    type: 'button',
                },
                {
                    icon: SettingIcon,
                   
                    type: 'dropdown',
                    isDifferentUrl: true,
                    linkFor: 'general forum',
                    droplist: [
                        { Name: 'Edit', url: '/admin/edit-generalforum'},
                        // { Name: 'Archive', setID: setArchiveId, linkFor: 'change status' },
                        // { Name: 'Unarchive', setID: setUnArchiveId, linkFor: 'change status' },
                        { Name: 'Copy', url: '/admin/create-generalforum' },
                        // { Name: 'Unpublish', setID: setPublishId },
                        { Name: 'Delete', setID: setDelID, linkFor: 'getId' }
                    ]
                },
            ]
        }

    ]);

    const [forumData, setForumData] = useState([])

    const [searchString, setSearchString] = useState('')
    const [searchedForumResult, setSearchedForumData] = useState([])

    // Search forums through forum name
    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredForum = forumData.filter(row => {
            return (
                search.test(row.name)
            );
        });
        setSearchedForumData(filteredForum);
    }, [searchString]);


    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide delete modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

     // For changing status of activity
     useEffect(() => {
        (archiveId || unArchiveId || publishId) && editActivity()
    }, [archiveId, unArchiveId, publishId])


    const getForumListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(getAllGeneralForum({page: currentPage, limit: dataLimit}))
            .then(response => {
                setForumData(response.payload.content.activities)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }

    useEffect(() => {
        getForumListing()
    }, [])

    const deleteActivity = () => {
        dispatch(delActivity(delId))
        .then(response => {
            setDelID()
            getForumListing(activityState.currentPage, 5)
            Swal({
                title: "Deleted",
                text: "Activity Deleted",
                icon: 'info'
            });
        })
        .catch(err => {
            Swal({
                title: err.content ? err.content : "Error",
                text: "Please try again!",
                icon: 'error'
            });
        })
    }

    // for changing status of forum
    const editActivity = () => {
        const formData = new FormData()
        let id = ''
        if (archiveId) {
            formData.append('status', 'archive')
            id = archiveId
            setArchiveId()
        } else if (unArchiveId) {
            formData.append('status', 'scheduled')
            id = unArchiveId
            setUnArchiveId()
        } else if (publishId) {
            formData.append('status', 'archive')
            id = publishId
            setPublishId()
        }
        dispatch(updateActivity(id, formData, 1))
        .then(response => {
            getForumListing(activityState.currentPage, 5)
            Swal({
                title: "Updated",
                text: "Activity Updated",
                icon: 'info'
            });
        })
        .catch(err => {
            Swal({
                title: err.content ? err.content : "Error",
                text: "Please try again!",
                icon: 'error'
            });
        })
    }


    return (

        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>


                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                        {/* Delete Modal */}
                        <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={deleteActivity}/>

                        {
                            <>
                                <div className="project_header">
                                    <PageHeader
                                        heading="General Forums" img={Projecticon}
                                        btnText={width <= 350 ? "Create" : "Create General Forums"}
                                        btnClick={() => history("/admin/create-generalforum", { editMode: false })}
                                    />
                                </div>
                                <Card className="main-card mb-3">
                                    <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                        <div className="text-white menu-header-title text-capitalize">General Forum List</div>

                                        <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                            <ul className="navbar-nav mr-auto">
                                            </ul>
                                            <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.5rem' }}>
                                                <div className="input-group input-group-sm w-75">
                                                    <div className="input-group-prepend border-0">
                                                        <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                    </div>
                                                    <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search"
                                                        onChange={(e) => setSearchString(e.target.value)} />
                                                </div>

                                            </div>
                                        </div>
                                    </nav>

                                   {/* Loader */}
                                   <LoadingOverlay tag="div" active={isLoading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: "#fff",
                                                opacity: 0.5,
                                            }),
                                        }}
                                        spinner={
                                            <Loader color="#6c757d" active type="ball-pulse" />
                                        }>
                                        <CardBody className="p-0">

                                            {/* Listing */}
                                            <div className="table_border">
                                                <Listing columnName={forumColumn}
                                                    data={(searchString.length > 0 || searchedForumResult.length > 0) ? searchedForumResult : forumData && forumData.length > 0 ? forumData: <></>}
                                                    currentPage={activityState.currentPage ? activityState.currentPage : 1}
                                                    totalPages={activityState.totalPages ? activityState.totalPages : 1}
                                                    getListingData={(currentPage, dataLimit) => getForumListing(currentPage, dataLimit)}
                                                    startLoading={() => setLoading(true)}
                                                />
                                            </div>

                                        </CardBody>
                                    </LoadingOverlay>
                                </Card>
                            </>
                        }

                    </TransitionGroup>
                </>
            )}
        />
    )
}

export default GeneralForumListing
