import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
export default function UnknownRoute() {
    const navigate = useNavigate();

    useEffect(() => {
      navigate('/login', { replace: true }); // Redirect to login with history replacement if an unkown/invalid route detected
    }, [navigate]);
  
    return null; 
  };

 

