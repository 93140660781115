import React,{useState, useEffect} from 'react'
import { editSurveyReply, getSurveyReply, getActivityReplyImagesRespondent } from '../../../actions/activities_reply_actions';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player'
//import styles from '../../css/styling.module.css';
import styles from '../../../assets/css/styling.module.css';


export default function RenderFile(props) {
    const [ResponseFile, setResponseFile] = useState({})
    const dispatch = useDispatch()
    //const getFile = (file) => {

    useEffect(()=>{
//console.log('props ->', props.filePath)
        dispatch(getActivityReplyImagesRespondent(props.filePath))
        .then(response => {
          //console.log('response ->', response)

            setResponseFile(response.payload.content.fileData)
            //return responseF
        })

    },[])
        

   // }

    return (
        <div>
          {  ResponseFile.type ==='video/mp4' ? <ReactPlayer
                url={`data:${ResponseFile.type};base64,${ResponseFile.data}`}
                controls
                width='100%'
            />
            :
            <img src={`data:${ResponseFile.type} ;base64, ${ResponseFile.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
          }
        </div>
    )
}
