import React, { useEffect, useState } from 'react';
import { Input, Button, Card, CardBody, InputGroup } from 'reactstrap';
import styles from '../../../../../assets/css/rstyling.module.css';
import { useNavigate, useParams } from 'react-router';
import Back from '../../../../../assets/components/back/Back';
import { getRespondentActivityDetails } from '../../../../../actions/activities_actions';
import Swal from 'sweetalert'
import { useDispatch } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const OnlineInterview = (props) => {

    const history = useNavigate()
    const params = useParams()
    const [interviewDetails, setInterviewDetails] = useState({})
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)

    const getInterviewDetails = () => {
        dispatch(getRespondentActivityDetails(params.onlineinterviewid))
            .then((response) => {
               
                
                setInterviewDetails(response.payload.content)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }
    useEffect(() => {
        getInterviewDetails()
    }, [])

    return (
        <>
            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>
                {/* Back */}
                <Back />

                <div className="pt-4 mb-2 mx-3 pl-1">
                    {/* Activity Name */}
                    <h5 style={{ color: "#000", fontWeight: 500, fontSize: "20px" }}>{interviewDetails.name}</h5>
                </div>

                <Card className="main-card mx-3 mt-3 mb-4">
                    <CardBody>
                        <h6 style={{ color: '#000', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}> {interviewDetails.description}</h6>
                       
                    </CardBody>
                </Card>

                <div className="row mx-2 py-4">

                    <div className="col-sm-6 col-12 py-3">
                        <div   style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Study Type:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.studyType ? interviewDetails.studyType: 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div   style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Client Company:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.clientCompany ? interviewDetails.clientCompany : 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div  style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Compensation:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.compensation ? interviewDetails.compensation: 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div   style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Duration (Days):</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.days ? interviewDetails.days : 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div   style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Duration Per Day (Minutes):</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.duration ? interviewDetails.duration : 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div   style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Priority:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.priority ? interviewDetails.priority: 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div    style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Country:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.country ? interviewDetails.country: 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div    style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>City:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.location ? interviewDetails.location:'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div    style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Start date:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.startDate ? new Date(interviewDetails.startDate).toLocaleDateString() : 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div    style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Project Creation Date:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.projectCreationDate ? interviewDetails.projectCreationDate : 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div     style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Project Completion date:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.projectCompletionDate ? interviewDetails.projectCompletionDate: 'Not Available'}</div>
                    </div>
                    <div className="col-sm-6 col-12 py-3">
                        <div  style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Location:</div>
                        <div style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>{interviewDetails.location ? interviewDetails.location: 'Not Available'}</div>
                    </div>
                </div>

                <div className="row mt-5 mx-2">
                    <div className="col-12">
                        <div style={{ color: '#000', fontSize: '20px', fontWeight: '500' }}>Interview Meeting Address</div>
                        <div className="mb-2" style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }}>Easily copy pasting the link to join the meeting</div>
                        <div className="d-flex m-0">
                            <div className="col-md-10 col-sm-8 col-8 p-0">
                                <InputGroup className="custom_input_group">
                                    <Input type="text" id="copy-meeting-link" readOnly value={interviewDetails.meetingLink} />
                                    <InputGroup addonType="append">
                                        <Button outline style={{ border: '1px solid #006FA7', borderRadius: '0.5rem' }} className={`${styles.bg_gray} ${styles.text_blue} my-2`} onClick={() => navigator.clipboard.writeText(document.getElementById('copy-meeting-link').value)}>Copy Link</Button>{" "}
                                    </InputGroup>
                                </InputGroup>
                            </div>
                            <div className="col-md-2 col-sm-4 col-4 ">
                                <Button style={{ backgroundColor:'#0069AB', border:'none'}}><a className="text-white" href={interviewDetails.meetingLink} target="_blank">Join Meeting</a></Button>
                            </div>
                        </div>

                    </div>
                </div>

            </LoadingOverlay >

        </>
    )


}

export default OnlineInterview


