import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createComments } from '../../../actions/activities_reply_actions';
import styles3 from '../../../assets/css/newStyling.module.css'
import styles from '../../../assets/css/styling.module.css';
import Swal from 'sweetalert'
import { FaLocationArrow } from "react-icons/fa"
const Group_Forum_Write_Comment = (props) => {

    const [comment, setComment] = useState('')
    const dispatch = useDispatch()

    const onSubmitComment = (postId) => {
        dispatch(createComments({ post: postId, description: comment }))
            .then((response) => {
                // props.getReplies()
                setComment('')
                props.getComments(postId, true, 1 , 10)
                // props.getForumReply(1, 5)
                // Swal({
                //     title: response.payload.title ? response.payload.title : "Created",
                //     text: response.payload.message ? response.payload.message : 'Comment Created',
                //     icon: 'success'
                // })
            })
            .catch(err => {
                console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }
    return (

        <div className="row px-3">

            <div className="d-inline-flex align-self-center">
                <div className="rounded-circle" style={{ backgroundColor: "", width: '38px', height: '38px' }}>
                </div>
            </div>

            <div className="col">

                <div className="input-group" style={{ border: '2px solid #ddd', borderRadius: '8px', backgroundColor: "#f7f6f2", padding: '' }}>
                    <input type="text"
                        className={"form-control " + styles.reply_comment_textbox}
                        style={{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' }}
                        placeholder={props.placeholder}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    {/* <div className="input-group-append">
                        <span className={styles.upload_media_icon}></span>
                    </div> */}
                </div>

            </div>

          <div className=''>
                <button className={`${styles3.submit_button} p-0`} style={{borderStyle:'none'}}  onClick={() => onSubmitComment(props.postId)}>
              
                            <span><FaLocationArrow size={23} /></span>
                        
                </button>
         
                </div>
        </div>

    )

}

export default Group_Forum_Write_Comment;