import React from 'react';


import Group_Forum_Reply_View from './Group_Forum_Reply_View';

const Group_Forum_Replies = (props) => {
const ForumReplies = props.ForumReplies
    return (
        <div className="mx-3">
            <Group_Forum_Reply_View ForumReplies={ForumReplies}/>
        </div>
    )

}

export default Group_Forum_Replies;