import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import { MY_NOTIFICATIONS, UPDATE_NOTIFICATIONS, MY_NEW_NOTIFICATIONS } from './types.js';

// ************* Used in Notifications Section for all 3 types of users inside pages folder *****************


// Returns an array of notifications of a logged in user
export const getMyNotifications = (page, limit) => {

    let url = `${BACK_SERVER_URL}api/notifications/myNotifications?page=${page}&limit=${limit}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: MY_NOTIFICATIONS,
        payload: request
    }
}

// admin side new notifications in testing phase
export const getMyNewNotifications = (limit) => {

    let url = `${BACK_SERVER_URL}api/notifications/myNewNotifications?limit=${limit}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: MY_NEW_NOTIFICATIONS,
        payload: request
    }
}


export const changeNotificationStatus =(id) =>{
let data = {status:'seen'}
    let url = `${BACK_SERVER_URL}api/notifications/myNotifications/change-status/${id}`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: UPDATE_NOTIFICATIONS,
        payload: request
    }

}