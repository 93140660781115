import React from 'react'
import AttachementIcon from '../../../../../assets/images/attachement_header_icon.png'
import { TransitionGroup } from 'react-transition-group';
import { useNavigate, useParams, useLocation } from 'react-router';
import PageHeader from '../../../../../assets/components/pageheader/PageHeader';
import ArrowLeft from '../../../../../assets/images/arrow_left.png'
import ReportTable from '../../../../../assets/components/report-table/ReportTable';


// *************** THis Screen is removed from the UI ******************

const InterviewReports = () => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const path = currentPath.slice(0, currentPath.lastIndexOf('/'))
    const param = useParams()
    const data = [
        { question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', responses: ['yes', 'yes', 'yes', 'yes', 'yes', 'yes', 'no'] },
        { question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', responses: ['yes', 'no', 'no', 'yes', 'yes', 'yes', 'no'] },
        { question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', responses: ['yes', 'no', 'yes', 'yes', 'yes', 'yes', 'no'] },
        { question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', responses: ['no', 'no', 'yes', 'yes', 'yes', 'yes', 'no'] },
        { question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', responses: ['yes', 'no', 'yes', 'yes', 'yes', 'yes', 'no'] },
        { question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', responses: ['yes', 'no', 'yes', 'yes', 'yes', 'yes', 'no'] },
    ]

    const getTitle = () => {
       
       
        switch (path) {
            case '/researcher/project/inperson-interview-report/' + param.projectid:
                return 'In-person Interview'
                case '/researcher/project/online-interview-report/' + param.projectid :
                    return 'Online Interview'
                case '/researcher/project/inperson-forum-report/' + param.projectid :
                    return 'In-person Forum'
            default:
                return 'In-person Interview';
        }
    }

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <div className="project_header"><PageHeader
                heading="Reports" img={AttachementIcon}
            /></div>

            <div className="container-fluid">

                {/* Back Icon */}
                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>

                <h4 className="text-capitalize m-3" style={{ fontWeight: '600', fontSize: '1rem' }}>
                    {getTitle()}
                </h4>

                <ReportTable data={data} columnName="Interviews" isTimeline={true}/>

            </div>

        </TransitionGroup >

    )
}

export default InterviewReports
