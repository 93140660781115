import React from 'react'
import styles3 from '../../assets/css/newStyling.module.css';
import landing_page_logo from '../../assets/images/landing_page_logo.png';
import login_bg_new from "../../assets/images/login_bg2.png";
import {useParams } from 'react-router-dom'
import SecureLS from 'secure-ls';

export default function InActiveActivityRedirect() {

    const params = useParams();
    const ls = new SecureLS()
    const activityName = ls.get('activity').name.name


  return (
    <div>
         <>
            <div className="container-fluid p-2 px-4">
                <div className='row justify-content-center'>
                    <div className={`row p-2 ${styles3.login_container}`}>

                        <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                            <div className={`${styles3.login_img}`}
                                style={{
                                    backgroundImage: "url(" + login_bg_new + ")",
                                }} />


                        </div>

                        <div className="col-sm-7 col-12 bg-white align-self-center">
                            <div className='row justify-content-center'>
                                <div className={`${styles3.login_section}`}>
                                    {/* <ul className="nav justify-content-center pt-5">
        <li className="nav-item">
            <img src={landing_page_logo} alt="logo" width="250px" style={{ margin: '2rem 1rem 0 0' }} />
        </li >
       
    </ul> */}
                                    <div className=" container" >



                                        <div className="container text-center">
                                            <img className={`${window.innerWidth < 768 ? ' d-none': styles3.login_logo}`} src={landing_page_logo} alt="logo" width="200px" />
                                            <p className ={`${window.innerWidth < 768 ? ' mt-4': ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1rem', paddingTop:'50px' }}>{`Thank you for your interest but ${activityName ? activityName: ''} is not active anymore!`}</p>

                                            












                                        </div>
                                    </div>
                                </div>
                            </div>










                        </div>
                    </div>
                </div>
            </div>
        </>
    </div>
  )
}