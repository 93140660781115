import React, { useEffect, useRef, useState } from 'react';
import { Input, Button } from 'reactstrap';
import styles from '../../../../../../assets/css/rstyling.module.css'
import Dropzone from 'react-dropzone';
import CreateIcon from '../../../../../../assets/images/create_icon.png'
import { useNavigate, useParams, useLocation } from 'react-router';
import PageHeader from '../../../../../../assets/components/pageheader/PageHeader';

import CKEditor from "@ckeditor/ckeditor5-react";
import ForumIcon from '../../../../../../assets/images/forum_icon.png'
import { getProjectAudiences } from '../../../../../../actions/audience_actions';
import { createActivity, getActivity, updateActivity } from '../../../../../../actions/activities_actions';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert'
import ArrowLeft from '../../../../../../assets/images/arrow_left.png';

import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import ImageModal from '../../../../../../assets/components/modal/ImageModal';
import AttachementIcon from '../../../../../../assets/images/attachement_icon.png'
const Forum = (props) => {

    const history = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const formRef = useRef();
    const location = useLocation();
    const currentPath = location.pathname;
    const [isLoading, setLoading] = useState(true)
    const [isEdit, setEditMode] = useState(false)
    const [isPreview, setPreviewMode] = useState(false)
    const [isPublish, setPublishMode] = useState(false)

    const [isShowQuestionFileModal, setQuestionFileModal] = useState(false)
    const [Audience, setAudience] = useState([])

    // These values are set to show in dropdown as per backend requirements
    const Priorities = ['low', 'medium', 'high']
    const replyVisibility = [
        { name: 'Show replies to all', value: 'Yes' },
        { name: 'Show replies after response is submitted', value: 'After Response' },
        { name: "don't show replies", value: "No" }
    ]

    const [data, setData] = useState({
        bannerImage: '',
        name: '',
        description: '',
        duration: '',
        days: '',
        priority: '',
        startDate: '',
        audience: '',
        displayResponses: '',
        query: '',
        additionalFiles: ''
    })

    const onPreview = () => {
        if (formRef.current) {
            setPreviewMode(true)
            formRef.current.handleSubmit()
        }
    }

    const getPageHeader = () => {
        switch (currentPath) {
            case '/researcher/project/create-forum/' + params.projectid:
                return <PageHeader
                    heading={"Create Forum Activities"} img={CreateIcon}
                    btnText="Preview"
                    isDisable={false}
                    btnClick={() => onPreview()}
                />
                break;

            case '/researcher/project/edit-forum/' + params.projectid:
                return <PageHeader
                    heading={"Edit Forum Activities"} img={ForumIcon}
                    btnText="Preview"
                    isDisable={false}
                    btnClick={() => onPreview()}
                />
                break;

            default:
                return <PageHeader
                    heading={"Create Forum Activities"} img={CreateIcon}
                    btnText="Preview"
                    isDisable={false}
                    btnClick={() => onPreview()}
                />
                break;
        }
    }

    const getAudienceDetails = () => {
        dispatch(getProjectAudiences(params.projectid))
            .then(response => {
                const audience = response.payload.content.map(audience => {
                    return {
                        id: audience._id,
                        name: audience.title
                    }
                })
                setAudience(audience)
            })
            .catch(err => {
                console.log(err)
            })
    }

    // getting data for edit
    useEffect(() => {
        const editUrl = `/researcher/project/edit-forum/${params.projectid}/${params.forumid}`
        const copyUrl = `/researcher/project/create-forum/${params.projectid}/${params.forumid}`
        if (currentPath === editUrl) {
            setEditMode(true)
            getForumDetails()
        } else if (currentPath === copyUrl) {
            getForumDetails()
        } else {
            setLoading(false)
        }
    }, [])

    const getForumDetails = () => {
        dispatch(getActivity(params.forumid))
            .then((response) => {
                setData({
                    ...response.payload.content,
                    audience: response.payload.content.audience ? response.payload.content.audience._id : ""
                })
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    useEffect(() => {
        getAudienceDetails()
    }, [])

    return (
        <>
            <div className="create_header">
                {getPageHeader()}
            </div>

            {/* Back Icon */}
            <span onClick={() => history("/researcher/project/activity/" + params.projectid)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12 form-group">
                        <label htmlFor="activityBanner">Activity Banner</label>
                        <div className="mx-3 mt-3 mb-4 _survey-steps_">


                            <LoadingOverlay tag="div" active={isLoading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: "#fff",
                                        opacity: 0.5,
                                        // position: 'fixed',
                                        // top: '20%'
                                    }),
                                }}
                                spinner={
                                    <Loader color="#6c757d" active type="ball-pulse" />
                                }>

                                <Formik
                                    initialValues={data}
                                    enableReinitialize={true}
                                    innerRef={formRef}
                                    onSubmit={(values, { setSubmitting }) => {

                                      
                                        setSubmitting(true);

                                        const formData = new FormData()

                                        formData.append('projectId', params.projectid)
                                        formData.append('name', values.name)
                                        values.bannerImage && formData.append('bannerImage', values.bannerImage)
                                        formData.append('description', values.description)
                                        formData.append('activityType', 'Forum')
                                        formData.append('priority', values.priority)
                                        isPreview ? formData.append('status', 'draft') : formData.append('status', 'scheduled')
                                        formData.append('startDate', values.startDate)
                                        formData.append('duration', values.duration)
                                        formData.append('days', values.days)
                                        formData.append('query', values.query)
                                        values.additionalFiles && formData.append('additionalFiles[0]', values.additionalFiles[0])
                                        values.audience && formData.append('audience', values.audience)
                                        formData.append('displayResponses', values.displayResponses)

                                        Swal({
                                            title: "Please wait!",
                                            text: "Saving data...",
                                            type: "info",
                                            buttons: false,
                                            closeModal: false,
                                            closeOnClickOutside: false
                                        });

                                        if (isEdit) {
                                            dispatch(updateActivity(params.forumid, formData, 7))
                                                .then(response => {
                                                    Swal.stopLoading();
                                                    Swal.close();
                                                    isPublish ?
                                                        Swal({
                                                            title: response.payload.title ? response.payload.title : "Created",
                                                            text: response.payload.message ? response.payload.message : 'Forum Created',
                                                            icon: 'success'
                                                        }).then(() => {
                                                            history('/researcher/project/activity/' + params.projectid)
                                                        })
                                                        :
                                                        history({
                                                            pathname: `/researcher/project/preview-forum/${params.projectid}/${response.payload.content._id}`,
                                                            state: { from: 'create' }
                                                        })
                                                })
                                                .catch(err => {
                                                    Swal({
                                                        title: err.response ? err.response.data.name : "Error",
                                                        text: err.response ? err.response.data.content : "Please try again",
                                                        icon: 'error'
                                                    }).then(() => {
                                                        Swal.stopLoading();
                                                        Swal.close();
                                                    })
                                                })
                                        } else {
                                            dispatch(createActivity(formData, 7))
                                                .then(response => {
                                                    Swal.stopLoading();
                                                    Swal.close();
                                                    isPublish ?
                                                        Swal({
                                                            title: response.payload.title ? response.payload.title : "Created",
                                                            text: response.payload.message ? response.payload.message : 'Forum Created',
                                                            icon: 'success'
                                                        }).then(() => {
                                                            history('/researcher/project/activity/' + params.projectid)
                                                        })
                                                        :
                                                        history({
                                                            pathname: `/researcher/project/preview-forum/${params.projectid}/${response.payload.content._id}`,
                                                            state: { from: 'create' }
                                                        })
                                                })
                                                .catch(err => {
                                                    Swal({
                                                        title: err.response ? err.response.data.name : "Error",
                                                        text: err.response ? err.response.data.content : "Please try again",
                                                        icon: 'error'
                                                    }).then(() => {
                                                        Swal.stopLoading();
                                                        Swal.close();
                                                    })
                                                })
                                        }


                                    }}


                                    const validationSchema={Yup.object().shape({
                                        name: Yup.string().required("* Activity Name is required"),
                                        description: Yup.string().required("* Description is required"),
                                        startDate: Yup.date().required("* Start Date is required"),
                                        duration: Yup.number().required("* Duration is required"),
                                        days: Yup.number().required("* Duration is required"),
                                        priority: Yup.string().required("* Priority is required"),
                                        // audience: Yup.string().required("* Audience is required"),
                                        displayResponses: Yup.string().required("* Reply Visibility is required"),
                                        query: Yup.string().required("* Question Text is required"),

                                    })}


                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue
                                        } = props;

                                        const startDate = new Date(values.startDate)
                                        values.startDate = startDate.getFullYear() + "-" + ("0" + (startDate.getMonth() + 1)).slice(-2) + "-" + ("0" + (startDate.getDate())).slice(-2)
                                        return (
                                            <form autoComplete="off" onSubmit={handleSubmit}>

                                                {/* Start of form */}

                                                <div className='row'>
                                                    <div className='col-12 mb-3'>
                                                        {/* Image Display & Dropzone */}
                                                        {values.bannerImage ? (

                                                            // Image Display
                                                            <div className="pt-3">
                                                                <div className="position-relative">
                                                                    <span className="questionImg_close" style={{ left: "97%", marginLeft: 0 }} onClick={() => setFieldValue('bannerImage', '')}>&times;</span>
                                                                    <img src={typeof values.bannerImage === 'string' ? values.bannerImage : URL.createObjectURL(values.bannerImage)} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px', maxHeight: 400, width: 'max-content' }} alt="img" />
                                                                </div>
                                                            </div>

                                                        ) : (

                                                            // Image Drop Zone 
                                                            < div className="dropzone-wrapper dropzone-wrapper-sm">
                                                                <Dropzone multiple={false} accept={".jpg, .jpeg, .png"} onDrop={file => setFieldValue('bannerImage', file[0])} value={values.bannerImage}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                                                                <p>
                                                                                    <span className={styles.upload_media_icon} style={{ width: 100, height: 100, backgroundSize: 55, verticalAlign: "middle" }}></span>Try dropping image here, or click to select image to upload.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>

                                                        )}
                                                    </div>

                                                    <div className="col-12 form-group">
                                                        <label htmlFor="activityName">Activity Name</label>
                                                        <Input type="text"
                                                            className={errors.name && touched.name ? styles.is_invalid : 'valid'}
                                                            name="name"
                                                            placeholder="Activity Name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                        />

                                                        {errors.name && touched.name && (
                                                            <div>
                                                                {errors.name}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label htmlFor="description">Description</label>
                                                        <Input type="text"
                                                            className={errors.description && touched.description ? styles.is_invalid : 'valid'}
                                                            name="description"
                                                            placeholder="Description"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.description}
                                                        />

                                                        {errors.description && touched.description && (
                                                            <div>
                                                                {errors.description}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="duration">Duration</label>
                                                        <Input type="number"
                                                            className={errors.days && touched.days ? styles.is_invalid : 'valid'}
                                                            name="days"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.days}
                                                        />

                                                        {errors.days && touched.days && (
                                                            <div>
                                                                {errors.days}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="duration">Duration per Day (Minutes)</label>
                                                        <Input type="number"
                                                            className={errors.duration && touched.duration ? styles.is_invalid : 'valid'}
                                                            name="duration"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.duration}
                                                        />

                                                        {errors.duration && touched.duration && (
                                                            <div>
                                                                {errors.duration}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="priority">Priority</label>
                                                        <Input type="select"
                                                            className={errors.priority && touched.priority ? styles.is_invalid : 'valid'}
                                                            name="priority"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.priority}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                                Priorities.map(priority => {
                                                                    return <option value={priority}>{priority}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.priority && touched.priority && (
                                                            <div>
                                                                {errors.priority}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="startDate">Start Date</label>
                                                        <Input type="date"
                                                            className={errors.startDate && touched.startDate ? styles.is_invalid : 'valid'}
                                                            name="startDate"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.startDate}
                                                        />

                                                        {errors.startDate && touched.startDate && (
                                                            <div>
                                                                {errors.startDate}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-12 form-group">
                                                        <label htmlFor="emailTemplate">Question Text</label>
                                                        <div className="text_editor">
                                                            {
                                                                params.forumid ? (values.query &&
                                                                    <CKEditor
                                                                        initData={values.query}
                                                                        // name="query"
                                                                        // onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        onChange={(e) => setFieldValue('query', e.editor.getData())}
                                                                        editor={ClassicEditor}
                                                                        config={{
                                                                            toolbar: [{
                                                                                name: 'document',
                                                                                items: ['Print']
                                                                            },
                                                                            {
                                                                                name: 'clipboard',
                                                                                items: ['Undo', 'Redo']
                                                                            },
                                                                            {
                                                                                name: 'styles',
                                                                                items: ['Format', 'Font', 'FontSize']
                                                                            },
                                                                            {
                                                                                name: 'colors',
                                                                                items: ['TextColor', 'BGColor']
                                                                            },
                                                                            {
                                                                                name: 'align',
                                                                                items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                                                                            },
                                                                                '/',
                                                                            {
                                                                                name: 'basicstyles',
                                                                                items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting']
                                                                            },
                                                                            {
                                                                                name: 'links',
                                                                                items: ['Link', 'Unlink']
                                                                            },
                                                                            {
                                                                                name: 'paragraph',
                                                                                items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                                                                            },
                                                                            {
                                                                                name: 'insert',
                                                                                items: ['Image', 'Table']
                                                                            },
                                                                            {
                                                                                name: 'tools',
                                                                                items: ['Maximize']
                                                                            },
                                                                            {
                                                                                name: 'editing',
                                                                                items: ['Scayt']
                                                                            }
                                                                            ],
                                                                            extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
                                                                            // Adding drag and drop image upload.
                                                                            extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
                                                                        }}

                                                                    />
                                                                ) :
                                                                    <CKEditor
                                                                        initData={values.query}
                                                                        // name="query"
                                                                        // onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        onChange={(e) => setFieldValue('query', e.editor.getData())}
                                                                        editor={ClassicEditor}
                                                                        config={{
                                                                            toolbar: [{
                                                                                name: 'document',
                                                                                items: ['Print']
                                                                            },
                                                                            {
                                                                                name: 'clipboard',
                                                                                items: ['Undo', 'Redo']
                                                                            },
                                                                            {
                                                                                name: 'styles',
                                                                                items: ['Format', 'Font', 'FontSize']
                                                                            },
                                                                            {
                                                                                name: 'colors',
                                                                                items: ['TextColor', 'BGColor']
                                                                            },
                                                                            {
                                                                                name: 'align',
                                                                                items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                                                                            },
                                                                                '/',
                                                                            {
                                                                                name: 'basicstyles',
                                                                                items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting']
                                                                            },
                                                                            {
                                                                                name: 'links',
                                                                                items: ['Link', 'Unlink']
                                                                            },
                                                                            {
                                                                                name: 'paragraph',
                                                                                items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                                                                            },
                                                                            {
                                                                                name: 'insert',
                                                                                items: ['Image', 'Table']
                                                                            },
                                                                            {
                                                                                name: 'tools',
                                                                                items: ['Maximize']
                                                                            },
                                                                            {
                                                                                name: 'editing',
                                                                                items: ['Scayt']
                                                                            }
                                                                            ],
                                                                            extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
                                                                            // Adding drag and drop image upload.
                                                                            extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
                                                                        }}

                                                                    />
                                                            }

                                                            {errors.query && touched.query && (
                                                                <div>
                                                                    {errors.query}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label>Add File</label>
                                                        <div className={`input-group mb-3 hide-file-text`}>
                                                            <input type="file" className="form-control" multiple={false} accept={".jpg, .jpeg, .png"}
                                                                style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                                                onChange={(event) => {
                                                                    setFieldValue("additionalFiles", event.target.files)
                                                                }}
                                                            />
                                                            {
                                                                values.additionalFiles && values.additionalFiles.length > 0 ?
                                                                    <>
                                                                        <img src={typeof values.additionalFiles[0] === 'string' ? values.additionalFiles[0] : URL.createObjectURL(values.additionalFiles[0])}
                                                                            alt="" width="40px" height="40px" onClick={() => setQuestionFileModal(!isShowQuestionFileModal)} />


                                                                        <ImageModal
                                                                            isModalOpen={isShowQuestionFileModal} setModal={() => setQuestionFileModal(!isShowQuestionFileModal)}
                                                                            image={typeof values.additionalFiles[0] === 'string' ? values.additionalFiles[0] : URL.createObjectURL(values.additionalFiles[0])}
                                                                            changeImage={(files) => setFieldValue('additionalFiles', files)}
                                                                            delImage={() => setFieldValue('additionalFiles', '')}
                                                                        />
                                                                    </>

                                                                    :
                                                                    <label className="btn btn-light" style={{ padding: ' 0.53rem 0.6rem' }}>
                                                                        <img src={AttachementIcon} alt="" width="20px" />
                                                                    </label>
                                                            }

                                                            {errors.additionalFiles && touched.additionalFiles && (
                                                                <div>
                                                                    {errors.additionalFiles}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="visibility">Visibility</label>
                                                        <Input type="select"
                                                            className={errors.audience && touched.audience ? styles.is_invalid : 'valid'}
                                                            name="audience"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.audience}
                                                        >
                                                            <option value="">All Participants</option>
                                                            {
                                                                Audience.map(audience => {
                                                                    return <option value={audience.id}>{audience.name}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.audience && touched.audience && (
                                                            <div>
                                                                {errors.audience}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="displayResponses">Reply Visibility</label>
                                                        <Input type="select"
                                                            className={errors.displayResponses && touched.displayResponses ? styles.is_invalid : 'valid'}
                                                            name="displayResponses"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.displayResponses}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                                replyVisibility.map(reply => {
                                                                    return <option value={reply.value}>{reply.name}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.displayResponses && touched.displayResponses && (
                                                            <div>
                                                                {errors.displayResponses}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-12 text-right">
                                                        <Button className={` ${styles.bg_blue} mr-3`} type="submit" onClick={() => setPublishMode(true)}>
                                                            {values.status && values.status === 'draft' ? 'Publish' : 'Save'}
                                                        </Button>
                                                        <Button className={` bg-light text-muted ml-3`} type="button"
                                                            onClick={() => history('/researcher/project/activity/' + params.projectid)}
                                                            style={{ border: '1px solid #6c757d ' }}>
                                                            Cancel
                                                        </Button>
                                                    </div>

                                                </div>
                                            </form>
                                        )
                                    }}

                                </Formik>

                            </LoadingOverlay>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}

export default Forum


