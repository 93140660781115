import React, { useState, useEffect } from 'react'
import PageHeader from '../../../assets/components/pageheader/PageHeader'
import EvaluationIcon from '../../../assets/images/evaluation_header_icon.png'
import Tabs from "react-responsive-tabs";
import classnames from 'classnames';
import Avatar from '../../../assets/images/profile_placeholder_men.png'
import About from './About';
import { editUserDetails, getUserDetails } from '../../../actions/user_action';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import styles from '../../../assets/css/rstyling.module.css'
const MyProfile = () => {

    const [activeTab, setActiveTab] = useState('1')
    const params = useParams()
    const dispatch = useDispatch()
    const history = useNavigate()

    const [profileImg, setProfileImg] = useState('')
    const [profileDetails, setProfileDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        userName: '',
        role: '',
        notification: 0,
        profileImage: ''
    })
    const userDetails = () => {
        dispatch(getUserDetails(params.userId))
            .then(response => {
                setProfileDetails(response.payload.content)
                // setLoading(false)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                }).then(() => history('/respondent'))
            })
    }
    useEffect(() => {
        userDetails()
    }, []);

    const onEditUserDetails = () => {
        const formData = new FormData();

        formData.append('firstName', profileDetails.firstName)
        formData.append('lastName', profileDetails.lastName)
        formData.append('userName', profileDetails.userName)
        if(profileDetails.profileImage !== undefined) {
            if (typeof profileDetails.profileImage === 'string') {
                formData.append('profileImage', profileDetails.profileImage)
            } else if(typeof profileDetails.profileImage === 'object') {
                formData.append('profileImage', profileDetails.profileImage[0])
            }
        }

        Swal({
            title: "Please wait!",
            text: "Editing data...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });
        dispatch(editUserDetails(profileDetails._id, formData))
            .then(response => {
                Swal.stopLoading();
                Swal.close();
                Swal({
                    title: response.payload.title ? response.payload.title : "Edited",
                    text: response.payload.message ? response.payload.message : 'User Details edited',
                    icon: 'success'
                }).then(() => {
                    userDetails()
                    setProfileImg()
                })
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                }).then(() => {
                    Swal.stopLoading();
                    Swal.close();
                })
            })

    }

    const tabsContent = [
        {
            name: "About",
            component: <About profile={profileDetails} setProfile={setProfileDetails} editProfile={onEditUserDetails} />,
        }
    ];

    const getSimpleTabs = () =>
        tabsContent.map(({ name, component }, index) => ({
            key: index,
            title: name,
            getContent: () => component,
        }));

    const [state, setState] = useState({
        activeTab: "1",
        showMore: true,
        transform: true,
        showInkBar: true,
        items: getSimpleTabs(),
        selectedTabKey: 0,
        transformWidth: 400,
    })

    useEffect(() => {
        setState({ ...state, items: getSimpleTabs() })
    }, [profileDetails])

    const onProfileUploadClick = () => {
        document.getElementById("profile_image_click").click();
    }

    const onSelectProfileImg = (input) => {

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                setProfileImg(e.target.result);

                setProfileDetails({ ...profileDetails, profileImage: input.files })
                setProfileImg(e.target.result)
            }

            reader.readAsDataURL(input.files[0]);

        }

    }
    return (
        <>
            <div className="project_header"><PageHeader
                heading="My Profile" img={EvaluationIcon}
                isSubheading={true}
                
            /></div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-sm-12 col-12 text-right">
                    <div className={styles.profile_uploader + " mt-3 mx-auto"} id="profile" style={{ backgroundImage: profileImg ?  `url(${profileImg})` : profileDetails.profileImage ? `url(${profileDetails.profileImage})` : `url(${Avatar}` }}>
                            <span className={styles.profile_upload_setting_icon} onClick={onProfileUploadClick}></span>
                            <input className="d-none" id="profile_image_click" type="file" accept=".png, .jpg, .jpeg" onChange={e => onSelectProfileImg(e.target)} />
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-12 col-12">
                        <div style={{ fontWeight: 600, fontSize: '1rem' }}>{profileDetails.userName}</div>
                        <div className={classnames({
                            'badge-success': profileDetails.role === 1,
                            'badge-warning': profileDetails.role === 2,
                            'badge-danger': profileDetails.role === 3,
                        }, 'mb-2 mr-2 badge badge-pill text-dark')}>
                            {profileDetails.role === 2 ? 'Researcher' :
                                profileDetails.role === 1 ? 'Admin' : 'Respondent'
                            }
                        </div>
                        <div className="mb-3 tab_blue">
                            <Tabs tabsWrapperClass="body-tabs" {...state} />
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default MyProfile
