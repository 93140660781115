import React, { useState, useEffect } from 'react';
import RewardStyles from '../../../assets/css/newStyling.module.css';
import junaid_jamshed from '../../../assets/images/junaid_jamshed.png'
import cross_stitch from '../../../assets/images/cross_stitch.png'
import breakout from '../../../assets/images/Breakout.png'
import alkaram from '../../../assets/images/alkaram.png'
import servis from '../../../assets/images/servis.png'
import nandos from '../../../assets/images/nandos.jpeg'
import StreetPizza from '../../../assets/images/14th_street_pizza.png'
import RedeemModal from '../../../assets/components/modal/RedeemModal'
import PFChangs from '../../../assets/images/pf_changs.jpg'
import Mocca from '../../../assets/images/mocca.png'
import Xiaomi from '../../../assets/images/xiaomi.png'
import Audionic from '../../../assets/images/audionic.png'
import Dany from '../../../assets/images/dany.png'
import SecureLS from 'secure-ls';
import { create_reward_request } from '../../../actions/rewards_actions'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert'
import { Button } from "reactstrap";
import pointsBadge from '../../../assets/images/medal.png'
import { BACK_SERVER_URL } from '../../../config/server';
import axios from 'axios'
import { set } from 'date-fns';

const RewardCards = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [totalPoints, setTotalPoints] = useState(0);
    const ls = new SecureLS()
    const dispatch = useDispatch()
    const user = ls.get('accessUser').user._id
    //const totalPoints = ls.get('accessUser').user.logs.pointsEarned // get this through an api call instead of local storage
    
    const handleButtonClick = (card) => {
        setSelectedCard(card);
        setIsModalOpen(true);
    };

useEffect(() =>{
    axios.get(`${BACK_SERVER_URL}api/users/me`)
    .then((response) => {
        //console.log('User:',response.data)
        setTotalPoints(response.data.content.logs.pointsEarned)
    })
},[])


    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleRedeem = (selectedOption) => {
        
        if (selectedCard && selectedOption) {
            //console.log('Redeeming:', selectedCard);
            let data = {
                status: 'Scheduled',
                redeemPoints: selectedOption.value,
                rewardCash: selectedOption.label,
                brand: selectedCard.title
            }
            //console.log('finalized data ->',data)


            dispatch(create_reward_request(data, user))
                .then((response) => {
                    toggleModal();
                    if (response.payload.message === 'Reward request created successfully') {


                        Swal({
                            title: "Request Submitted!",
                            text: "Your redemption request has been successfully generated. You will be contacted through email soon.",
                            icon: 'success'
                        });

                    }


                })
                .catch((error) => {
                    console.error(error)
                })
        }
    };

    const cards = [
        { id: 1, title: 'Junaid Jamshed Gift Card', price: 'PKR 1,000', points: 1000, image: `${junaid_jamshed}` },
        { id: 2, title: 'Cross Stich Gift Card', price: 'PKR 1,000', points: 1000, image: `${cross_stitch}` },
        { id: 3, title: 'Breakout Gift Card', price: 'PKR 1,000', points: 1000, image: `${breakout}` },
        { id: 4, title: 'Alkaram Gift Card', price: 'PKR 1,000', points: 1000, image: `${alkaram}` },
        { id: 5, title: 'Servis Gift Card', price: 'PKR 1,000', points: 1000, image: `${servis}` },
        { id: 6, title: 'Nandos Play Gift Card', price: 'PKR 1,000', points: 1000, image: `${nandos}` },
        { id: 7, title: '14th Street Pizza Gift Card', price: 'PKR 1,000', points: 1000, image: `${StreetPizza}` },
        { id: 8, title: 'PF Changs Gift Card', price: 'PKR 1,000', points: 1000, image: `${PFChangs}` },
        { id: 9, title: 'Mocca Gift Card', price: 'PKR 1,000', points: 1000, image: `${Mocca}` },
        { id: 10, title: 'Xiamoi Gift Card', price: 'PKR 1,000', points: 1000, image: `${Xiaomi}` },
        { id: 11, title: 'Audionic Gift Card', price: 'PKR 1,000', points: 1000, image: `${Audionic}` },
        { id: 12, title: 'Dany Gift Card', price: 'PKR 1,000', points: 1000, image: `${Dany}` },
    ];

    return (

        <div className={RewardStyles.gift_card_container}>
            {cards.map((card) => (
                <div key={card.id} className={RewardStyles.gift_card}>
                    <img src={`${card.image}`} alt={card.title} className={RewardStyles.gift_card_image} />
                    <div className='p-3'>
                        <h3 className={RewardStyles.gift_card_title}>{card.title}</h3>
                        {/* <p className={RewardStyles.gift_card_price}>{card.price}</p> */}
                        <div className='d-flex justify-content-center align-items-center'>
                            <p className={RewardStyles.gift_card_points}>Minimum {card.points}</p>  <img src={pointsBadge} alt='image' className={`${RewardStyles.reward_badge}`}></img>
                        </div>
                        <Button className={"btn-hover-shine mt-4"} style={{ cursor: totalPoints < 1000 ? 'not-allowed' : 'pointer', backgroundColor: '#0069AB', color: 'white', border: 'none' }} disabled={totalPoints < 1000} onClick={() => handleButtonClick(card)}>Redeem Reward</Button>

                    </div>
                </div>
            ))}

            {isModalOpen && (
                <RedeemModal
                    isModalOpen={isModalOpen}
                    setModal={toggleModal}
                    brand={selectedCard.title}
                    onClick={handleRedeem}
                    totalPoints={totalPoints}
                    variations={true}
                />
            )}
        </div>


    );
};

export default RewardCards;
