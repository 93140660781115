import React, { useState, useEffect } from 'react'
import Projecticon from '../../../assets/images/project_header_icon.png'
import Eyeicon from '../../../assets/images/eye_icon.png'
import Deleteicon from '../../../assets/images/delete_icon.png'
import Editicon from '../../../assets/images/edit_icon.png'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png'
import TickIcon from '../../../assets/images/tick_icon.png'
import { Card, CardBody } from "reactstrap";
import PageHeader from '../../../assets/components/pageheader/PageHeader'
import { useNavigate, useParams } from 'react-router'
import ResizeDetector from "react-resize-detector";
import Listing from '../../../assets/components/listing/Listing'
import Group from '../../../assets/images/user_group_icon.png'
import AlertModal from '../../../assets/components/modal/AlertModal'
import CopyIcon from '../../../assets/images/copy_icon.png'
import ReportIcon from '../../../assets/images/report_icon.png'
import Loader from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects, delProject, updateProject, changeProjectStatus } from '../../../actions/project_actions'
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'

const ProjectList = () => {

    const [delId, setDelID] = useState()
    const [completeStatusId, setCompleteStatusId] = useState()
    const history = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const projectState = useSelector(state => state.Project)
    const [isLoading, setLoading] = useState(true)
    const [projectColumn, setProjectColumn] = useState([
        { Name: 'Project Name', Type: 'string', key: 'title' },
        // { Name: "Project Code", Type: 'string', key: 'code' },
        // { Name: "Start Date", Type: 'projectstartdate', key: 'startDate' },
        // { Name: "End Date", Type: 'projectenddate', key: 'endDate' },
        // { Name: "Type", Type: 'string', key: 'projectType' },
        // { Name: "Response", Type: 'string', key: 'response' },
        // { Name: "Status", Type: 'string', key: 'status' },
        { Name: "Dates", Type: 'projectdates', key: 'dates' },
        { Name: "Research Company", Type: 'string', key: 'company' },
        {
            Name: "Action", Type: 'action', width: 350, options: [
                {
                    icon: Eyeicon,
                    onClick: () => console.log("I'm clicked"),
                    type: 'dropdown',
                    droplist: [
                        // { Name: 'Introduction', url: "/admin/project/intro" },
                        // { Name: 'Details', url: "/admin/project/detail" },
                        // { Name: 'Attachments', url: "/admin/project/attachement" },
                        // { Name: 'Screener', url: "/admin/project/evaluation" },
                        { Name: 'View Details', viewType:'project-details', url: "/admin/project/activity" },
                        // { Name: 'Grouping', url: "/admin/project/grouping" },
                    ]
                },
                // {
                //     icon: Group,
                //     // onClick: () => "",
                //     type: 'dropdown',
                //     droplist: [
                //         { Name: 'Participants', url: '/admin/project/participants' },
                //         { Name: 'Focus Groups', url: '/admin/project/groups' },
                //     ]
                // },
                {
                    icon: Group,
                    url: '/admin/project/participants',
                    type: 'project-participants',
                },
                // {
                //     icon: ReportIcon,
                //     url: "/admin/project/report",
                //     type: 'button'
                // },
                {
                    icon: Editicon,
                    url: "/admin/edit-project",
                    type: 'button'
                },
                // {
                //     icon: CopyIcon,
                //     url: "/admin/add-project",
                //     type: 'button'
                // },
                // {
                //     icon: TickIcon,
                //     setID: setCompleteStatusId,
                //     type: 'delete'
                // },
                {
                    icon: Deleteicon,
                    setID: setDelID,
                    type: 'delete'
                },
            ]
        }

    ]);

    const [projectData, setProjectData] = useState([])

    const [searchString, setSearchString] = useState('')
    const [searchedProjectResult, setSearchedProjectResult] = useState([])

    // Listing Search
    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredProject = projectData.filter(row => {
            return (
                search.test(row.title) ||
                search.test(row.type) ||
                search.test(row.company) ||
                search.test(row.status) ||
                search.test(row.code)
            );
        });
        setSearchedProjectResult(filteredProject);
    }, [searchString]);

    const [isModalOpen, setModal] = useState(false)

    const showModal = () => {
       
        setModal(!isModalOpen)
    }

    // Show / hide delete modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    // for changing project status to complete
    useEffect(() => {
        if (completeStatusId) {
            dispatch(changeProjectStatus(completeStatusId, { status: 'complete' }))
                .then(response => {
                    setCompleteStatusId('')
                    Swal({
                        title: "Edited",
                        text: 'Project Status changed to complete',
                        icon: 'success'
                    })
                })
                .catch(err => {
                    setCompleteStatusId('')
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        }
    }, [completeStatusId])

    const getProjectListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(getAllProjects(currentPage, dataLimit))
            .then(response => {
                setProjectData(response.payload.content.projects)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }

    const deleteProject = () => {
        dispatch(delProject(delId))
            .then(response => {
                setDelID()
                getProjectListing(projectState.currentPage, projectState.dataLimit)
                Swal({
                    title: "Deleted",
                    text: "Project Deleted",
                    icon: 'info'
                });
            })
            .catch(err => {
                console.log(err);
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again!",
                    icon: 'error'
                });
            })
    }

    useEffect(() => {
        getProjectListing()
    }, [])

    return (

        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>


                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                        {/* Delete Modal */}
                        <AlertModal isModalOpen={isModalOpen} setModal={showModal}
                            onClick={deleteProject}
                        />

                        <>
                            <div className="project_header">
                                <PageHeader
                                    heading="Research Projects" img={Projecticon}
                                    btnText={width <= 350 ? "Create" : "Create Project"}
                                    btnClick={() => history("/admin/add-project", { editMode: false })}
                                />
                            </div>
                            <Card className="main-card mb-3">
                                <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                    <div className="text-white menu-header-title text-capitalize">Project List</div>

                                    <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                        <ul className="navbar-nav mr-auto">
                                        </ul>
                                        <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.5rem' }}>
                                            <div className="input-group input-group-sm w-75">
                                                <div className="input-group-prepend border-0">
                                                    <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                </div>
                                                <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search"
                                                    onChange={(e) => setSearchString(e.target.value)} />
                                            </div>

                                        </div>
                                    </div>
                                </nav>

                                {/* Loader */}
                                <LoadingOverlay tag="div" active={isLoading}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: "#fff",
                                            opacity: 0.5,
                                        }),
                                    }}
                                    spinner={
                                        <Loader color="#6c757d" active type="ball-pulse" />
                                    }>
                                    <CardBody className="p-0">

                                        {/* Listing */}
                                        <div className="table_border">
                                            <Listing columnName={projectColumn}
                                                data={(searchString.length > 0 || searchedProjectResult.length > 0) ? searchedProjectResult : projectData && projectData.length > 0? projectData:<p></p>}
                                                currentPage={projectState.currentPage ? projectState.currentPage : 1}
                                                totalPages={projectState.totalPages ? projectState.totalPages : 1}
                                                getListingData={(currentPage, dataLimit) => getProjectListing(currentPage, dataLimit)}
                                                startLoading={() => setLoading(true)}
                                            />
                                        </div>

                                    </CardBody>
                                </LoadingOverlay>

                            </Card>
                        </>

                    </TransitionGroup>
                </>
            )}
        />
       
    )
}

export default ProjectList
