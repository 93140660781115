import React, { useState, useEffect } from 'react'
import avatar from '../../../../assets/images/profile_placeholder.png'
import bg4 from "../../../../assets/utils/images/dropdown-header/city2.jpg";
import dots from '../../../../assets/images/dots_icon.png'
import {
    Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ButtonGroup
} from "reactstrap";
import ArrowLeft from '../../../../assets/images/arrow_left.png'
import { useNavigate } from 'react-router';
import styles from '../../../../assets/css/rstyling.module.css'
import classNames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import { getProjectParticipantPaginated, removeParticipant } from '../../../../actions/participant_actions';
import Swal from 'sweetalert'
import { useParams } from 'react-router-dom'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const Participants = () => {

    const history = useNavigate()
    const [isModalOpen, setModal] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()
    const participantState = useSelector(state => state.ProjectParticipants)
    const [delId, setDelId] = useState('')
    const [participants, setParticipants] = useState([])
    const [isLoading, setLoading] = useState(true)
    // Show Image inModal
    const showModal = (id) => {
        setDelId(id)
        setModal(!isModalOpen)
    }

    const getParticipantsList = (page = 1, limit = 10) => {
        dispatch(getProjectParticipantPaginated(params.projectid, { page: page, limit: limit }))
            .then(response => {
                setLoading(false)
                setParticipants(response.payload.content.participants)
            })
            .catch(err => {
                // if (err.response.data.name !== 'Not Found') {
                    
                    Swal({
                        title: err.response ? err.response.data.name : "Not Found",
                        text: err.response ? err.response.data.content : "Participants not available",
                        icon: 'info'
                    })
              //  }
               

                
                setLoading(false)
            })
    }

    const previous = () => {
        getParticipantsList(participantState.currentPage - 1, 10)
    }

    const next = () => {
        getParticipantsList(participantState.currentPage + 1, 10)
    }

    useEffect(() => {
        getParticipantsList()
    }, [])


    const dropParticipant = () => {
        dispatch(removeParticipant(params.projectid, { userId: delId }))
            .then((response) => {
              
                Swal({
                    title: response.payload.title ? response.payload.title : "Removed",
                    text: response.payload.content ? response.payload.content : "Participant removed from the project",
                    icon: 'success'
                }).then(() => {
                    getParticipantsList()
                    setModal(!isModalOpen)
                })
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }

    return (
        <LoadingOverlay tag="div" active={isLoading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                    // position: 'fixed',
                    // top: '20%'
                }),
            }}
            spinner={
                <Loader color="#6c757d" active type="ball-pulse" />
            }>

            <>
                <div className="container-fluid">

                    {/* Back Icon */}
                    <span onClick={() => history("/admin/project")} style={{ cursor: 'pointer' }}>
                        <img src={ArrowLeft} alt="" width="8px" />
                        <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                    </span>

                    <div className="row member_card">
                        {/* Modal */}
                        <ModalContent
                            setModal={showModal}
                            isModalOpen={isModalOpen}
                            onConfirm={() => dropParticipant()}
                        />

                        {/* Card */}
                        {
                            (participants.length > 0 ?
                                participants.map((data, index) => {
                                    return <div key={index} className="col-md-4 col-sm-12 col-12 my-3">
                                        <div className="dropdown-menu-header" >
                                            <div className="dropdown-menu-header-inner participant_card" style={{ borderRadius: ' 0.5rem 0.5rem 0 0', backgroundColor: '#020202' }}>
                                                <div className="menu-header-image"
                                                    style={{
                                                        backgroundImage: "url(" + bg4 + ")",
                                                    }} />
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3">
                                                            <div className="widget-content-left">
                                                                <img width={32} height={32} style={{ borderRadius: '100%' }} src={data.profileImage && data.profileImage !== 'undefined' ? data.profileImage : avatar} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-left flex2" style={{ zIndex: '15' }} >
                                                            <div className={`widget-heading text-white ${styles.cursor}`}>{data.userName}</div>
                                                            <div className="widget-subheading text-white" style={{ fontSize: '0.7rem' }}>
                                                                <div className={classNames({
                                                                    'badge-success': data.role === 1,
                                                                    'badge-warning': data.role === 2,
                                                                    'badge-danger': data.role === 3,
                                                                }, 'mb-2 mr-2 badge badge-pill')}>

                                                                    {
                                                                        data.role === 2 ? 'Researcher' : 'Respondent'
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right d-flex custom_drop font-weight-bold">
                                                            <div className={data.logs && data.logs.isActive ? `text-success widget-heading mt-2` : `text-white widget-heading mt-2`} style={{ fontSize: '0.7rem' }}>
                                                                {data.logs && data.logs.isActive ? 'Active' : 'Away'}
                                                            </div>
                                                            <UncontrolledButtonDropdown className="mb-2 mr-2 participant_btn" style={{ zIndex: '15' }} >
                                                                <DropdownToggle>
                                                                    <img className="ml-2 mr-2" src={dots} height="12px" alt="" />
                                                                </DropdownToggle>
                                                                <DropdownMenu style={{ marginLeft: '-13rem' }}>
                                                                    <DropdownItem onClick={() => showModal(data._id)}>Drop</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid-menu border-left border-right border-bottom" style={{ borderRadius: ' 0 0 0.5rem 0.5rem' }}>
                                            <div className="no-gutters row">
                                                <div className="col-4 text-center border-bottom border-right py-2">
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Joined Study</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSlabelace: 'pre-wrap' }} >{data.logs && new Date(data.logs.currentProjectJoined).toLocaleDateString()}</label>
                                                </div>
                                                <div className="col-4 text-center py-2 border-right border-bottom">
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Study Visits</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{data.logs && data.logs.totalVisits}</label>
                                                </div>
                                                <div className="col-4 text-center py-2 border-bottom">
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Last Visit</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{(data.logs && data.logs.latestVisit) ? new Date(data.logs.latestVisit).toLocaleDateString() : '-'}</label>
                                                </div>
                                                <div className="col-6 text-center py-2 border-right" >
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Last Email Opened</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{(data.logs && data.logs.lastEmailOpened) ? new Date(data.logs.lastEmailOpened).toLocaleDateString() : '-'}</label>
                                                </div>
                                                <div className="col-6 py-2 text-center">
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Activities Completed</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{(data.logs && data.logs.activitiesDone) ? data.logs.activitiesDone : 0}</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                })
                                :
                                <div className="col-12 text-center">No Participants found</div>)
                        }
                    </div>

                    {
                        participants.length > 0 &&
                        <div className='col-12 text-center mt-5'>
                            <ButtonGroup size="sm">
                                <Button className={"px-2 text-light"} style={{ backgroundColor: '#006FA7', borderColor: '#006FA7' }} onClick={() => previous()} disabled={participantState.currentPage === 1 ? true : false}>
                                    Previous
                                </Button>
                                <Button className={"px-3 text-light"} style={{ backgroundColor: '#006FA7', borderColor: '#006FA7' }} onClick={() => next()} disabled={(participantState.totalPages === participantState.currentPage) ? true : false}>
                                    Next
                                </Button>
                            </ButtonGroup>
                        </div>

                    }

                </div>
            </>
        </LoadingOverlay >
    )
}

export default Participants


export const ModalContent = (props) => {

    const toggle = () => {
        props.setModal()
    }

    return (

        <Modal isOpen={props.isModalOpen} toggle={toggle} id={`ImageModal`} className="modal-dialog-centered shadow-none" >
            <ModalHeader className="bg-white" toggle={toggle}>Drop Participant</ModalHeader>
            <ModalBody className="text-center">
                <p> Are you sure want to drop participant? </p>
                <Button className={` ${styles.bg_blue} mr-3`} onClick={() => props.onConfirm()}>
                    Yes
                </Button>
                <Button className={` bg-light text-muted ml-3`}
                    style={{ border: '1px solid #6c757d ' }} onClick={() => toggle()}>
                    No
                </Button>
            </ModalBody>
        </Modal>

    )
}