import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import axios from 'axios'
import Swal from'sweetalert'
import { BACK_SERVER_URL } from '../../../../config/server'
import { TokenContext } from '../../../../ResearcherToken';


const ProjectInvitation = () => {

    let { search } = useLocation();
    const history = useNavigate()
    const query = new URLSearchParams(window.decodeURIComponent(search));
   
    const token= query.get('token')
    const user = query.get('user')
   
   // const {tokenn, setToken} = useContext(TokenContext)

    useEffect(() => {
        axios.get(`${BACK_SERVER_URL}api/participants/acceptProjectInvite?token=${token}`)
        .then(response => {
          
            if(response.data.message== 'User is not in system'){
                
                
                if(user == 'researcher'){
               
                history(`/invite_researcher/${token}`)
                 } 
                else if (user == 'respondent') {
                    
                    history(`/invite_signup/${token}`)
                }
                
                }
            else{
            Swal({
                title: response.message ? response.message : "Added",
                text: response.content ? response.content : " You are added in a project. ",
                icon: 'success'
            })
            history('/login');
        }})
        .catch( err => {
            Swal({
                title: err.response ? err.response.data.name : "Error",
                text: err.response ? err.response.data.content : "Something went wrong",
                icon: 'error'
            })
        })
    }, [])

    return (
        <p> 
        </p>
    )
}

export default ProjectInvitation
