import React from 'react';
import { useState, useEffect } from 'react';
import Projects from '../Dashboard/Projects';
import ConnectPoints from '../Dashboard/ConnectPoints';
import WelcomeUser from '../Dashboard/WelcomeUser';
import LatestTasks from '../Dashboard/LatestTasks';
import ContinueProject from '../Dashboard/Continueproject';
import { participantsProjectsDashboard } from '../../../actions/project_actions';
import { useDispatch } from 'react-redux';
import noTasksImage from '../../../assets/images/no-project-img.png'
import { respondentDashboardDetails } from '../../../actions/dashboard_activities'
import { TransitionGroup } from 'react-transition-group';
import ResizeDetector from "react-resize-detector";
import LoadingOverlay from 'react-loading-overlay-ts';
import Loader from "react-loaders";
import SecureLS from 'secure-ls';
import Styles from '../../../assets/css/newStyling.module.css'
import Swal from 'sweetalert'


const Respondent = () => {
  const [projectsCheck, setProjectsCheck] = useState('')
  const [checkContinueProject, setCheckContinueProject] = useState(0)
  const dispatch = useDispatch()
  const [todayTasks, setTodayTasks] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [profileCheck, set_profileCheck] = useState('')
  const ls = new SecureLS()
  const userId = ls.get('accessUser').user._id




  useEffect(() => {


    dispatch(participantsProjectsDashboard())
      .then(response => {

        setProjectsCheck(response.payload.message)
        setCheckContinueProject(response.payload.content)



      })
      .catch(err => {
        console.log(err);

      })

  }, [])


  useEffect(() => {
    let timeout;

    dispatch(respondentDashboardDetails(userId))
      .then(response => {
// there might be issue getting data from our server. please reload the page. show this msg if data dont come after 20 sec
        set_profileCheck(response.payload.content.profileCompletion)
        setTodayTasks(response.payload.content.todaysTasks)
        setLoading(false)
        clearTimeout(timeout);
      })
      .catch(err =>
        console.log(err)
      )

      timeout = setTimeout(() => {
        if (Object.keys(todayTasks).length === 0 || profileCheck === "") {
          Swal({
           icon:'info',
            text: "We are having trouble connecting to the server or fetching your data. Please check your internet connection and try reloading the page!",
            
        });
        }
      }, 20000);

  }, [])


  

  const profileLink = () => {

    if (window.location.hostname === 'localhost') {
      window.open('http://localhost:3006/respondent/profile', '_blank')
    }
    else {
      window.open('https://app.consumerconnect.net/respondent/profile', '_blank')
    }
  }
  return (

    <TransitionGroup >

      <ResizeDetector
        handleWidth
        render={({ width }) => (

          <LoadingOverlay tag="div" active={isLoading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#fff",
                opacity: 0.5,
                position: 'fixed',
                top: '20%'
              }),
            }}
            spinner={
              <Loader color="#6c757d" active type="ball-pulse" />
            }>

            <React.Fragment>
















              <div >
                <div className='container'>
                  <WelcomeUser />

                </div>
                {projectsCheck === 'User Only New Projects not Found' && <div className='container d-none'>
                  <ContinueProject />
                </div>
                }


                <div className="row">

                  <div className="col-md-7 col-12">
                    {projectsCheck === 'Only In-Progress Projects' || projectsCheck === 'Both In-Progress and New Projects' ? <div className='container'>
                      <ContinueProject />
                    </div> : ''
                    }
                    <div className="content">

                      {projectsCheck === 'Only New Projects' || projectsCheck === 'Both In-Progress and New Projects' ? <Projects /> : ''}

                      {(projectsCheck === 'Both In-Progress and New Projects' || projectsCheck === 'Only New Projects' || projectsCheck === 'Only In-Progress Projects') && (todayTasks && todayTasks.length > 0) ? <LatestTasks /> : ''}
                      {/* {todayTasks.length > 0 ||  projectsCheck === 'Neither In-Progress nor New Projects' ? <LatestTasks/>:''}  */}



                      {projectsCheck === 'Neither In-Progress nor New Projects' ? todayTasks && todayTasks.length > 0 ? <LatestTasks /> : <div className='mt-5 container'>
                        <img style={{ maxWidth: '100%' }} src={noTasksImage}></img>
                        <p className='container' style={{ color: '#8E8E8E', fontFamily: 'Poppins', fontSize: '20px' }}>No matching projects now, but stay tuned! We're crafting exciting opportunities just for you.</p>
                      </div> : ''}




                    </div>
                  </div>

                  <div className="col-md-5 col-12">

                    <div className="content container">

                      <ConnectPoints />





                    </div>
                  </div>
                </div>
              </div>























            </React.Fragment>


          </LoadingOverlay>

        )}

      />
    </TransitionGroup>


    //         <div >
    //           <div className='container'>
    //           <WelcomeUser/>
    //           {/* <div style={{ width: '100%', backgroundColor: 'red', textAlign: 'center', padding: '5px 0' }}>
    //   <p style={{ color: 'white', display: 'inline', fontWeight:'bold' }}>
    //     Complete your profile to unlock 100 connect points.{' '}
    //     <span 
    //       style={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}
    //       onClick={() => { profileLink() }}
    //     >
    //       Complete Now
    //     </span>
    //   </p>
    // </div> */}

    //           {/* {profileCheck && profileCheck <100 &&
    //           <div style={{display:'flex', justifyContent:`${window.innerWidth > 768 ? 'right':'center'}`, alignItems:`${window.innerWidth > 768 ? 'right':'center'}`}}>
    //          <button className={`${Styles.pulse_button}`} onClick={profileLink}>Complete profile</button>
    //          </div>
    //           } */}
    //           </div>
    //           {projectsCheck === 'User Only New Projects not Found'  &&  <div className='container d-none'>
    //           <ContinueProject/>
    //           </div>
    //           }


    //         <div className="row">

    //           <div className="col-md-7 col-12">
    //           {projectsCheck === 'Only In-Progress Projects' || projectsCheck ==='Both In-Progress and New Projects' ?  <div className='container'>
    //           <ContinueProject/>
    //           </div> :''
    //           }
    //             <div className="content">

    //               {projectsCheck === 'Only New Projects' || projectsCheck ==='Both In-Progress and New Projects' ? <Projects/>:''}

    // { (projectsCheck ==='Both In-Progress and New Projects' || projectsCheck==='Only New Projects' || projectsCheck === 'Only In-Progress Projects') && (todayTasks.length > 0) ? <LatestTasks/>:''}
    //  {/* {todayTasks.length > 0 ||  projectsCheck === 'Neither In-Progress nor New Projects' ? <LatestTasks/>:''}  */}



    // {projectsCheck === 'Neither In-Progress nor New Projects' ?  todayTasks.length >0 ?<LatestTasks/>: <div className='mt-5 container'>
    //               <img style={{maxWidth:'100%'}} src={noTasksImage}></img>
    //               <p className='container' style={{color: '#8E8E8E',fontFamily: 'Poppins', fontSize:'20px' }}>No matching projects now, but stay tuned! We're crafting exciting opportunities just for you.</p>
    //               </div>:''}




    //             </div>
    //           </div>

    //           <div className="col-md-5 col-12">

    //             <div className="content container">

    //         <ConnectPoints/>





    //             </div>
    //           </div>
    //         </div>
    //       </div>


  )

}

export default Respondent;