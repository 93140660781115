import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { TransitionGroup } from 'react-transition-group';
import PageHeader from '../../../../../assets/components/pageheader/PageHeader'
import CreateIcon from '../../../../../assets/images/create_icon.png'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate, useParams, useLocation } from 'react-router';
import styles from '../../../../../assets/css/rstyling.module.css'
import { Typeahead } from 'react-bootstrap-typeahead';
import Swal from 'sweetalert'
import { useDispatch, useSelector } from 'react-redux';
import { createEmailTemplate, editEmailTemplateDetails, getEmailTemplateDetails } from '../../../../../actions/email_template_actions';
import SecureLS from 'secure-ls';
import { getSpecificOption } from '../../../../../actions/settings_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import ArrowLeft from '../../../../../assets/images/arrow_left.png'

const CreateTemplate = () => {

    const params = useParams()
    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const dispatch = useDispatch()
    const ls = new SecureLS()

    const emailTemplateState = useSelector(state => state.EmailTemplates)

    const [isLoading, setLoading] = useState(true)
    const [selectedVariable, setVariable] = useState([])
    const [isUserVar, setUserVar] = useState([])
    const [variableList, setVariableList] = useState([])

    const [emailTemplate, setEmailTemplate] = useState({
        title: '',
        subject: '',
        dynamicVariables: [],
        emailContent: '',
        variableValue: '',
        author: ls.get('accessUser') && ls.get('accessUser').user ? ls.get('accessUser').user._id : ''
    })
    // for saving ckeditor data
    const [emailTemplateContent, setEmailTemplateContent] = useState()
    const onEditorStateChange = (e) => {
        setEmailTemplateContent(e.editor.getData())
    }

    const [err, setError] = useState({
        title: '',
        subject: '',
        emailContent: ''
    })

    const removeErr = () => {
        setError({
            title: '',
            subject: '',
            emailContent: ''
        })
    }


    const checkValidation = (emailTemplateData) => {
        removeErr()
        let isErr = false
            if (emailTemplateData.subject === '') {
                setError((prevState) => ({ ...prevState, subject: 'Subject is required.' }))
                isErr = true
            }
            if (emailTemplateData.emailContent === '' || emailTemplateData.emailContent === undefined) {
                setError((prevState) => ({ ...prevState, emailContent: 'Content is required.' }))
                isErr = true
            }
            if (emailTemplateData.title === '') {
                setError((prevState) => ({ ...prevState, title: 'Title to is required.' }))
                isErr = true
            }

       
        if (isErr === false){
            return true
        }
    }

    const onCreateEmailTemplate = () => {
        let emailContent = emailTemplateContent
        for (let i = 0; i < selectedVariable.length; i++) {
            emailContent = emailContent.replaceAll(selectedVariable[i], `{{${selectedVariable[i]}}}`)
        }

        const dynamicVar = selectedVariable.map(variable => {
            // variable.name === 'username' ? setUserVar(true) : setUserVar(false)
            return variable._id
        })

        const emailTemplateData = {
            title: emailTemplate.title,
            subject: emailTemplate.subject,
            dynamicVariables: dynamicVar.filter(v => v),
            variableValue: emailTemplate.variableValue,
            emailContent: emailContent,
            author: ls.get('accessUser') && ls.get('accessUser').user ? ls.get('accessUser').user._id : ''
        }

        if (checkValidation(emailTemplateData)) {
            Swal({
                title: "Please wait!",
                text: "Saving data...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });
            dispatch(createEmailTemplate(emailTemplateData))
                .then(response => {
                    Swal.stopLoading()
                    Swal.close()
                  
                    Swal({
                        title: "Created",
                        text: response.content ? response.content : "Created",
                        icon: 'success'
                    });
                    history('/admin/templates/email')

                })
                .catch(err => {
                    Swal.stopLoading()
                    Swal.close()
                    Swal({
                        title: err ? err : "Error",
                        text: "Please try again!",
                        icon: 'error'
                    });
                })
        }
    }

    const editEmailTemplate = () => {
        if (params.templateid) {
            let emailContent = emailTemplateContent

            for (let i = 0; i < selectedVariable.length; i++) {
                emailContent = emailContent.replaceAll(selectedVariable[i], `{{${selectedVariable[i]}}}`)
            }

            const dynamicVar = selectedVariable.map(variable => {
                // variable.name === 'username' ? setUserVar(true) : setUserVar(false)
                return variable._id
            })

            const emailTemplateData = {
                title: emailTemplate.title,
                subject: emailTemplate.subject,
                dynamicVariables: dynamicVar.filter(v => v),
                variableValue: emailTemplate.variableValue,
                emailContent: emailContent,
                author: ls.get('accessUser') && ls.get('accessUser').user ? ls.get('accessUser').user._id : ''
            }

            if (checkValidation(emailTemplateData)) {
                Swal({
                    title: "Please wait!",
                    text: "Saving data...",
                    type: "info",
                    buttons: false,
                    closeModal: false,
                    closeOnClickOutside: false
                });
                dispatch(editEmailTemplateDetails(params.templateid, emailTemplateData))
                    .then(response => {
                        Swal.stopLoading()
                        Swal.close()
                        Swal({
                            title: response.message ? response.message : "Edited",
                            text: response.content ? response.content : "Template edited",
                            icon: 'success'
                        });
                        history('/admin/templates/email')

                    })
                    .catch(err => {
                        Swal.stopLoading()
                        Swal.close()
                        Swal({
                            title: err.content ? err.content : "Error",
                            text: "Please try again!",
                            icon: 'error'
                        });
                    })
            }

        }
    }

    const onSave = () => {
        const editUrl = '/admin/edit-email-template/' + params.templateid

        if (params.templateid) {
            if (currentPath === editUrl)
                editEmailTemplate();
            else
                onCreateEmailTemplate()

        } else {
            onCreateEmailTemplate()
        }
    }

    // For geting details of template in case of edit or copy template
    useEffect(() => {
        if (params.templateid) {
            // getting template details
            dispatch(getEmailTemplateDetails(params.templateid))
                .then(response => {
                    setEmailTemplate({
                        title: response.payload.content.title,
                        subject: response.payload.content.subject,
                        emailContent: response.payload.content.emailContent,
                        author: response.payload.content.author,
                        variableValue: response.payload.content.variableValue
                    })
                    const selectedVariables = response.payload.content.dynamicVariables.map(variable => variable._id)
                    setVariable([...response.payload.content.dynamicVariables])
                    setEmailTemplateContent(response.payload.content.emailContent)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                    Swal({
                        title: err ? err : "Error",
                        text: "Something went wrong",
                        icon: 'error',
                    });
                    history('/admin/templates/email')
                })
        } else {
            setLoading(false)
        }

    }, [])

    const getPageHeader = () => {
        switch (currentPath) {
            case '/admin/edit-email-template/' + params.templateid:
                return <PageHeader heading="Edit Template" img={CreateIcon} />

            default:
                return <PageHeader heading="Create Template" img={CreateIcon} />
        }
    }

    const getSystemOptions = () => {
        dispatch(getSpecificOption({ options: ["Template Variables"] }))
            .then(response => {
                // setSystemVariables(response.payload.content.templateVariables)
                // const variables = response.payload.content.templateVariables);
                setVariableList(response.payload.content.templateVariables)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getSystemOptions()
    }, []);


    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>


            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '20%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                <div className="create_header">
                    {getPageHeader()}
                </div>

                {/* Back Icon */}
                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>

                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-12">
                            <FormGroup>
                                <Label for="emailTitle">Template Title</Label>
                                <Input type="text" name="emailTitle" id="emailTitle" value={emailTemplate.title} placeholder='Email Title'
                                    className={err.title ? styles.is_invalid : 'valid'}
                                    onChange={(e) => setEmailTemplate({ ...emailTemplate, title: e.target.value })} />
                                <small className="text-danger">
                                    {err.title}
                                </small>
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormGroup>
                                <Label for="subject">Subject</Label>
                                <Input type="text" name="subject" id="subject" value={emailTemplate.subject} placeholder='Subject'
                                    className={err.subject ? styles.is_invalid : 'valid'}
                                    onChange={(e) => setEmailTemplate({ ...emailTemplate, subject: e.target.value })} />
                                <small className="text-danger">
                                    {err.subject}
                                </small>
                            </FormGroup>
                        </div>
                        {
                            selectedVariable.length > 0 &&
                            <div className="col-12">
                                <FormGroup className="light_typeahead custom_drop">
                                    <Label for="sendto">Avaiable Strings</Label><br />
                                    {
                                        selectedVariable.map(variable => {
                                            return <span key={variable.appendable} className="mb-2 mr-2 rbt-token" style={{ backgroundColor: 'rgb(232, 240, 254)' }}>
                                                {variable.appendable}
                                            </span>
                                        })
                                    }
                                </FormGroup>
                            </div>
                        }
                        <div className="col-12">
                            <FormGroup className="light_typeahead custom_drop">
                                <Label for="variables">Variables</Label>
                                <Typeahead
                                    id="variables"
                                    multiple
                                    onChange={setVariable}
                                    selected={selectedVariable}
                                    options={variableList}
                                    placeholder="Variables"
                                    defaultSelected={selectedVariable}
                                    labelKey={(option) => `${option.name}`}
                                />
                            </FormGroup>
                        </div>
                        {
                            selectedVariable.length > 0 &&
                            <div className="col-6">
                                <FormGroup className="light_typeahead custom_drop">
                                    <Label for="varValue">{"Please provide a value for selected variable"}</Label>
                                    <Input type='text' value={emailTemplate.variableValue} onChange={(e) => setEmailTemplate({ ...emailTemplate, variableValue: e.target.value })} />
                                </FormGroup>
                            </div>
                        }
                        <div className="col-12">
                            <FormGroup>
                                <Label for="emailTemplate">Email Template</Label>
                                <div className="text_editor">
                                    {
                                        !isLoading &&
                                        <>
                                            <CKEditor
                                                initData={emailTemplateContent}
                                                onChange={(e) => onEditorStateChange(e)}
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: [{
                                                        name: 'document',
                                                        items: ['Print']
                                                    },
                                                    {
                                                        name: 'clipboard',
                                                        items: ['Undo', 'Redo']
                                                    },
                                                    {
                                                        name: 'styles',
                                                        items: ['Format', 'Font', 'FontSize']
                                                    },
                                                    {
                                                        name: 'colors',
                                                        items: ['TextColor', 'BGColor']
                                                    },
                                                    {
                                                        name: 'align',
                                                        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                                                    },
                                                        '/',
                                                    {
                                                        name: 'basicstyles',
                                                        items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting']
                                                    },
                                                    {
                                                        name: 'links',
                                                        items: ['Link', 'Unlink']
                                                    },
                                                    {
                                                        name: 'paragraph',
                                                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                                                    },
                                                    {
                                                        name: 'insert',
                                                        items: ['Image', 'Table']
                                                    },
                                                    {
                                                        name: 'tools',
                                                        items: ['Maximize']
                                                    },
                                                    {
                                                        name: 'editing',
                                                        items: ['Scayt']
                                                    }
                                                    ],
                                                    extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
                                                    // Adding drag and drop image upload.
                                                    extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
                                                    // uploadUrl: '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',
                                                    // Configure your file manager integration. This example uses CKFinder 3 for PHP.
                                                    // filebrowserBrowseUrl: 'https://api.imgur.com/3/image', filebrowserUploadUrl: '/uploader/browseimage'
                                                }}

                                            />
                                            <small className="text-danger">
                                                {err.emailContent}
                                            </small>
                                        </>
                                    }

                                </div>
                            </FormGroup>
                        </div>
                        <div className="col-12 text-right">
                            <button className={` ${styles.bg_blue} text-white mr-3 btn`} onClick={() => onSave()}>
                                Save
                            </button>
                            <button className={` bg-light btn`}
                                style={{ border: '1px solid #6c757d ' }} onClick={() => history('/admin/templates/email')
                                }>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
            



        </TransitionGroup>

    )
}

export default CreateTemplate
