import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from '../../css/styling.module.css';
import { getActivityVideo, getActivityQuestionImage, getActivityVideo2 } from '../../../actions/activities_actions';
import Dropzone from "react-dropzone";
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import SecureLS from 'secure-ls';




const Question_With_Video2 = (props) => {

    const [videoUpload, setVideoUpload] = useState('')
    const [videoPreview, setVideoPreview] = useState(null);
    

     


     
    

    // On Video Select
    const onVideoSelect = async(file) => {
        //console.log('video answer function before compression ->', file)
        if (file) {

            // Remove Error Message
            document.getElementById(`error-msg-${props.id}`).innerHTML = "";

            for (let i = 0; i < file.length; i++) {

                // File Extension Check
                let fileExtension = file[i].name.split('.').pop();

                if (fileExtension === "mp4") {
                    console.log('mp4 file')
                    const filee = file[i];

                    

                   


                    const reader = new FileReader();
                    reader.onload = () => {
                        setVideoPreview(reader.result);
                    };
                    reader.readAsDataURL(filee);
                  

                    props.allContent.content[props.categoryIndex].questions[props.questionIndex].video = [filee];
                    props.allContent.content[props.categoryIndex].questions[props.questionIndex].q = props.questionNo;

                    props.setContent({ ...props.allContent });


                   

                }
            }


        }


        else {
            //console.log('else came here')
            document.getElementById(`error-msg-${props.id}`).innerHTML = `
                <div class="my-2">
                    <div class="alert alert-danger mb-0">
                        Invalid file type
                    </div>
                </div>
            `;
        }

    }
    

    // on Cancel Video
    const onCancelVideo = (index) => {
        props.allContent.content[props.categoryIndex].questions[props.questionIndex].video.splice(index, 1);
        props.setContent({ ...props.allContent });
        setVideoPreview(null)
    }

    // On Comment
    const onComment = (e) => {

        props.allContent.content[props.categoryIndex].questions[props.questionIndex].comment = e.target.value;
        props.setContent({ ...props.allContent });

    }
    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };
    const params = useParams();
    const userId = params.UserId
    const ls = new SecureLS()
    
    const catId = props.categoryId
    const questionIndex = props.questionIndex
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [qVideo, setQVideo] = useState({})

    const [qImage, setQImage] = useState({})
    useEffect(() => {
        if (params.surveyid || params.ActivityId || params.activityid) {
            dispatch(getActivityVideo2(params.surveyid || params.ActivityId || params.activityid, catId, questionIndex))
                .then((response) => {
                    try {
                       
                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('video not available') }
                    setIsLoading(false);

                })
                .catch(err=>err)
            // dispatch(getActivityQuestionImage(params.ActivityId || params.surveyid || params.activityid))
            //     .then((response) => {
            //         try {
            //             //console.log('question image in respondant side survey->', response.payload.content.fileData)
            //             setQImage(response.payload.content.fileData)
            //         } catch (err) { console.log('image in questionnaire not available') }
            //         setIsLoading(false);
            //     })
            //     .catch(err =>err)




        }
        // else if (params.evaluationid) {
        //     dispatch(getActivityVideo(params.evaluationid))
        //         .then((response) => {
        //             try {
        //                // console.log('question video in respodant side survey->', response.payload.content.fileData)
        //                 setQVideo(response.payload.content.fileData)
        //             } catch (err) { console.log('video not available') }
        //             setIsLoading(false);

        //         })
        //         .catch(err=>err)
        //     dispatch(getActivityQuestionImage(params.evaluationid))
        //         .then((response) => {
        //             try {
        //                 //console.log('question image long text in respondant side survey->', response.payload.content.fileData)
        //                 setQImage(response.payload.content.fileData)
        //             } catch (err) { console.log('image in questionnaire not available') }
        //             setIsLoading(false);
        //         })
        //         .catch(err=>err)


        // }
    }, []);






    const onChangeText = (e) => {
   
      

        props.allContent.content[props.categoryIndex].questions[props.questionIndex].answer = e.target.value
       props.setContent({ ...props.allContent });
       

   }

    const makeLinksOpenInNewTab = (htmlContent) => {
        // Create a DOM element to parse the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;

        // Find all anchor tags within the parsed HTML content
        const anchorTags = tempElement.getElementsByTagName('a');

        // Iterate through the anchor tags and set the target attribute to '_blank'
        for (const anchorTag of anchorTags) {
            anchorTag.setAttribute('target', '_blank');
            // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
            anchorTag.setAttribute('rel', 'noopener noreferrer');
        }

        // Return the modified HTML content
        return tempElement.innerHTML;
    }
    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}
                        {props.questionText &&
                            <span style={{ color: '#000', fontSize: '27px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }

                        {/* Question YT video */}
                        {props.questionVideo && (
                            <div>

                                <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>

                            </div>
                        )}

                    </p>


                    {/* Question Video */}
                    {

                        props.questionFile &&
                        <>
                            {

                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}

                                        <ReactPlayer url={`data:${qVideo.type};base64,${qVideo.data}`} controls />
                                    </div>
                                ) :
                                    /* Question Image */
                                    <div className="mb-3 mt-3">

                                        {qVideo.data &&


                                            <div>
                                                <div className="position-relative">
                                                    <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                            }
                        </>


                    }


                    <div className="mt-3 mx-3">

                        {/* File Limit and File Size */}
                        <small className="text-danger">
                            Max files Limit: {props.filesLimit ? props.filesLimit : "1"} | Max file Size: {props.filesSize ? props.filesSize : "6"} MB
                        </small>

                        {/* Video Display & Dropzone  */}
                        {props.video && props.video.length > 0 ? (

                            // Video Display
                            <div className="row pt-3">

                                {props.video.map((videoSrc, index) => {
                                    return (
                                        <div key={index} className="position-relative mx-3 mb-3">
                                            <span className="questionVideo_close" style={{ marginLeft: '275px', zIndex: '2' }} onClick={() => onCancelVideo(index)}>&times;</span>
                                            
                                            {videoPreview && videoPreview != null &&
                                                <video width={300} controls className="img-fluid- border" style={{ maxHeight: 500 }}>
                                                    <source src={videoPreview} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            }

                                           
                                        </div>
                                    )
                                })}

                            </div>

                        ) : (

                            // Video Drop Zone 
                            < div className="dropzone-wrapper dropzone-wrapper-sm mb-3">
                                <Dropzone
                                    maxFiles={!Number.isNaN(parseInt(props.filesLimit)) ? parseInt(props.filesLimit) : 0}
                                    maxSize={!Number.isNaN(parseFloat(props.filesSize)) ? (parseFloat(props.filesSize) * 1048576) : (6 * 1048576)}
                                    multiple={!Number.isNaN(parseInt(props.filesLimit)) ? (parseInt(props.filesLimit) > 0 ? true : false) : false}
                                    accept={".mp4"}
                                    onDrop={file => onVideoSelect(file)}

                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="dropzone-content" style={{ cursor: 'pointer' }}>

                                                {videoPreview != null ? (
                                                    // <video controls style={{ width: '50%', height: '50%' }}>
                                                    //     <source src={''} type="video/mp4" />
                                                    //     Your browser does not support the video tag.
                                                    // </video>
                                                    <div></div>
                                                ) : (
                                                    <div className="d-flex">
                                                        <span className="pe-7s-video ml-1 mr-3" style={{ fontSize: "2rem", verticalAlign: "middle" }}></span>
                                                        <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Click to select file to upload.</span>
                                                    </div>)}

                                                {/* <div className="d-flex">
                                                    <span className="pe-7s-video ml-1 mr-3" style={{ fontSize: "2rem", verticalAlign: "middle" }}></span>
                                                    <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Click to select file to upload.</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>

                        )}

                        {/* Text  Area */}
                        <div className="form-group mt-1 mb-3">
                            <label>Answer Field</label>

                            {/* Text Limit */}
                            {props.textLimit && !Number.isNaN(parseInt(props.textLimit.max)) && (
                                <small className="text-danger mx-1">(Max Character Limit: {props.textLimit.max})</small>
                            )}

                            <input className="form-control" type="text" value={props.answer} onChange={onChangeText} placeholder="Write here" />
                        </div>



                        {/* Comment */}
                        {props.isComment && (
                            <div className="form-group mt-1 mb-3">
                                <label>Comment</label>
                                <input className="form-control" type="text" value={props.comment} onChange={onComment} placeholder="Write here" />
                            </div>
                        )}

                    </div>

                    {/* Upload related error */}
                    <div id={`error-msg-${props.id}`}></div>

                    {/* Required Answer error */}
                    {/* {props.required && !props.isAnswered && (
                        <div id={`error-msg`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )} */}


                </div>
            </div>
        </div >
    )

}

export default Question_With_Video2;