import React, { useState, useEffect } from 'react';
import JournalIcon from '../../../../../../assets/images/journal_icon.png'
import { useNavigate, useParams, useLocation } from 'react-router';
import PageHeader from '../../../../../../assets/components/pageheader/PageHeader';
import Photo from '../../../../../../assets/images/photo_icon.png'
import CKEditor from "@ckeditor/ckeditor5-react";
import ArrowLeft from '../../../../../../assets/images/arrow_left.png'
import { Card, CardBody } from 'reactstrap';
import styles from '../../../../../../assets/css/rstyling.module.css'
import { getActivity, getActivityImg } from '../../../../../../actions/activities_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import ResizeDetector from 'react-resize-detector';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const JournalPreview = (props) => {

    const location = useLocation();
    const history = useNavigate()
    const params = useParams()
    const [journalData, setJournalData] = useState({
        // bannerImage: Banner
    })
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [bannerImg, setBannerImg] = useState({})

    useEffect(() => {

        dispatch(getActivityImg(params.journalid))
        .then((response) =>{
            try{
          
            setBannerImg(response.payload.content.fileData)}catch(err){console.log('img not available')}
            
        }).catch(err=>{
            console.log(err)
        })

        if (params.journalid !== 'undefined') {
            dispatch(getActivity(params.journalid))
                .then((response) => {
                    setJournalData(response.payload.content)
                    setLoading(false)
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        } else {
            setLoading(false)

        }

    }, [])

    const [currentWidth, setWidth] = useState()
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // top: '50%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>
                     <>
                    <div className="admin_header">
                        <PageHeader
                            heading="Diary Activity" img={JournalIcon}
                            btnGroup={true}
                            btn1Click={() => setWidth(1026)}
                            btn2Click={() => setWidth(470)}
                            btn3Click={() => setWidth(400)}
                        />
                    </div>

                    {/* Back Icon */}
                    <span onClick={() => location.state && location.state.from === 'create' ?
                                history(`/admin/project/edit-journal/${params.projectid}/${params.journalid}`) :
                                history(-1)} style={{ cursor: 'pointer' }}>
                        <img src={ArrowLeft} alt="" width="8px" />
                        <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                    </span>

                    <div className="container-fluid" style={{ width: currentWidth }}>
                        {/* Image Display */}
                        {
                            journalData.bannerImage &&
                            <div className="mx-3 mb-4">
                                <div className="pt-3">
                                    <div className="position-relative">
                                        <img src={`data:${bannerImg.type} ;base64, ${bannerImg.data}`} className={`img-fluid border ${styles.banner_image}`} alt="img" width={200} />
                                    </div>
                                </div>
                            </div>
                        }


                        <Card className="main-card mx-3 mt-3 mb-4">
                            <CardBody>
                                <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Description</h6>
                                <p>{journalData.description}</p>
                            </CardBody>


                        </Card>
                        {
                            journalData.query &&
                            <Card className="main-card mx-3 mt-3 mb-4">

                                <CardBody>
                                    <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Question</h6>
                                    {/* <p>{data.description}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: journalData.query }}></p>

                                    <div className="py-2 d-inline-block">
                                        <div className="position-relative mr-3">
                                            {journalData.additionalFiles && journalData.additionalFiles.length > 0 &&
                                                journalData.additionalFiles.map(file => {
                                                    return <img src={file} width={200} height={100} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="" />
                                                })
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                        }
                        <Card className="main-card mx-3 mt-3 mb-4">
                            <CardBody>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Create New Entry</h6>
                                        <label for="emailTemplate">CK Editor</label>
                                        <div className="text_editor">
                                            <CKEditor data="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 mt-2">
                                        <label>File</label>
                                        <div className={`input-group mb-3`}>
                                            <input type="text" className="form-control"
                                                style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                                placeholder="Add File"
                                            />
                                            <div className={`input-group-append`}>
                                                <button className="btn btn-light" style={{ height: "2.5rem" }} >
                                                    <img src={Photo} alt="" width="15px" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 mt-2">
                                        <label>Date</label>
                                        <input className="form-control" type="date" name="date" />
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </div>

                </>
                </LoadingOverlay>
              
            )}
        />
    )


}

export default JournalPreview


