import React, { useEffect, useState } from 'react'
import ReactWordcloud from "react-wordcloud";
import { Resizable } from "re-resizable";
import Listing from '../../../../assets/components/listing/Listing';
import { Card, CardBody } from "reactstrap";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { searchWordCloud } from '../../../../actions/project_actions';

const resizeStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 'auto',
    border: "none",
    background: "#ffffff",

};


const WordCloud = () => {

    const [isLoading, setLoading] = useState(true)

    const dispatch = useDispatch()
    const history = useNavigate()
    const params = useParams()
  //  const path = currentPath
    let { search } = useLocation();
    
    const query = new URLSearchParams(window.decodeURIComponent(search));

    const [column, setColumn] = useState([
        { Name: 'Rank', Type: 'serialNo' },
        { Name: "Word", Type: 'string', key: 'text' },
        { Name: "Frequency", Type: 'number', key: 'value', width: 110 },
    ])

    const [wordCloud, setWordCloud] = useState([])

    const options = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontFamily: 'Poppins'
    };

    const getWordCloud = () => {
        dispatch(searchWordCloud({ searchStr: query.get('searchStr'), projectId: params.projectid }))
            .then((response) => {
              
                setWordCloud([...response.payload.content.wordCloud])
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })

    }

    useEffect(() => {
        getWordCloud()
    }, [query.get('searchStr')])

    return (
        <div className="container-fluid">
            <h4 className="text-capitalize m-3" style={{ fontWeight: '500', fontSize: '0.9rem' }}>
                {/* Activity Name */}
            </h4>
            <Resizable
                defaultSize={{
                    width: 'auto',
                    height: 300
                }}
                style={resizeStyle}
            >
                <div style={{ width: "100%", height: "100%" }}>
                    <ReactWordcloud words={wordCloud} options={options} />
                </div>
            </Resizable>
            <div className="tbl_round mt-3">
                <Card className="main-card mb-3">
                    <LoadingOverlay tag="div" active={isLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>
                        <CardBody className="p-0">

                            {/* Listing */}
                            <div className="table_border">
                                <Listing columnName={column}
                                    data={wordCloud}
                                    isStriped={true}
                                />
                            </div>

                        </CardBody>
                    </LoadingOverlay>

                </Card>
            </div>
        </div>
    )
}

export default WordCloud
