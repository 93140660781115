import React, { useEffect, useState, useRef } from 'react'
import Chart from 'react-apexcharts'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Card, CardBody, Button, CardHeader, UncontrolledButtonDropdown, DropdownMenu, DropdownToggle, ButtonGroup, DropdownItem } from 'reactstrap'
import classnames from "classnames";
import { getActivityReplyGraphs } from '../../../../../actions/activities_actions'
import { getSurveyWordCloud } from '../../../../../actions/activities_reply_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import ReactWordcloud from "react-wordcloud";
import { Resizable } from "re-resizable";
import GraphCartsWordCloud from './GraphCartsWordCloud';
import {
    faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toPng } from 'html-to-image';
import pptxgen from 'pptxgenjs';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert'


const Graphcharts = () => {

    const chartRef = useRef(null);
    const dispatch = useDispatch()
    const params = useParams()
    const [wordCloud, setWordCloud] = useState([])
    const [filter_check, set_filter_check] = useState(false)
    const [type, setType] = useState('');
    const [value, setValue] = useState('');
    const [filterObject, setFilterObject] = useState({ type: '', value: '' });
    const [isShowWordCloud, setShowWordCloud] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [surveyResponse, setSurveyResponse] = useState([])
    const [citiesFilter, set_citiesFilter] = useState([])
    const [newDemographicsActiveTab, setNewDemographicsActiveTab] = useState('City')
    const [totalReplies, set_totalReplies] = useState('')
    const [newCityStats, setNewCityStats] = useState({
        options: {},
        series: [],
        labels: []
    })

    const [newGenderStats, setNewGenderStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [newIncomeRangeStats, setNewIncomeRangeStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [newSECStats, setNewSECStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [newAgeRangeStats, setNewAgeRangeStats] = useState({
        options: {},
        series: [],
        labels: []
    })


    const downloadPPTWordCloud = async (chartIndex, questionText, wordCloudData) => {
       
        const chartContainer = document.getElementById(`chart-${chartIndex}`);

        if (!chartContainer) {
            alert('Chart container not found');
            return;
        }

        try {
            
            const dataUrl = await toPng(chartContainer);
            const pptx = new pptxgen();
            const slide = pptx.addSlide();
            slide.addText(`Q${chartIndex + 1}. ${removeSpanTagsFromQuestion(questionText)}`, { x: 0.5, y: 0.5, fontSize: 18 });
            slide.addImage({ data: dataUrl, x: 1, y: 1, w: 6, h: 4 });
            pptx.writeFile({ fileName: `Q${chartIndex + 1}.pptx` });

           
            const worksheetData = wordCloudData.map(item => ({ Word: item.text, Frequency: item.value }));
            const worksheet = XLSX.utils.json_to_sheet(worksheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Word Cloud Data');
            XLSX.writeFile(workbook, `Q${chartIndex + 1}_wordcloud_data.xlsx`);
        } catch (error) {
            console.error('Error generating image:', error);
        }
    };



    const downloadPPT = async (chartIndex, questionText, response) => {
        try {
            const pptx = new pptxgen();

            // const response = getResponseForIndex(chartIndex); // Fetch the data for the specific chart index
            // const { questionText } = response;

            const chartData = getRadioCheckboxGraphs(response);
            const { options, series } = chartData;

            const slide = pptx.addSlide();

            // Add question text as title
            slide.addText(`Q${chartIndex + 1}. ${removeSpanTagsFromQuestion(questionText)}`, { x: 0.5, y: 0.5, fontSize: 18 });

            // Prepare data for pptxgenjs chart
            const data = [{
                name: `Q${chartIndex + 1}`,
                labels: options.labels,
                values: series
            }];

            // Add chart

            slide.addChart(pptx.ChartType.doughnut, data, {
                x: 1,
                y: 1.5,
                w: 6,
                h: 3.5,
                showLegend: true,
                legendPos: 'b',
                showTitle: false,

            });

            await pptx.writeFile({ fileName: `Q${chartIndex + 1}.pptx` });
        } catch (error) {
            console.error('Error generating PPT:', error);
        }
    };

   
    const downloadPPTGrid = async (chartIndex, questionText, response) => {
        try {
            const pptx = new pptxgen();
           
           
           const responses = response.availableGridOptions

              responses.forEach((response, index) => {
                const slide = pptx.addSlide();
                slide.addText(`Q${chartIndex + 1}. ${removeSpanTagsFromQuestion(questionText)}`,{ x: 0.5, y: 0.5, fontSize: 20 });
             
          
                const labels = response.options.map(option => option.text);
                const values = response.options.map(option => option.count);
          
                const dataChartBar = [
                  {
                    name: response.text,
                    labels: labels,
                    values: values
                  }
                ];
          
                slide.addChart(pptx.ChartType.bar, dataChartBar, { x: 1, y: 1.5, w: 9, h: 4,  legendPos: 't', legendFontSize: 14,
                showLegend: true, chartArea: { fill: 'F1F1F1' },
                barDir: 'col' });
              });
          
              pptx.writeFile({ fileName: `Q${chartIndex +1}.pptx` });
            
          
    


        } catch (error) {
            console.error('Error generating PPT:', error);
        }
    };





    const activityGraphs = () => {
        const filter = filterObject
        // const timeout = setTimeout(() => {
        //     Swal({
        //         title: "Internet is slow",
        //         text: "The request is taking longer than usual. Please wait...",
        //         icon: "info",
        //         allowOutsideClick: false,
        //         showConfirmButton: false,
                
        //       });
        //   }, 30000); // 30 seconds
        dispatch(getActivityReplyGraphs({ activityId: params.activityid,filter }))
            .then((response) => {
                //clearTimeout(timeout);
// if(response.payload.content === 'No results available"'){
//     Swal({
//         title: "No results available",
//         text: "There are no results available for this activity.",
//         icon: "info",
//         showConfirmButton: true,
//       });
//     setLoading(false)
//     return
// }
                set_totalReplies(response.payload.content.totalReplies)
                //Swal.close();
                let newCityCount = []
                let newCityName = []
                response.payload.content.demographics.newRepliesCities.length > 0 && response.payload.content.demographics.newRepliesCities.map(city => {
                    newCityCount.push(city.count)
                    newCityName.push(city._id)
                    set_citiesFilter(newCityName)
                })
                let newGenderCount = []
                let newGenderName = []
                response.payload.content.demographics.newRepliesGenders.length > 0 && response.payload.content.demographics.newRepliesGenders.map(gender => {
                    newGenderCount.push(gender.count)
                    newGenderName.push(gender._id)
                })

                let newSECCount = []
                let newSECName = []
                response.payload.content.demographics.newRepliesSECs.length > 0 && response.payload.content.demographics.newRepliesSECs.map(sec => {
                    newSECCount.push(sec.count)
                    newSECName.push(sec._id)
                })

                let newIncomeCount = []
                let newIncomeName = []
                response.payload.content.demographics.newRepliesIncomes.length > 0 && response.payload.content.demographics.newRepliesIncomes.map(income => {
                    newIncomeCount.push(income.count)
                    newIncomeName.push(income._id)
                })
                let newAgeCount = []
                let newAgeName = []
                response.payload.content.demographics.newRepliesAges.length > 0 && response.payload.content.demographics.newRepliesAges.map(age => {
                    newAgeCount.push(age.count)
                    newAgeName.push(age._id)
                })




                setNewCityStats({
                    options: {
                        labels: newCityName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        //colors: colors

                    },
                    series: newCityCount,
                })
                setNewGenderStats({
                    options: {
                        labels: newGenderName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        //colors: colors

                    },
                    series: newGenderCount,
                })

                setNewSECStats({
                    options: {
                        labels: newSECName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        //colors: colors

                    },
                    series: newSECCount,
                })

                setNewIncomeRangeStats({
                    options: {
                        labels: newIncomeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        //colors: colors

                    },
                    series: newIncomeCount,
                })
                setNewAgeRangeStats({
                    options: {
                        labels: newAgeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        //colors: colors

                    },
                    series: newAgeCount,
                })

//console.log('survey graph grid ->',response.payload.content.surveyGraphs)
                setSurveyResponse([...response.payload.content.surveyGraphs])
                setLoading(false)
            })
            .catch(err => {
                //setLoading(false)
                //clearTimeout(timeout); // Clear timeout in case of error
                // Swal({
                //     title: "Error fetching data",
                //     text: "There was an issue with the request.",
                //     icon: "error",
                //     showConfirmButton: true,
                //   });
            })
    }

    useEffect(() => {
        activityGraphs()
    }, [filterObject])

    // word cloud options

    // Setting graph options based on different question types
    // const getRadioCheckboxGraphs = (response) => {


    //     let series = []
    //     let label = []
    //     response.availableOptions.length > 0 && response.availableOptions.map(option => {
    //         series.push(option.count)
    //          label.push(option.text)
    //     })

    //     return {
    //         options: {
    //             chart: {
    //                 id: 'apexchart-example-2'
    //             },
    //             labels: label,
    //         },
    //         series: series,
    //     }

    // }


    // const getRadioCheckboxGraphs = (response) => {
    //     let series = [];
    //     let labels = [];
    //     let maxLength = 50
    //     response.availableOptions.length > 0 &&
    //         response.availableOptions.forEach((option) => {
    //             series.push(option.count);
    //             let label = option.text;
    //             label = label.length > 30 ? '' : label;
    //             labels.push(label);
    //             // let splitIndex = label.lastIndexOf(' ', maxLength);

    //             // //     // If no space found within the first maxLength characters, split at maxLength
    //             //      if (splitIndex === -1) {
    //             //          splitIndex = maxLength;
    //             //      }

    //             // //     // Extract the portion of the label for the current line
    //             //      const line = label.substring(0, splitIndex);
    //             //      labels.push(line)

    //         });



    //     return {
    //         options: {
    //             chart: {
    //                 id: 'apexchart-example-2'
    //             },
    //             labels: labels
    //         },
    //         series: series
    //     };
    // };

    const getRadioCheckboxGraphs = (response) => {
        let series = [];
        let labels = [];
        response.availableOptions.length > 0 &&
            response.availableOptions.forEach((option) => {
                series.push(option.count);
                let label = option.text;
                // Trim label to 10 characters if it exceeds that length
                // label = label.length > 40 ? '' : label;
                labels.push(label);
            });

        return {
            options: {
                chart: {
                    id: 'apexchart-example-2'
                },
                legend: {
                    // show: false 
                    position: 'bottom'
                },
                labels: labels

            },
            series: series
        };
    };

    const newToggle = (tab) => {
        if (newDemographicsActiveTab !== tab) {
            setNewDemographicsActiveTab(tab)
        }
    }

    const newDemographicsChart = () => {
     
        if (newDemographicsActiveTab === 'City') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <CardBody className="p-0">
                        <div className="pie">

                            <Chart options={newCityStats.options} series={newCityStats.series} type="pie" height="400" />
                        </div>
                    </CardBody>
                </Card>
            )
        } else if (newDemographicsActiveTab === 'Gender') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <CardBody className="p-0">
                        <div id="donut">
                            <Chart options={newGenderStats.options} series={newGenderStats.series} type="donut" height="200" />
                        </div>
                    </CardBody>
                </Card>

            )
        }
        else if (newDemographicsActiveTab === 'SEC') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <CardBody className="p-0">
                        <div id="donut">
                            <Chart options={newSECStats.options} series={newSECStats.series} type="pie" height="300" />
                        </div>
                    </CardBody>
                </Card>

            )
        }
        else if (newDemographicsActiveTab === 'Income Range') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <CardBody className="p-0">
                        <div id="pie">
                            <Chart options={newIncomeRangeStats.options} series={newIncomeRangeStats.series} type="pie" height="400" />
                        </div>
                    </CardBody>
                </Card>

            )
        }
        if (newDemographicsActiveTab === 'Age Range') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <CardBody className="p-0">
                        <div className="pie">

                            <Chart options={newAgeRangeStats.options} series={newAgeRangeStats.series} type="donut" height="300" />
                        </div>
                    </CardBody>
                </Card>
            )
        }


    }

    const getImageVideoGraph = (response) => {
        return {
            options: {
                labels: ['Responses'],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
            },
            series: [response.count],
        }
    }

    const getLongTextGraph = (response) => {
        return {
            series: [
                {
                    name: 'Responses',
                    data: [response.longResponse.count, response.shortResponse.count]
                }
            ],
            options: {
                chart: {
                    id: 'apexchart-example-3',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                width: "20",
                xaxis: {
                    categories: ['Long Responses', 'Short Responses']
                },
            }
        }
    }

    const getImageVideoTextGraph = (response) => {
        return {
            series: [
                {
                    name: 'Responses',
                    data: [response.fileResponse.count, response.textResponse.count]
                }
            ],
            options: {
                chart: {
                    id: 'apexchart-example-3',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                width: "20",
                xaxis: {
                    categories: ['File Responses', 'Text Responses']
                },
            }
        }
    }

    const getGridGraph = (response) => {
        let rowName = []
        let responseData = []
        let colName = []
       
        response.availableGridOptions.map((gridOption, index) => {
            let colCount = []

            gridOption.options.map((option, optionIndex) => {
                colCount.push(option.count)
                colName.push(option.text)

            })

            responseData.push(colCount)
            rowName.push(gridOption.text)
        })

        const transpose = (array, arrayLength) => {
            var newArray = [];
            for (var i = 0; i < array.length; i++) {
                newArray.push([]);
            };

            for (var i = 0; i < array.length; i++) {
                for (var j = 0; j < arrayLength; j++) {
                    newArray[j].push(array[i][j]);
                };

                if (arrayLength < array[0].length) {
                    for (let index = arrayLength; index < array[0].length; index++) {
                        newArray.push([])
                        newArray[index].push(array[i][index]);

                    }
                }
            };

            return newArray;
        }
        let transposedData = transpose(responseData, responseData.length)

        // console.log(transposedData)
        let graphSeries = transposedData.map((series, index) => {
            // console.log(series, colName[index])

            if (series[0] !== undefined) {
                return {
                    name: colName[index],
                    data: series
                }
            }

        })

        graphSeries = graphSeries.filter(Boolean)

        return {
            series: graphSeries,
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: rowName,
                },
                legend: {
                    position: 'top',
                    offsetX: 40
                },
                fill: {
                    opacity: 1
                },
                stroke: {
                    show: true,
                    width: 25,
                    colors: ['transparent'],
                    dashArray: 10
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return + val + " Responses"
                        }
                    },

                }
            }
        }
    }
    const removeSpanTagsFromQuestion = (question) => {

        let questionFinal = question && question != undefined ? question.replace(/<span>|<\/span>/g, '') : ''
        return questionFinal
    }


    // const getSurveyQuestionWordCloud = (questionId, activityId) => {

    //     // setLoading(true)
    //     // let questionId = []
    //     // if (isShowQuestion) {
    //     //     questionId = selectedQuestion.map(question => question.questionId)
    //     // }
    //     console.log('questionid ->', questionId, 'activityid ->', activityId)
    //     dispatch(getSurveyWordCloud({ questionId: questionId, activityId: activityId }))
    //         .then((response) => {
    //             console.log('response of wordcloud->', response.payload.content)

    //             setWordCloud([...response.payload.content.wordCloud])
    //             setShowWordCloud(true)
    //             setLoading(false)
    //         })
    //         .catch(err => {
    //             setLoading(false)
    //         })


    // }
    // useEffect(() => {
    //     getSurveyQuestionWordCloud()
    // }, [])

    const activateFilterOptions = () => {
        if(filter_check === false){
        set_filter_check(true)
        }
        if(filter_check === true){
            set_filter_check(false)
        }
    }

    const handleFilterClick = () => {
        if (type && value) {
            const filter = { type, value };
            setFilterObject(filter);
            // console.log('Filter object:', filter);
        } else {
            alert('Please select both Type and Value');
        }
    }

    const resetFilter = () => {
        setFilterObject({ type: '', value: '' })
        setValue('')
    }

    return (
        <div className="container-fluid">
            <h4 className="text-capitalize mt-3" style={{ fontWeight: '500', fontSize: '0.9rem' }}>
                {/* Activity Name */}
            </h4>
            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>



                <div style={{ paddingTop: '50px', paddingBottom: '100px' }}>
                    <div className='row' >
                        <div className={`col-12`} >

                        <div className="custom-checkbox custom-control pb-4 col-md-3 col-6">
                                <input type="checkbox" id={`filtercheck`} className="custom-control-input"
                                    onChange={() => activateFilterOptions()}
                                />
                                <label className="custom-control-label" htmlFor={`filtercheck`}>
                                    <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Filter Stats</span>
                                </label>
                            </div>
                            {filter_check === true &&
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', marginBottom: '5rem' }}>
                                        {/* Type Dropdown */}
                                        <div style={{ flex: 2 }}>
                                            <label>
                                                Filter Type:
                                                <select className="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                                                    <option value="">Select Type</option>
                                                    <option value="Gender">Gender</option>
                                                    <option value="City">City</option>
                                                </select>
                                            </label>
                                        </div>

                                        {/* Value Dropdown */}
                                        <div style={{ flex: 2 }}>
                                            <label>
                                               Filter Value:
                                                <select className="form-control" value={value} onChange={(e) => setValue(e.target.value)}>
                                                    <option value="">Select Value</option>
                                                    {type === 'Gender' && (
                                                        <>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </>
                                                    )}
                                                   
                                                    {type === 'City' && (
                                                        <>
                                                            {/* <option value="Lahore">Lahore</option>
                                                            <option value="Islamabad">Islamabad</option>
                                                            <option value="Karachi">Karachi</option> */}
                                                            {citiesFilter.map((city, index) => (
                                                                <option key={index} value={city}>
                                                                    {city}
                                                                </option>
                                                            ))}
                                                        </>

                                                    )}
                                                </select>
                                            </label>
                                        </div>

                                        {/* Apply Filter Button */}
                                        <button className="btn btn-hover-shine text-light"
                                            style={{ flex: 1, backgroundColor: '#006FA7', padding: '0.5rem' }}
                                            onClick={handleFilterClick}
                                            disabled={!type || !value}
                                        >
                                            Apply Filter
                                        </button>
                                        <button className="btn btn-hover-shine text-light"
                                            style={{ flex: 1, backgroundColor: '#808080', padding: '0.5rem' }}
                                            onClick={resetFilter}
                                            disabled={!type || !value}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            }

                            <h5 className="opacity-6 text-capitalize text-center">Total Responses: <b>{totalReplies}</b></h5>
                            <Card className="main-card">

                                <CardHeader className="d-flex">
                                    <h5 className="opacity-6 text-capitalize">
                                        Demographics of survey
                                    </h5>

                                    {
                                        // width <= 350 ?
                                        //     <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                                        //         <UncontrolledButtonDropdown>

                                        //             <DropdownToggle caret size="sm" className="btn-icon btn-icon-only" color="dark">
                                        //                 <div className="btn-icon-wrapper">
                                        //                     <FontAwesomeIcon icon={faBars} />
                                        //                 </div>
                                        //             </DropdownToggle>
                                        //             <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                        //                 <DropdownItem onClick={() => newToggle("Country")}>
                                        //                     <span className="opacity-6">Country</span>
                                        //                 </DropdownItem>
                                        //                 <DropdownItem onClick={() => newToggle("City")}>
                                        //                     <span className="opacity-6">City</span>
                                        //                 </DropdownItem>
                                        //                 <DropdownItem onClick={() => newToggle("Gender")}>
                                        //                     <span className="opacity-6">Gender</span>
                                        //                 </DropdownItem>
                                        //                 <DropdownItem onClick={() => newToggle("Income Range")}>
                                        //                     <span className="opacity-6">Income Range</span>
                                        //                 </DropdownItem>
                                        //                 <DropdownItem onClick={() => newToggle("Signup")}>
                                        //                     <span className="opacity-6">Signup</span>
                                        //                 </DropdownItem>
                                        //             </DropdownMenu>
                                        //         </UncontrolledButtonDropdown>
                                        //     </div>
                                        //     :
                                        <div className="btn-actions-pane-right">
                                            <ButtonGroup size="sm">

                                                <Button color="dark"
                                                    className={
                                                        "btn-shadow " +
                                                        classnames({
                                                            active: newDemographicsActiveTab === "1",
                                                        })
                                                    }
                                                    onClick={() => {
                                                        newToggle("City");
                                                    }}>
                                                    City
                                                </Button>
                                                <Button color="dark"
                                                    className={
                                                        "btn-shadow " +
                                                        classnames({
                                                            active: newDemographicsActiveTab === "2",
                                                        })
                                                    }
                                                    onClick={() => {
                                                        newToggle("Gender");
                                                    }}>
                                                    Gender
                                                </Button>
                                                <Button color="dark"
                                                    className={
                                                        "btn-shadow " +
                                                        classnames({
                                                            active: newDemographicsActiveTab === "3",
                                                        })
                                                    }
                                                    onClick={() => {
                                                        newToggle("Income Range");
                                                    }}>
                                                    Income
                                                </Button>
                                                <Button color="dark"
                                                    className={
                                                        "btn-shadow " +
                                                        classnames({
                                                            active: newDemographicsActiveTab === "4",
                                                        })
                                                    }
                                                    onClick={() => {
                                                        newToggle("Age Range");
                                                    }}>
                                                    Age
                                                </Button>
                                                <Button color="dark"
                                                    className={
                                                        "btn-shadow " +
                                                        classnames({
                                                            active: newDemographicsActiveTab === "6",
                                                        })
                                                    }
                                                    onClick={() => {
                                                        newToggle("SEC");
                                                    }}>
                                                    SEC
                                                </Button>

                                            </ButtonGroup>
                                        </div>


                                    }


                                </CardHeader>
                                <CardBody>
                                    {newDemographicsChart()}
                                </CardBody>
                            </Card>
                        </div>

                    </div>
                </div>

                <div className="row">

                    {
                        surveyResponse.length > 0 &&
                        surveyResponse.map((response, index) => {
                            return <>
                                {
                                    (response.questionType === 'radio' || response.questionType === 'radio script' || response.questionType === 'checkbox' || response.questionType === 'checkbox script' || response.questionType === 'spectrum' || response.questionType === 'slider') &&
                                    <div className="col-md-6 col-sm-12 pb-2" >
                                        <Card className="main-card" style={{ height: '100%' }}>

                                            <CardBody className="" id={`chart-${index}`}>

                                                <p style={{ fontWeight: '500' }}>Q{index + 1}. {removeSpanTagsFromQuestion(response.questionText)}</p>
                                                <div className="text-center mb-3" >
                                                    <Chart ref={chartRef} options={getRadioCheckboxGraphs(response).options} series={getRadioCheckboxGraphs(response).series} type="donut" />

                                                </div>

                                            </CardBody>
                                            <div className="text-center mb-3">
                                                <Button onClick={() => downloadPPT(index, response.questionText, response)}>Download as PPT</Button>
                                            </div>
                                        </Card>
                                    </div>
                                }

                                {/* {
                                    (response.questionType === 'image' || response.questionType === 'video') &&
                                    <div className="col-md-6 col-sm-12 pb-3 px-2">
                                        <Card className="main-card" style={{ height: '100%' }}>
                                            <CardBody className="">
                                                <p style={{ fontWeight: '500' }}>Q{index + 1}. {removeSpanTagsFromQuestion(response.questionText)}</p>
                                                <div className="radialBar">
                                                    <Chart options={getImageVideoGraph(response).options} series={getImageVideoGraph(response).series} type="radialBar" width="100%" />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                } */}

                                {

                                    response.questionType === 'long text' &&
                                    <div className="col-md-6 col-sm-12 pb-3 px-2">
                                        {/* {console.log('question id->', response.questionId)} */}
                                        <Card className="main-card" style={{ height: '100%' }}>
                                            <CardBody >
                                               
                                                <p style={{ fontWeight: '500' }}>Q{index + 1}. {removeSpanTagsFromQuestion(response.questionText)}</p>
                                                {/* <Button className='btn-primary' onClick={() => getSurveyQuestionWordCloud(response.questionId, params.activityid)}>Generate Wordcloud</Button> */}
                                                <div id={`chart-${index}`}>
                                                    
                                                    {/* <GraphCartsWordCloud ref={chartRef} questionId={response.questionId} activityId={params.activityid} wordCloudData={wordCloud} showWordCloud={isShowWordCloud} /> */}
                                                    <GraphCartsWordCloud ref={chartRef} questionId={response.questionId} activityId={params.activityid} showWordCloud={true}  />

                                                </div>


                                            </CardBody>
                                            <div className="text-center mb-3">
                                                <Button onClick={() => downloadPPTWordCloud(index, response.questionText, wordCloud)}>Download as PPT</Button>
                                            </div>
                                        </Card>
                                    </div>
                                }

                                {/* {
                                    (response.questionType === 'image text' || response.questionType === 'video text') &&

                                    <div className="col-md-6 col-sm-12 pb-3 px-2">
                                        <Card className="main-card" style={{ height: '100%' }}>
                                            <CardBody className="">
                                                <p style={{ fontWeight: '500' }}>Q{index + 1}. {removeSpanTagsFromQuestion(response.questionText)}</p>
                                                <div className="radialBar">
                                                    <Chart options={getImageVideoTextGraph(response).options} series={getImageVideoTextGraph(response).series} type="bar" width="100%" />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                } */}
                                {
                                    response.questionType === 'grid' &&

                                    <div className={"col-sm-12 pb-3 px-2"}>

                                        <Card className="main-card" style={{ height: '100%' }}>
                                            <CardBody id={`chart-${index}`}>
                                                
                                                <p style={{ fontWeight: '500' }}>Q{index + 1}. {removeSpanTagsFromQuestion(response.questionText)}</p>
                                                <div style={{ overflowX: 'scroll' }} >
                                                    <Chart options={getGridGraph(response).options} series={getGridGraph(response).series} type="bar" height="400" width='800' ref={chartRef} />
                                                    {/* width={getGridGraph(response).series[0].data.length * 65} */}
                                                </div>

                                            </CardBody>
                                            <div className="text-center mb-3">
                                                <Button onClick={() => downloadPPTGrid(index, response.questionText, response)}>Download as PPT</Button>
                                            </div>
                                        </Card>
                                    </div>
                                }

                            </>
                        })
                    }
                </div>

            </LoadingOverlay >

        </div >
    )
}

export default Graphcharts
