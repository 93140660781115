import React, { useEffect, useState } from "react";
import DashboardIcon from '../../../assets/images/dashboard_header_icon.png'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import CountUp from "react-countup";
import classnames from "classnames";
import Chart from 'react-apexcharts'
import {
    Card,
    CardHeader,
    Button,
    UncontrolledButtonDropdown,
    DropdownMenu,
    CardBody,
    DropdownToggle,
    ButtonGroup,
    DropdownItem,
} from "reactstrap";
import Slider from "react-slick";
import ResizeDetector from "react-resize-detector";
import Listing from "../../../assets/components/listing/Listing";
import Eyeicon from '../../../assets/images/eye_icon.png';
import Editicon from '../../../assets/images/edit_icon.png'
import GroupIcon from '../../../assets/images/user_group_icon.png'
import GraphIcon from '../../../assets/images/graph_icon.png'
import {
    faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Search from '../../../assets/images/search_icon.png'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { adminDashboardDetails, getDashboardProjects } from "../../../actions/dashboard_activities";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";

const Dashboard = () => {
    const colors = [
        "#1f77b4",
        "#ff7f0e",
        "#2ca02c",
        "#d62728",
        "#9467bd",
        "#8c564b",
        "#e377c2",
        "#7f7f7f",
        "#bcbd22",
        "#17becf",
    ];
    const [cityStats, setCityStats] = useState({
        series: [
            {
                name: 'City',
                data: []
            }
        ],
        options: {
            chart: {
                id: 'apexchart-example-3',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            width: "20",
            xaxis: {
                categories: []
            },
        }
    })

    const [countryStats, setCountryStats] = useState({
        series: [
            {
                name: 'Country',
                data: []
            }
        ],
        options: {
            chart: {
                id: 'apexchart-example-3',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            width: "20",
            xaxis: {
                categories: []
            },
        }
    })

    const [maritalStats, setMaritalStats] = useState({
        options: {},
        series: [],
        labels: []
    })

    // last 30 days 
    const [newCityStats, setNewCityStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_3months_CityStats, set_last_3months_CityStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_6months_CityStats, set_last_6months_CityStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_12months_CityStats, set_last_12months_CityStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [newCountryStats, setNewCountryStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_3months_CountryStats, set_last_3months_CountryStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_6months_CountryStats, set_last_6months_CountryStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_12months_CountryStats, set_last_12months_CountryStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [newGenderStats, setNewGenderStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_3months_GenderStats, set_last_3months_GenderStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_6months_GenderStats, set_last_6months_GenderStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_12months_GenderStats, set_last_12months_GenderStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [newIncomeRangeStats, setNewIncomeRangeStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_3months_IncomeStats, set_last_3months_IncomeStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_6months_IncomeStats, set_last_6months_IncomeStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_12months_IncomeStats, set_last_12months_IncomeStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [newAgeStats, setNewAgeStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_3months_AgeStats, set_last_3months_AgeStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_6months_AgeStats, set_last_6months_AgeStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last_12months_AgeStats, set_last_12months_AgeStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [newSignupsStats, setNewSignupsStats] = useState({
        options: {},
        series: [],
        labels: []
    })


    const [last3months_SignupsStats, set_last3months_SignupsStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last6months_SignupsStats, set_last6months_SignupsStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [last12months_SignupsStats, set_last12months_SignupsStats] = useState({
        options: {},
        series: [],
        labels: []
    })
    const [ageGenderStats, setAgeGenderStats] = useState({

    })

    const [userStats, setUserStats] = useState([])
    const [userMonthStats, setUserMonthStats] = useState([])
    const [incomeStats, setIncomeStats] = useState({
        series: [],
        options: {
            chart: {
                type: 'polarArea',
            },
            stroke: {
                colors: ['#fff']
            },
            fill: {
                opacity: 0.8
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    })

    const [seniorityStats, setSeniorityStats] = useState({
        series: [],
        options: {
        }
    })

    const [employmentStats, setEmploymentStats] = useState({
        options: {},
        series: [],
        labels: []
    })

    const dashboardState = useSelector(state => state.Dashboard)
    const [isLoading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const [activeProjects, setActiveProjects] = useState([])
    const [activeActivities, setActiveActivities] = useState([])
    const [dashboardDetails, setDashboardDetails] = useState({})

    const [projectCol, setColumn] = useState([
        // { Name: 'Projects', Type: 'string', key: 'title' },
        // { Name: "Start Date", Type: 'projectstartdate', key: 'startDate' },
        // { Name: "End Date", Type: 'projectenddate', key: 'endDate' },
        // { Name: "Project Type", Type: 'string', key: 'projectType' },
        // { Name: "Research Type", Type: 'string', key: 'researchType' },
        // { Name: "Priority", Type: 'string', key: 'priority' },
        // { Name: "Status", Type: 'string', key: 'status' },
        { Name: "Title", Type: 'string', key: 'dashboardtitle' },
        { Name: "Start Date", Type: 'string', key: 'dashboardstartDate' },
        { Name: "End Date", Type: 'string', key: 'dashboardendDate' },
        { Name: "Type", Type: 'string', key: 'dashboardactivitytype' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                   
                    type: 'dropdown',
                    droplist: [
                        { Name: 'View Details', viewType:'project-details', url: "/admin/project/activity" },
                        // { Name: 'Respondents', url: "/admin/project/participants" },
                        { Name: 'View Details', viewType:'survey-details', url: "/admin/minisurvey-activity" },
                       
                    ]
                },
                {
                    icon: GroupIcon,
                    url: '/admin/project/participants',
                    type: 'project-participants',
                },
                {
                    icon: Editicon,
                    url: '/admin/edit-project',
                    type: 'project-edit',
                },
                {
                    icon: GraphIcon,
                    url: '/admin/minisurvey-analytics',
                    type: 'quick-survey-analytics',
                },
                {
                    icon: Editicon,
                    url: '/admin/edit-minisurvey',
                    type: 'quick-survey-edit',
                },
               
                
            ]
        },
    ])

    useEffect(() => {
        getDashboardDetails()
        getActiveProjectsListing()
    }, [])

    const getActiveProjectsListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(getDashboardProjects({ page: currentPage, limit: dataLimit }))
            .then(response => {

                setActiveProjects(response.payload.content.projects)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)

            })
    }

    // for getting dashboard stats
    const getDashboardDetails = () => {

        dispatch(adminDashboardDetails())
            .then(response => {
                setDashboardDetails(response.payload.content)
                setActiveActivities(response.payload.content.activeActivities)
                const stats = response.payload.content
                const newStats = response.payload.content.newUsers

                let userStats = stats.userStats.allUserCounts

                let monthStats = stats.userStats.allMonths
                const monthArray = []
                monthStats.map(x =>
                    x === 1 ? monthArray.push('January') : x === 2 ? monthArray.push('February') : x === 3 ? monthArray.push('March') : x === 4 ? monthArray.push('April') : x === 5 ? monthArray.push('May') : x === 6 ? monthArray.push('June') : x === 7 ? monthArray.push('July') : x === 8 ? monthArray.push('August') : x === 9 ? monthArray.push('September') : x === 10 ? monthArray.push('October') : x === 11 ? monthArray.push('November') : x === 12 ? monthArray.push('December') : []

                )

                setUserStats(userStats)
                setUserMonthStats(monthArray)



                let cityCount = []
                let cityName = []
                stats.cityStats.length > 0 && stats.cityStats.map(city => {
                    cityCount.push(city.count)
                    cityName.push(city._id)
                })


                setCityStats({
                    series: [
                        {
                            name: 'Users',
                            data: cityCount
                        }
                    ],
                    options: {
                        chart: {
                            id: 'apexchart-example-3',
                            toolbar: {
                                show: false
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true
                            }
                        },
                        width: "20",
                        xaxis: {
                            categories: cityName
                        },
                    }
                })

                let countryCount = []
                let countryName = []
                stats.countryStats.length > 0 && stats.countryStats.map(country => {
                    countryCount.push(country.count)
                    countryName.push(country._id)
                })

                setCountryStats({
                    series: [
                        {
                            name: 'Users',
                            data: countryCount
                        }
                    ],
                    options: {
                        chart: {
                            id: 'apexchart-example-3',
                            toolbar: {
                                show: false
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true
                            }
                        },
                        width: "20",
                        xaxis: {
                            categories: countryName
                        },
                    }
                })
                let maritalCount = []
                let maritalName = []
                stats.maritalStatusStats.length > 0 && stats.maritalStatusStats.map(maritalStatus => {
                    maritalCount.push(maritalStatus.count)
                    maritalName.push(maritalStatus._id)
                })

                setMaritalStats({
                    options: {
                        labels: maritalName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: maritalCount,
                })
                let maleCount = []
                let femaleCount = []
                let a, maxValue, pm, pf;
                let ages = []
                let ageMaxVal = 0;
                // alert(stats.ageAndGenderStats.length)
                stats.ageAndGenderStats.length > 0 && stats.ageAndGenderStats.map(ageGender => {





                    maleCount.push(ageGender.males)
                    femaleCount.push(-ageGender.females)
                    ages.push(ageGender._id)

                    if (ageMaxVal < ageGender.males)
                        ageMaxVal = ageGender.males;
                    if (ageMaxVal < ageGender.females)
                        ageMaxVal = ageGender.females;



                })
                ageMaxVal = ageMaxVal - (ageMaxVal % 10) + 10;

                setAgeGenderStats({


                    series: [
                        {
                            name: 'Females',
                            type: 'column',
                            data: femaleCount

                        },
                        {
                            name: 'Males',
                            type: 'column',
                            data: maleCount
                        },



                    ],
                    options: {
                        chart: {
                            type: 'bar',
                            height: 440,
                            stacked: true
                        },
                        colors: ["var(--success)", "var(--danger)"],
                        plotOptions: {
                            bar: {
                                horizontal: true,
                                barHeight: '80%',
                            },
                        },
                        legend: {
                            position: 'bottom'
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            width: 1,
                            colors: ["#fff"]
                        },

                        grid: {
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            }
                        },
                        yaxis: {
                            min: -ageMaxVal,
                            max: ageMaxVal,
                            title: {
                                text: 'Age',
                                style: {
                                    fontSize: 'small',
                                    fontWeight: 640,
                                    fontFamily: 'Poppins',
                                    cssClass: 'apexcharts-xaxis-title',
                                },
                            },
                        },
                        tooltip: {
                            shared: true,
                            x: {
                                formatter: function (val) {

                                    return val

                                }
                            },
                            y: {
                                formatter: function (val) {
                                    return Math.abs(val)

                                }
                            }
                        },
                        xaxis: {
                            categories: ages,
                            title: {
                                text: 'Users'
                            },
                            labels: {
                                formatter: function (val) {
                                    return Math.abs(Math.round(val))
                                }
                            }
                        },

                    },



                })

                let incomeCount = []
                let incomeName = []
                stats.incomeStats.length > 0 && stats.incomeStats.map(income => {
                    incomeCount.push(income.count)
                    incomeName.push(income._id)
                })

                setIncomeStats({
                    series: incomeCount,

                    options: {
                        labels: incomeName,
                        colors: colors,
                        dataLabels: {
                            enabled: false
                        },

                        yaxis: {
                            show: false,
                        },
                        plotOptions: {
                            polarArea: {
                                rings: {
                                    strokeWidth: 0,
                                },
                            },
                        },
                        tooltip: {
                            enabled: true,
                            x: {
                                formatter: function (val) {
                                    return val
                                }
                            },
                            y: {
                                formatter: function (val) {
                                    return Math.abs(val)
                                }
                            }
                        },
                        legend: {
                            position: 'bottom'
                        },
                    }
                })

                let employmentCount = []
                let employmentName = []
                stats.employmentStats.length > 0 && stats.employmentStats.map(employment => {
                    employmentCount.push(employment.count)
                    employmentName.push(employment._id)
                })

                setEmploymentStats({
                    options: {
                        labels: employmentName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                    },

                    series: employmentCount,
                })

                let seniorityCount = []
                let seniorityName = []
                stats.seniorityStats.length > 0 && stats.seniorityStats.map(seniorityStats => {
                    seniorityCount.push(seniorityStats.count)
                    seniorityName.push(seniorityStats._id)
                })

                setSeniorityStats({
                    series: seniorityCount,
                    options: {
                        chart: {
                            width: 380,
                            type: 'pie',
                        },
                        labels: seniorityName,
                        legend: {
                            position: 'bottom'
                        },
                    }
                })

                // last 30days country data 
                let newCountryCount = []
                let newCountryName = []
                newStats.newUsersCountries.length > 0 && newStats.newUsersCountries.map(country => {
                    newCountryCount.push(country.count)
                    newCountryName.push(country._id)
                })

                // last 3 months country data 
                let last_3months_CountryCount = []
                let last_3months_CountryName = []
                newStats.last_threeMonths_UsersCountries.length > 0 && newStats.last_threeMonths_UsersCountries.map(country => {
                    last_3months_CountryCount.push(country.count)
                    last_3months_CountryName.push(country._id)
                })

                // last 6 months country data 
                let last_6months_CountryCount = []
                let last_6months_CountryName = []
                newStats.last_sixMonths_UsersCountries.length > 0 && newStats.last_sixMonths_UsersCountries.map(country => {
                    last_6months_CountryCount.push(country.count)
                    last_6months_CountryName.push(country._id)
                })

                // last 12 months country data 
                let last_12months_CountryCount = []
                let last_12months_CountryName = []
                newStats.last_twelveMonths_UsersCountries.length > 0 && newStats.last_twelveMonths_UsersCountries.map(country => {
                    last_12months_CountryCount.push(country.count)
                    last_12months_CountryName.push(country._id)
                })

                // last 30 days city data
                let newCityCount = []
                let newCityName = []
                newStats.newUsersCities.length > 0 && newStats.newUsersCities.map(city => {
                    newCityCount.push(city.count)
                    newCityName.push(city._id)
                })

                // last 3 months city data
                let last_3months_CityCount = []
                let last_3months_CityName = []
                newStats.last_threeMonths_UsersCities.length > 0 && newStats.last_threeMonths_UsersCities.map(city => {
                    last_3months_CityCount.push(city.count)
                    last_3months_CityName.push(city._id)
                })

                // last 6 months city data
                let last_6months_CityCount = []
                let last_6months_CityName = []
                newStats.last_sixMonths_UsersCities.length > 0 && newStats.last_sixMonths_UsersCities.map(city => {
                    last_6months_CityCount.push(city.count)
                    last_6months_CityName.push(city._id)
                })

                // last 12 months city data
                let last_12months_CityCount = []
                let last_12months_CityName = []
                newStats.last_twelveMonths_UsersCities.length > 0 && newStats.last_twelveMonths_UsersCities.map(city => {
                    last_12months_CityCount.push(city.count)
                    last_12months_CityName.push(city._id)
                })

                // last 30 days gender data
                let newGenderCount = []
                let newGenderName = []
                newStats.newUsersGenders.length > 0 && newStats.newUsersGenders.map(gender => {
                    newGenderCount.push(gender.count)
                    newGenderName.push(gender._id)
                })

                // last 3 months gender data
                let last_3months_GenderCount = []
                let last_3months_GenderName = []
                newStats.last_threeMonths_UsersGenders.length > 0 && newStats.last_threeMonths_UsersGenders.map(gender => {
                    last_3months_GenderCount.push(gender.count)
                    last_3months_GenderName.push(gender._id)
                })

                // last 6 months gender data
                let last_6months_GenderCount = []
                let last_6months_GenderName = []
                newStats.last_sixMonths_UsersGenders.length > 0 && newStats.last_sixMonths_UsersGenders.map(gender => {
                    last_6months_GenderCount.push(gender.count)
                    last_6months_GenderName.push(gender._id)
                })

                // last 12 months gender data
                let last_12months_GenderCount = []
                let last_12months_GenderName = []
                newStats.last_twelveMonths_UsersGenders.length > 0 && newStats.last_twelveMonths_UsersGenders.map(gender => {
                    last_12months_GenderCount.push(gender.count)
                    last_12months_GenderName.push(gender._id)
                })

                // last 30 days income data
                let newIncomeCount = []
                let newIncomeName = []
                newStats.newUsersIncomes.length > 0 && newStats.newUsersIncomes.map(income => {
                    newIncomeCount.push(income.count)
                    newIncomeName.push(income._id)
                })

                // last 3 months income data
                let last_3months_IncomeCount = []
                let last_3months_IncomeName = []
                newStats.last_threeMonths_UsersIncomes.length > 0 && newStats.last_threeMonths_UsersIncomes.map(income => {
                    last_3months_IncomeCount.push(income.count)
                    last_3months_IncomeName.push(income._id)
                })

                // last 6 months income data
                let last_6months_IncomeCount = []
                let last_6months_IncomeName = []
                newStats.last_sixMonths_UsersIncomes.length > 0 && newStats.last_sixMonths_UsersIncomes.map(income => {
                    last_6months_IncomeCount.push(income.count)
                    last_6months_IncomeName.push(income._id)
                })

                // last 12 months income data
                let last_12months_IncomeCount = []
                let last_12months_IncomeName = []
                newStats.last_twelveMonths_UsersIncomes.length > 0 && newStats.last_twelveMonths_UsersIncomes.map(income => {
                    last_12months_IncomeCount.push(income.count)
                    last_12months_IncomeName.push(income._id)
                })


                let newSignupCount = []
                let newSignupName = []
                newStats.newUsersSignups.length > 0 && newStats.newUsersSignups.map(signup => {
                    newSignupCount.push(signup.count)
                    newSignupName.push(signup._id)
                })

                // last 3 months signups
                let last_3months_SignupCount = []
                let last_3months_SignupName = []
                newStats.last_three_months_signups.length > 0 && newStats.last_three_months_signups.map(signup => {
                    last_3months_SignupCount.push(signup.count)
                    last_3months_SignupName.push(signup._id)
                })

                // last 6 months signups
                let last_6months_SignupCount = []
                let last_6months_SignupName = []
                newStats.last_six_months_signups.length > 0 && newStats.last_six_months_signups.map(signup => {
                    last_6months_SignupCount.push(signup.count)
                    last_6months_SignupName.push(signup._id)
                })

                // last 12 months signups
                let last_12months_SignupCount = []
                let last_12months_SignupName = []
                newStats.last_twelve_months_signups.length > 0 && newStats.last_twelve_months_signups.map(signup => {
                    last_12months_SignupCount.push(signup.count)
                    last_12months_SignupName.push(signup._id)
                })


                // last 30 days age data
                let newAgeCount = []
                let newAgeName = []
                newStats.newUsersAges.length > 0 && newStats.newUsersAges.map(age => {
                    newAgeCount.push(age.count)
                    newAgeName.push(age._id)
                })

                // last 3 months age data
                let last_3months_AgeCount = []
                let last_3months_AgeName = []
                newStats.last_threeMonths_UsersAges.length > 0 && newStats.last_threeMonths_UsersAges.map(age => {
                    last_3months_AgeCount.push(age.count)
                    last_3months_AgeName.push(age._id)
                })

                // last 6 months age data
                let last_6months_AgeCount = []
                let last_6months_AgeName = []
                newStats.last_sixMonths_UsersAges.length > 0 && newStats.last_sixMonths_UsersAges.map(age => {
                    last_6months_AgeCount.push(age.count)
                    last_6months_AgeName.push(age._id)
                })

                // last 12 months age data
                let last_12months_AgeCount = []
                let last_12months_AgeName = []
                newStats.last_twelveMonths_UsersAges.length > 0 && newStats.last_twelveMonths_UsersAges.map(age => {
                    last_12months_AgeCount.push(age.count)
                    last_12months_AgeName.push(age._id)
                })




                setNewCountryStats({
                    options: {
                        labels: newCountryName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: newCountryCount,
                })

                set_last_3months_CountryStats({
                    options: {
                        labels: last_3months_CountryName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_3months_CountryCount,
                })
                set_last_6months_CountryStats({
                    options: {
                        labels: last_6months_CountryName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_6months_CountryCount,
                })
                set_last_12months_CountryStats({
                    options: {
                        labels: last_12months_CountryName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_12months_CountryCount,
                })
                setNewAgeStats({
                    options: {
                        labels: newAgeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: newAgeCount,
                })

                set_last_3months_AgeStats({
                    options: {
                        labels: last_3months_AgeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_3months_AgeCount,
                })

                set_last_6months_AgeStats({
                    options: {
                        labels: last_6months_AgeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_6months_AgeCount,
                })
                set_last_12months_AgeStats({
                    options: {
                        labels: last_12months_AgeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_12months_AgeCount,
                })


                setNewCityStats({
                    options: {
                        labels: newCityName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: newCityCount,
                })
                set_last_3months_CityStats({
                    options: {
                        labels: last_3months_CityName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_3months_CityCount,
                })
                set_last_6months_CityStats({
                    options: {
                        labels: last_6months_CityName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_6months_CityCount,
                })
                set_last_12months_CityStats({
                    options: {
                        labels: last_12months_CityName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_12months_CityCount,
                })
                setNewGenderStats({
                    options: {
                        labels: newGenderName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: newGenderCount,
                })
                set_last_3months_GenderStats({
                    options: {
                        labels: last_3months_GenderName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_3months_GenderCount,
                })

                set_last_6months_GenderStats({
                    options: {
                        labels: last_6months_GenderName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_6months_GenderCount,
                })

                set_last_6months_GenderStats({
                    options: {
                        labels: last_6months_GenderName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_6months_GenderCount,
                })
                set_last_12months_GenderStats({
                    options: {
                        labels: last_12months_GenderName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_12months_GenderCount,
                })
                setNewIncomeRangeStats({
                    options: {
                        labels: newIncomeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: newIncomeCount,
                })

                set_last_3months_IncomeStats({
                    options: {
                        labels: last_3months_IncomeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_3months_IncomeCount,
                })
                set_last_6months_IncomeStats({
                    options: {
                        labels: last_6months_IncomeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_6months_IncomeCount,
                })
                set_last_12months_IncomeStats({
                    options: {
                        labels: last_12months_IncomeName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_12months_IncomeCount,
                })

                // last 30 days
                setNewSignupsStats({

                    options: {
                        labels: newSignupName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: newSignupCount,
                })

                // last 3 months
                set_last3months_SignupsStats({


                    options: {
                        labels: last_3months_SignupName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_3months_SignupCount,
                })

                // last 6 months
                set_last6months_SignupsStats({

                    options: {
                        labels: last_6months_SignupName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_6months_SignupCount,
                })

                // last 12 months
                set_last12months_SignupsStats({

                    options: {
                        labels: last_12months_SignupName,
                        dataLabels: {
                            enabled: true
                        },
                        legend: {
                            position: 'left'
                        },
                        colors: colors

                    },
                    series: last_12months_SignupCount,
                })


                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)

            })
    }

    // Searching
    const [searchString, setSearchString] = useState('')
    const [searchedProject, setSearchedProjectResult] = useState([])

    // Project searching
    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredProject = activeProjects.filter(row => {
            return (
                search.test(row.title) ||
                search.test(row.type) ||
                search.test(row.company) ||
                search.test(row.status) ||
                search.test(row.code)
            );
        });
        setSearchedProjectResult(filteredProject);
    }, [searchString]);


    const [employmentSeniorityToggle, setEmploymentSeniorityToggle] = useState('Employment')
    const [cityCountryToggle, setCityCountryToggle] = useState('City')
    const [demographicsActiveTab, setDemographicsActiveTab] = useState('Marital')

    const [newDemographicsActiveTab, setNewDemographicsActiveTab] = useState('Country')

    const [toggleTab, set_ToggleTab] = useState('Past 30 days')

    const changeToggle = (data) => {
        setEmploymentSeniorityToggle(data)
    }



    const toggle = (tab) => {
        if (demographicsActiveTab !== tab) {
            setDemographicsActiveTab(tab)
        }
    }

    const newToggle = (tab) => {
        if (newDemographicsActiveTab !== tab) {
            setNewDemographicsActiveTab(tab)
        }
    }

    const signupToggle = (tab) => {
        set_ToggleTab(tab)
    }

    const employmentSeniorityChart = (data = data) => {
        if (employmentSeniorityToggle === 'Employment') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <CardBody className="p-0">
                        <div className="donut">
                            <Chart options={employmentStats.options} series={employmentStats.series} type="donut" width="460" />
                        </div>
                    </CardBody>
                </Card>


            )
        } else if (employmentSeniorityToggle === 'Occupational Rank') {
            return (
                <div id="seniority-chart">
                    <ReactApexChart options={seniorityStats.options} series={seniorityStats.series} type="pie" width={460} />
                </div>
            )
        }
    }

    const demographicsChart = () => {
        if (demographicsActiveTab === 'Marital') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <CardBody className="p-0">
                        <div className="donut">
                            <Chart options={maritalStats.options} series={maritalStats.series} type="donut" width="380" />
                        </div>
                    </CardBody>
                </Card>
            )
        } else if (demographicsActiveTab === 'Gender') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <CardBody className="p-0">
                        <div className="bar">
                            <Chart options={ageGenderStats.options} series={ageGenderStats.series} type="bar" height="230" />
                        </div>
                    </CardBody>
                </Card>
            )
        } else if (demographicsActiveTab === 'Income') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <CardBody className="p-0">
                        <div id="chart">
                            <Chart options={incomeStats.options} series={incomeStats.series} type="polarArea" height="480" />
                        </div>
                    </CardBody>
                </Card>

            )
        }
    }

    // last 30 days demographics
    const newDemographicsChart = () => {
        if (newDemographicsActiveTab === 'Country') {

            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret size="sm" className="btn-icon btn-icon-only " color="dark" >
                                <div className="btn-icon-wrapper">
                                    <FontAwesomeIcon icon={faBars} />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                <DropdownItem onClick={() => signupToggle('Past 30 days')}>
                                    <span className="opacity-6">Past 30 days</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 3 months')}>
                                    <span className="opacity-6">Past 3 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 6 months')}>
                                    <span className="opacity-6">Past 6 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 12 months')}>
                                    <span className="opacity-6">Past 12 months</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>

                    </div>
                    <CardBody className="p-0">
                        {toggleTab === 'Past 30 days' &&
                            <div className="donut">

                                <Chart options={newCountryStats.options} series={newCountryStats.series} type="donut" height="300" />
                            </div>
                        }
                        {toggleTab === 'Past 3 months' &&
                            <div className="donut">

                                <Chart options={last_3months_CountryStats.options} series={last_3months_CountryStats.series} type="donut" height="300" />
                            </div>
                        }
                        {toggleTab === 'Past 6 months' &&
                            <div className="donut">

                                <Chart options={last_6months_CountryStats.options} series={last_6months_CountryStats.series} type="donut" height="300" />
                            </div>
                        }
                        {toggleTab === 'Past 12 months' &&
                            <div className="donut">

                                <Chart options={last_12months_CountryStats.options} series={last_12months_CountryStats.series} type="donut" height="300" />
                            </div>
                        }
                    </CardBody>
                </Card>
            )
        } else if (newDemographicsActiveTab === 'City') {

            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret size="sm" className="btn-icon btn-icon-only " color="dark" >
                                <div className="btn-icon-wrapper">
                                    <FontAwesomeIcon icon={faBars} />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                <DropdownItem onClick={() => signupToggle('Past 30 days')}>
                                    <span className="opacity-6">Past 30 days</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 3 months')}>
                                    <span className="opacity-6">Past 3 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 6 months')}>
                                    <span className="opacity-6">Past 6 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 12 months')}>
                                    <span className="opacity-6">Past 12 months</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>

                    </div>
                    <CardBody className="p-0">
                        {toggleTab === 'Past 30 days' &&
                            <div className="pie">

                                <Chart options={newCityStats.options} series={newCityStats.series} type="pie" height="400" />
                            </div>
                        }
                        {toggleTab === 'Past 3 months' &&
                            <div className="pie">

                                <Chart options={last_3months_CityStats.options} series={last_3months_CityStats.series} type="pie" height="400" />
                            </div>
                        }
                        {toggleTab === 'Past 6 months' &&
                            <div className="pie">

                                <Chart options={last_6months_CityStats.options} series={last_6months_CityStats.series} type="pie" height="400" />
                            </div>
                        }
                        {toggleTab === 'Past 12 months' &&
                            <div className="pie">

                                <Chart options={last_12months_CityStats.options} series={last_12months_CityStats.series} type="pie" height="400" />
                            </div>
                        }
                    </CardBody>
                </Card>
            )
        } else if (newDemographicsActiveTab === 'Gender') {

            return (

                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret size="sm" className="btn-icon btn-icon-only " color="dark" >
                                <div className="btn-icon-wrapper">
                                    <FontAwesomeIcon icon={faBars} />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                <DropdownItem onClick={() => signupToggle('Past 30 days')}>
                                    <span className="opacity-6">Past 30 days</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 3 months')}>
                                    <span className="opacity-6">Past 3 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 6 months')}>
                                    <span className="opacity-6">Past 6 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 12 months')}>
                                    <span className="opacity-6">Past 12 months</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>

                    </div>
                    <CardBody className="p-0">
                        {toggleTab === 'Past 30 days' &&
                            <div div id="donut">
                                <Chart options={newGenderStats.options} series={newGenderStats.series} type="donut" height="200" />
                            </div>
                        }
                        {toggleTab === 'Past 3 months' &&
                            <div div id="donut">
                                <Chart options={last_3months_GenderStats.options} series={last_3months_GenderStats.series} type="donut" height="200" />
                            </div>
                        }
                        {toggleTab === 'Past 6 months' &&
                            <div div id="donut">
                                <Chart options={last_6months_GenderStats.options} series={last_6months_GenderStats.series} type="donut" height="200" />
                            </div>
                        }
                        {toggleTab === 'Past 12 months' &&
                            <div div id="donut">
                                <Chart options={last_12months_GenderStats.options} series={last_12months_GenderStats.series} type="donut" height="200" />
                            </div>
                        }
                    </CardBody>
                </Card >

            )
        }
        else if (newDemographicsActiveTab === 'Income Range') {

            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret size="sm" className="btn-icon btn-icon-only " color="dark" >
                                <div className="btn-icon-wrapper">
                                    <FontAwesomeIcon icon={faBars} />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                <DropdownItem onClick={() => signupToggle('Past 30 days')}>
                                    <span className="opacity-6">Past 30 days</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 3 months')}>
                                    <span className="opacity-6">Past 3 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 6 months')}>
                                    <span className="opacity-6">Past 6 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 12 months')}>
                                    <span className="opacity-6">Past 12 months</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>

                    </div>
                    <CardBody className="p-0">
                        {toggleTab === 'Past 30 days' &&
                            <div id="pie">

                                <Chart options={newIncomeRangeStats.options} series={newIncomeRangeStats.series} type="pie" height="400" />
                            </div>
                        }
                        {toggleTab === 'Past 3 months' &&
                            <div id="pie">

                                <Chart options={last_3months_IncomeStats.options} series={last_3months_IncomeStats.series} type="pie" height="400" />
                            </div>
                        }
                        {toggleTab === 'Past 6 months' &&
                            <div id="pie">

                                <Chart options={last_6months_IncomeStats.options} series={last_6months_IncomeStats.series} type="pie" height="400" />
                            </div>
                        }
                        {toggleTab === 'Past 12 months' &&
                            <div id="pie">

                                <Chart options={last_12months_IncomeStats.options} series={last_12months_IncomeStats.series} type="pie" height="400" />
                            </div>
                        }
                    </CardBody>
                </Card>

            )
        }
        else if (newDemographicsActiveTab === 'Age Range') {

            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>
                    <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret size="sm" className="btn-icon btn-icon-only " color="dark" >
                                <div className="btn-icon-wrapper">
                                    <FontAwesomeIcon icon={faBars} />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                <DropdownItem onClick={() => signupToggle('Past 30 days')}>
                                    <span className="opacity-6">Past 30 days</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 3 months')}>
                                    <span className="opacity-6">Past 3 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 6 months')}>
                                    <span className="opacity-6">Past 6 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 12 months')}>
                                    <span className="opacity-6">Past 12 months</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>

                    </div>
                    <CardBody className="p-0">

                        {toggleTab === 'Past 30 days' &&
                            <div id="pie">
                                <Chart options={newAgeStats.options} series={newAgeStats.series} type="donut" height="300" />
                            </div>}
                        {toggleTab === 'Past 3 months' &&
                            <div id="pie">
                                <Chart options={last_3months_AgeStats.options} series={last_3months_AgeStats.series} type="donut" height="300" />
                            </div>}
                        {toggleTab === 'Past 6 months' &&
                            <div id="pie">
                                <Chart options={last_6months_AgeStats.options} series={last_6months_AgeStats.series} type="donut" height="300" />
                            </div>}
                        {toggleTab === 'Past 12 months' &&
                            <div id="pie">
                                <Chart options={last_12months_AgeStats.options} series={last_12months_AgeStats.series} type="donut" height="300" />
                            </div>}
                    </CardBody>
                </Card>

            )
        }

        else if (newDemographicsActiveTab === 'Signup') {
            return (
                <Card style={{ boxShadow: 'none', border: '0' }}>

                    <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret size="sm" className="btn-icon btn-icon-only " color="dark" >
                                <div className="btn-icon-wrapper">
                                    <FontAwesomeIcon icon={faBars} />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                <DropdownItem onClick={() => signupToggle('Past 30 days')}>
                                    <span className="opacity-6">Past 30 days</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 3 months')}>
                                    <span className="opacity-6">Past 3 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 6 months')}>
                                    <span className="opacity-6">Past 6 months</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => signupToggle('Past 12 months')}>
                                    <span className="opacity-6">Past 12 months</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>

                    </div>
                    <CardBody className="p-0">


                        {toggleTab === 'Past 30 days' &&
                            <div id="pie">
                                <Chart options={newSignupsStats.options} series={newSignupsStats.series} type="pie" height="300" />
                            </div>
                        }

                        {toggleTab === 'Past 3 months' &&
                            <div id="pie">

                                <Chart options={last3months_SignupsStats.options} series={last3months_SignupsStats.series} type="pie" height="300" />
                            </div>
                        }
                        {toggleTab === 'Past 6 months' &&
                            <div id="pie">

                                <Chart options={last6months_SignupsStats.options} series={last6months_SignupsStats.series} type="pie" height="300" />
                            </div>
                        }
                        {toggleTab === 'Past 12 months' &&
                            <div id="pie">

                                <Chart options={last12months_SignupsStats.options} series={last12months_SignupsStats.series} type="pie" height="300" />
                            </div>
                        }
                    </CardBody>
                </Card>

            )
        }
    }
    const cityChart = () => {
        if (cityCountryToggle === 'Country') {

            return (
                <div className="bar">
                    <Chart options={countryStats.options} series={countryStats.series} type="bar" height="350" />
                </div>

            )
        } else if (cityCountryToggle === 'City') {
            return (
                <div className="bar">
                    <Chart options={cityStats.options} series={cityStats.series} type="bar" height="750" />
                </div>
            )
        }
    }

    // Top cards settings and their breakpoints
    let settings = {
        centerMode: false,
        infinite: true,
        speed: 500,
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 4,
        swipeToSlide: true,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
        ],
    };

    // a month before date to show on new user card
    let todaysDate = new Date()
    let prior = new Date().setDate(todaysDate.getDate() - 30)
    let newUserDate = new Date(prior).toLocaleDateString()

    const chartData = {
        options: {
            chart: {
                id: 'vertical-bar-chart',
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: userMonthStats,
            },
        },

        series: [
            {
                name: 'New Users',
                data: userStats,
            },
        ],
    };


    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>

                    <div className="admin_header">
                        <div className="page_header">
                            <div className="app-page-title">
                                <div className="page-title-wrapper">
                                    <div className="page-title-heading">
                                        <div className="page-title-icon">
                                            <img src={DashboardIcon} alt="icon" />
                                        </div>
                                        <div>
                                            Dashboard
                                            <div className="page-title-subheading">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-title-actions">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">

                        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
                            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>


                            {/* Cards */}

                            <div className=" custom_slider">
                                <div className={`slick-slider-lg pb-3`} style={{ width: "100%" }}>
                                    <Slider {...settings}>
                                        <div >
                                            <div className={` ${styles.gradient_grbl} card  widget-content`} style={{ boxShadow: 'none', border: '0', marginRight: '10px', height: '90px' }}>
                                                <div className="widget-content-wrapper text-white">
                                                    <div className="widget-content-left">
                                                        <div className={`${styles.card_title} widget-heading font-size-lg`}>Projects</div>
                                                        <div className={`${styles.card_text} widget-subheading font-size-md`}>Total Projects in System</div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-white">
                                                            <CountUp start={0} end={dashboardDetails.totalProjects ? dashboardDetails.totalProjects : 0} separator="" decimals={0} decimal="," prefix="" suffix="" duration="15" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div >
                                            <div className={` ${styles.gradient_grbl} card  widget-content`} style={{ boxShadow: 'none', border: '0', marginRight: '10px', height: '90px' }}>
                                                <div className="widget-content-wrapper text-white">
                                                    <div className="widget-content-left">
                                                        <div className={`${styles.card_title} widget-heading font-size-lg`}>Users</div>
                                                        <div className={`${styles.card_text} widget-subheading font-size-md`}>Total Users in System</div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-white">
                                                            <CountUp start={0} end={dashboardDetails.totalUsers ? dashboardDetails.totalUsers : 0} separator="" decimals={0} decimal="," prefix="" suffix="" duration="15" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div >
                                            <div className={` ${styles.gradient_grbl} card  widget-content`} style={{ boxShadow: 'none', border: '0', marginRight: '10px', height: '90px' }}>
                                                <div className="widget-content-wrapper text-white">
                                                    <div className="widget-content-left">
                                                        <div className={`${styles.card_title} widget-heading font-size-lg`}>New Users</div>
                                                        <div className={`${styles.card_text} widget-subheading font-size-md`}>New Users from {newUserDate}</div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-white">
                                                            <CountUp start={0} end={dashboardDetails.totalNewUsers ? dashboardDetails.totalNewUsers : 0} separator="" decimals={0} decimal="," prefix="" suffix="" duration="15" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div >
                                            <div className={` ${styles.gradient_grbl} card  widget-content`} style={{ boxShadow: 'none', border: '0', marginRight: '10px', height: '90px' }}>
                                                <div className="widget-content-wrapper text-white">
                                                    <div className="widget-content-left">
                                                        <div className={`${styles.card_title} widget-heading font-size-lg`}>Conversions</div>
                                                        <div className={`${styles.card_text} widget-subheading font-size-md`}>Conversions in System</div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-white">
                                                            <CountUp start={0} end={dashboardDetails.conversionsPercentage ? dashboardDetails.conversionsPercentage : 0} separator="" decimals={0.62} decimal="," prefix="" suffix="%" duration="15" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>

                            {/* Project Listing */}

                            <Card className="main-card mb-3">
                                <nav className="navbar navbar-expand-sm navbar-light">
                                    <div className="text-white menu-header-title text-capitalize text-muted ml-0" style={{ fontWeight: 500 }}>Active Projects and Quick Surveys</div>
                                    <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                        <ul className="navbar-nav mr-auto">
                                        </ul>
                                        <form className="form-inline my-2 my-lg-0" style={{ marginRight: '-2rem' }}>
                                            <div className="input-group input-group-sm w-75">
                                                <div className="input-group-prepend border-0">
                                                    <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                </div>
                                                <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }}
                                                    onChange={(e) => setSearchString(e.target.value)} />
                                            </div>
                                        </form>
                                    </div>
                                </nav>

                                <LoadingOverlay tag="div" active={isLoading}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: "#fff",
                                            opacity: 0.5,
                                        }),
                                    }}
                                    spinner={
                                        <Loader color="#6c757d" active type="ball-pulse" />
                                    }>
                                    <CardBody className="p-0">


                                        {/* Listing */}
                                        <div className="table_border">
                                            <Listing columnName={projectCol}
                                                // data={(searchString.length > 0 || searchedProject.length > 0) ? searchedProject : activeProjects}
                                                // data={activeActivities && activeActivities.concat(activeProjects && activeProjects)}
                                                data={activeActivities.length > 0 || activeProjects.length >0 ? activeActivities && activeActivities.concat(activeProjects && activeProjects): <p>No activivty</p>}
                                                currentPage={dashboardState && dashboardState.currentPage ? dashboardState.currentPage : 1}
                                                totalPages={dashboardState && dashboardState.totalPages ? dashboardState.totalPages : 1}
                                                getListingData={(currentPage, dataLimit) => getActiveProjectsListing(currentPage, dataLimit)}
                                                startLoading={() => setLoading(true)}
                                            />
                                        </div>

                                    </CardBody>
                                </LoadingOverlay>

                            </Card>

                            {/* Charts */}


                            <div className="row mb-4 pb-2">
                                <div className="col-lg-6 col-md-12">
                                    <Card style={{ boxShadow: "none" }} >

                                        <CardHeader className="d-flex">
                                            <div>
                                                <h5 className="opacity-6 text-capitalize">
                                                    {employmentSeniorityToggle}
                                                </h5>
                                            </div>
                                            <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                                                <UncontrolledButtonDropdown>

                                                    <DropdownToggle caret size="sm" className="btn-icon btn-icon-only " color="dark" >
                                                        <div className="btn-icon-wrapper">
                                                            <FontAwesomeIcon icon={faBars} />
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                                        <DropdownItem onClick={() => changeToggle('Employment')}>
                                                            <span className="opacity-6">Employment</span>
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => changeToggle('Occupational Rank')}>
                                                            <span className="opacity-6">Occupational Rank</span>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            {employmentSeniorityChart()}
                                        </CardBody>
                                    </Card>
                                </div>


                                <div className={`col-lg-6 col-md-12`}>
                                    <Card className="main-card" >
                                        <CardHeader className="d-flex">
                                            <h5 className="opacity-6 text-capitalize">
                                                {demographicsActiveTab}
                                            </h5>
                                            {
                                                width <= 350 ?
                                                    <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                                                        <UncontrolledButtonDropdown>

                                                            <DropdownToggle caret size="sm" className="btn-icon btn-icon-only" color="dark">
                                                                <div className="btn-icon-wrapper">
                                                                    <FontAwesomeIcon icon={faBars} />
                                                                </div>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                                                <DropdownItem onClick={() => toggle("Marital")}>
                                                                    <span className="opacity-6">Marital</span>
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => toggle("Gender")}>
                                                                    <span className="opacity-6">Gender</span>
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => toggle("Income")}>
                                                                    <span className="opacity-6">Income</span>
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                    :
                                                    <div className="btn-actions-pane-right">
                                                        <ButtonGroup size="sm">
                                                            <Button caret="true" color="dark"
                                                                className={
                                                                    "btn-shadow " +
                                                                    classnames({
                                                                        active: demographicsActiveTab === "2",
                                                                    })
                                                                }
                                                                onClick={() => {
                                                                    toggle("Marital");
                                                                }}>
                                                                Marital
                                                            </Button>
                                                            <Button color="dark"
                                                                className={
                                                                    "btn-shadow " +
                                                                    classnames({
                                                                        active: demographicsActiveTab === "3",
                                                                    })
                                                                }
                                                                onClick={() => {
                                                                    toggle("Gender");
                                                                }}>
                                                                Gender
                                                            </Button>
                                                            <Button color="dark"
                                                                className={
                                                                    "btn-shadow " +
                                                                    classnames({
                                                                        active: demographicsActiveTab === "4",
                                                                    })
                                                                }
                                                                onClick={() => {
                                                                    toggle("Income");
                                                                }}>
                                                                Income
                                                            </Button>
                                                        </ButtonGroup>
                                                    </div>


                                            }


                                        </CardHeader>
                                        <CardBody>
                                            {demographicsChart()}
                                        </CardBody>
                                    </Card>
                                </div>

                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <Card className={`${styles.card_chart} mb-3`} style={{ boxShadow: "none" }}>
                                        <CardHeader className="d-flex">
                                            <div>
                                                <h5 className="opacity-6 text-capitalize">
                                                    {cityCountryToggle}
                                                </h5>
                                            </div>
                                            <div className="btn-actions-pane-right pt-0 custom_drop">
                                                <UncontrolledButtonDropdown>

                                                    <DropdownToggle caret size="sm" className="btn-icon btn-icon-only" color="dark">
                                                        <div className="btn-icon-wrapper">
                                                            <FontAwesomeIcon icon={faBars} />
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                                        <DropdownItem>
                                                            <span onClick={() => setCityCountryToggle('City')} className="opacity-6">City</span>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <span onClick={() => setCityCountryToggle('Country')} className="opacity-6">Country</span>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            {cityChart()}
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <Card className={`${styles.card_chart} mb-3 mt-3`} style={{ boxShadow: "none" }}>
                                        <CardHeader className='d-flex'>
                                            <div>
                                                <h5 className="opacity-6 text-capitalize">
                                                    New members within the past six months
                                                </h5>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='vertical-bar-chart'>
                                                <ReactApexChart
                                                    options={chartData.options}
                                                    series={chartData.series}
                                                    type="bar"
                                                    height={350}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            {/* last 30 days users demographics */}
                            <div style={{ paddingTop: '50px' }}>
                                <div className='row' >
                                    <div className={`col-12`} >
                                        <Card className="main-card">
                                            <CardHeader className="d-flex">
                                                <h5 className="opacity-6 text-capitalize">
                                                    {`Demographics in ${toggleTab}`}
                                                </h5>
                                                {
                                                    width <= 350 ?
                                                        <div className="btn-actions-pane-right align-self-center pt-0 custom_drop">
                                                            <UncontrolledButtonDropdown>

                                                                <DropdownToggle caret size="sm" className="btn-icon btn-icon-only" color="dark">
                                                                    <div className="btn-icon-wrapper">
                                                                        <FontAwesomeIcon icon={faBars} />
                                                                    </div>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                                                    <DropdownItem onClick={() => newToggle("Country")}>
                                                                        <span className="opacity-6">Country</span>
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => newToggle("City")}>
                                                                        <span className="opacity-6">City</span>
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => newToggle("Gender")}>
                                                                        <span className="opacity-6">Gender</span>
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => newToggle("Income Range")}>
                                                                        <span className="opacity-6">Income Range</span>
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => newToggle("Signup")}>
                                                                        <span className="opacity-6">Signup</span>
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => newToggle("Age Range")}>
                                                                        <span className="opacity-6">Age</span>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </div>
                                                        :
                                                        <div className="btn-actions-pane-right">
                                                            <ButtonGroup size="sm">
                                                                <Button caret="true" color="dark"
                                                                    className={
                                                                        "btn-shadow " +
                                                                        classnames({
                                                                            active: newDemographicsActiveTab === "2",
                                                                        })
                                                                    }
                                                                    onClick={() => {
                                                                        newToggle("Country");
                                                                    }}>
                                                                    Country
                                                                </Button>
                                                                <Button color="dark"
                                                                    className={
                                                                        "btn-shadow " +
                                                                        classnames({
                                                                            active: newDemographicsActiveTab === "3",
                                                                        })
                                                                    }
                                                                    onClick={() => {
                                                                        newToggle("City");
                                                                    }}>
                                                                    City
                                                                </Button>
                                                                <Button color="dark"
                                                                    className={
                                                                        "btn-shadow " +
                                                                        classnames({
                                                                            active: newDemographicsActiveTab === "4",
                                                                        })
                                                                    }
                                                                    onClick={() => {
                                                                        newToggle("Gender");
                                                                    }}>
                                                                    Gender
                                                                </Button>
                                                                <Button color="dark"
                                                                    className={
                                                                        "btn-shadow " +
                                                                        classnames({
                                                                            active: newDemographicsActiveTab === "5",
                                                                        })
                                                                    }
                                                                    onClick={() => {
                                                                        newToggle("Income Range");
                                                                    }}>
                                                                    Income
                                                                </Button>
                                                                <Button color="dark"
                                                                    className={
                                                                        "btn-shadow " +
                                                                        classnames({
                                                                            active: newDemographicsActiveTab === "6",
                                                                        })
                                                                    }
                                                                    onClick={() => {
                                                                        newToggle("Signup");
                                                                    }}>
                                                                    Signup
                                                                </Button>
                                                                <Button color="dark"
                                                                    className={
                                                                        "btn-shadow " +
                                                                        classnames({
                                                                            active: newDemographicsActiveTab === "6",
                                                                        })
                                                                    }
                                                                    onClick={() => {
                                                                        newToggle("Age Range");
                                                                    }}>
                                                                    Age
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>


                                                }


                                            </CardHeader>
                                            <CardBody>
                                                {newDemographicsChart()}
                                            </CardBody>
                                        </Card>
                                    </div>

                                </div>
                            </div>
                        </TransitionGroup>

                    </div >

                </>
            )}
        />

    )
}

export default Dashboard