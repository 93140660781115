import React from "react";
import ResizeDetector from "react-resize-detector";
import { Button } from "reactstrap";
import { IoMdContact } from "react-icons/io";
import { FaMagnifyingGlassChart } from "react-icons/fa6";
import { MdWorkOutline } from "react-icons/md";
import { RxHobbyKnife } from "react-icons/rx"
import styles from '../../../assets/css/styling.module.css';
import { FaRegStar } from "react-icons/fa";
const getNavStates = (indx, length) => {
    let styles = [];
    for (let i = 0; i < length; i++) {
        if (i < indx) {
            styles.push("done");
        } else if (i === indx) {
            styles.push("doing");
        } else {
             styles.push("todo");
        }
    }
    return { current: indx, styles: styles };
};

const checkNavState = (currentStep, stepsLength) => {
    if (currentStep > 0 && currentStep < stepsLength - 1) {
        return {
            showPreviousBtn: true,
            showNextBtn: true,
        };
    } else if (currentStep === 0) {
        return {
            showPreviousBtn: false,
            showNextBtn: true,
        };
    } else {
        return {
            showPreviousBtn: true,
            showNextBtn: false,
        };
    }
};

export default class Profile_Steps extends React.Component {
    state = {
        showPreviousBtn: false,
        showNextBtn: true,
        compState: 0,
        navState: getNavStates(0, this.props.steps.length),
     
    };

    setNavState = (next) => {
        this.setState({
            navState: getNavStates(next, this.props.steps.length),
        });
        if (next < this.props.steps.length) {
            this.setState({ compState: next });
        }
        this.setState(checkNavState(next, this.props.steps.length));
        
    };

  

    handleKeyDown = (evt) => {
        if (evt.which === 13) {
            this.next();
        }
    };

    handleOnClick = (evt) => {
       

    
        if (
            evt.currentTarget.value === this.props.steps.length - 1 &&
            this.state.compState === this.props.steps.length - 1
        ) {
            this.setNavState(this.props.steps.length);
        } else {
            this.setNavState(evt.currentTarget.value);
        }
    };

    next = () => {
        setTimeout(() => {
            this.setNavState(this.state.compState + 1);
        }, 2000);
      
    };

    previous = () => {
        if (this.state.compState > 0) {
            setTimeout(()=>{
            this.setNavState(this.state.compState - 1);
        }, 2000)
        }
    };

    getClassName = (className, i) => {
        return className + "-" + this.state.navState.styles[i];
    };

    renderSteps = () => {
       
        return this.props.steps.map((s, i) => (
            
          
            <li
                className={this.getClassName("form-wizard-step", i)}
                onClick={this.handleOnClick}
                key={i}
                value={i}
                
               
            >
                <em>{ s.icon}</em>
                
                
              
              
                <span style={{color:'#000'}}>{window.innerWidth <768 ? '': this.props.steps[i].name}</span>
              
            </li>
            
        ));
    };

    render() {
        return (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <div className="_profile_steps_">

                        {/* Steps Strip */}
                        <div className={width > 440 ? "mx-5 px-5" : "mx-2"}>
                            <ol className="forms-wizard">{this.renderSteps()}</ol>
                        </div>

                        {/* Steps Content */}
                        {this.props.steps[this.state.compState].component}

                        {/* Buttons Prev and Next */}
                        {this.props.showNavigation && (
                            <div className="clearfix pt-3">
                                {this.state.showPreviousBtn && (
                                    <Button className={"float-left btn-hover-shine px-4"} style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }}  onClick={this.previous}>
                                        Previous
                                    </Button>
                                )}
                                {this.state.showNextBtn && (
                                    <Button className={"float-right btn-hover-shine"} style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }}  onClick={this.next}>
                                        Next
                                    </Button>
                                )}
                            </div>
                        )}

                    </div>
                )}
            />
        );
    }
}

Profile_Steps.defaultProps = {
    showNavigation: true,
};
