import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styles from '../../../assets/css/styling.module.css';

import { TransitionGroup } from 'react-transition-group';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

import Survey_Steps from './Survey_Steps';

import Question_With_Radio from '../../../assets/components/survey-questions/Question_With_Radio';
import Question_With_Checkbox from '../../../assets/components/survey-questions/Question_With_Checkbox';
import Question_With_LongText from '../../../assets/components/survey-questions/Question_With_LongText';
import Question_With_Image from '../../../assets/components/survey-questions/Question_With_Image';
import Question_With_Image_Text from '../../../assets/components/survey-questions/Question_With_Image_Text';
import Question_With_Video from '../../../assets/components/survey-questions/Question_With_Video';
import Question_With_Video_Text from '../../../assets/components/survey-questions/Question_With_Video_Text';
import Question_With_Grid from '../../../assets/components/survey-questions/Question_With_Grid';
import Question_With_Spectrum from '../../../assets/components/survey-questions/Question_With_Spectrum';
import Question_With_Slider from '../../../assets/components/survey-questions/Question_With_Slider';
import { getRespondentActivityDetails, getActivityImg, activityQuestionsCategories } from '../../../actions/activities_actions';
import Back from '../../../assets/components/back/Back';
import { createSurveyReply, getSurveyReplies, calculate_sec } from '../../../actions/activities_reply_actions';
import SecureLS from 'secure-ls';
import CryptoJS from 'crypto-js';


const Survey = (props) => {

    const params = useParams();
    const dispatch = useDispatch()

    const ls = new SecureLS()
    const UserId = ls.get('accessUser').user._id

    const history = useNavigate()
    const [steps, setSteps] = useState([]);
    const [currentPage, setCurrentPage] = useState();

    const [startTime, setStartTime] = useState(null);
    const [duration, setDuration] = useState(0);

    const [surveyContent, set_surveyContent] = useState({});
    const [surveyDetails, setSurveyDetails] = useState({})
    const [isLoading, setLoading] = useState(true)
    const [bannerImg, setBannerImg] = useState({})

    const [sec_question, set_questions_array] = useState([]);
    const [sec_responded_questions, set_responded_questions_array] = useState([]);
    const [sec_responded_options, set_sec_responded_options] = useState([])

    // Check is required question answered
    const isAnswered = (question, pageNo) => {

        if (currentPage === (pageNo + 1)) {

            if (question.questionType === "radio" && question.selected && question.selected !== '')
                return true;

            else if (question.questionType === "checkbox" && question.selected && question.selected.length > 0)
                return true;

            else if (question.questionType === "grid" && question.selected && question.selected.length === question.rows.length)
                return true;

            else if (question.questionType === "long text" && question.answer && question.answer !== '')
                return true;

            else if (question.questionType === "image" && question.image && question.image.length > 0)
                return true;

            else if (question.questionType === "video" && question.video && question.video.length > 0)
                return true;

            else if (question.questionType === "image text" && question.image && (question.image.length > 0 && question.answer !== ''))
                return true;

            else if (question.questionType === "video text" && question.video && (question.video.length > 0 && question.answer !== ''))
                return true;

            else if (question.questionType === "spectrum" && question.selected && question.selected !== '')
                return true;
            else if (question.questionType === "slider" && question.selected && question.selected !== '')
                return true;

            else
                return false;

        } else {

            return true;
        }
    }
const page = params.page

useEffect(() => {
    setStartTime(Date.now()); // Record the time when the component mounts
    return () => {
        // Cleanup function to stop the timer when the component unmounts
        if (startTime) {
            const endTime = Date.now();
            const surveyDuration = endTime - startTime;
            setDuration(duration + surveyDuration); // Add the current survey duration to the total
        }
    };
}, []);

let sec_questions = []
let responded_sec_questions = []

    useEffect(() => {
        setSteps([]);

        let quesNo = 0;

        surveyContent.pages && surveyContent.pages.map((page, i) => {

            let pageContent = <React.Fragment>

                {page.questions.map((question, index) => {

                    quesNo++;

                    switch (question.questionType) {

                        case 'radio':

                            return <Question_With_Radio
                                key={index}
                              id={'radio' + CryptoJS.lib.WordArray.random(5)}
                                name={page.pageName + Math.floor(Math.random() * 100) + (index + 1)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                sec_responded_questions={sec_responded_questions}
                                set_questions_array = {set_questions_array}
                                set_responded_questions_array = {set_responded_questions_array}
                                
                                sec_questions ={sec_questions}
                                // responded_sec_questions = {responded_sec_questions}
                                sec = {question.sec ? question.sec : false}
                                // sec_responded_options = {sec_responded_options}
                                // set_sec_responded_options = {set_sec_responded_options}

                                otherText={question.otherText}
                                isOtherAllowed={question.isOtherAllowed}
                                required={question.required}
                                questionId = {question._id}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />

                        case 'checkbox':

                            return <Question_With_Checkbox
                                key={index}
                                id={'check' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                otherText={question.otherText}
                                isOtherAllowed={question.isOtherAllowed}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />

                        case 'long text':

                            return <Question_With_LongText
                                key={index}
                                id={'longText' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                answer={question.answer}
                                textLimit={question.textLimit}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />

                        case 'image':

                            return <Question_With_Image
                                key={index}
                                id={'image' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                image={question.image}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                comment={question.comment}
                                isComment={question.isComment}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />

                        case 'image text':

                            return <Question_With_Image_Text
                                key={index}
                                id={'image-text' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                image={question.image}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                answer={question.answer}
                                textLimit={question.textLimit}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />

                        case 'video':

                            return <Question_With_Video
                                key={index}
                                id={'video' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                video={question.video}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                comment={question.comment}
                                isComment={question.isComment}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />

                        case 'video text':

                            return <Question_With_Video_Text
                                key={index}
                                id={'video-text' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                video={question.video}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                answer={question.answer}
                                textLimit={question.textLimit}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />

                        case 'grid':

                            return <Question_With_Grid
                                key={index}
                                id={'grid' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                rows={question.rows}
                                columns={question.columns}
                                selected={question.selected}
                                comment={question.comment}
                                isComment={question.isAdditionalComment}
                                uniqueSelected={question.isUniqueSelected}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />

                        case 'spectrum':

                            return <Question_With_Spectrum
                                key={index}
                                id={"spectrum" + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />
                            case 'slider':

                            return <Question_With_Slider
                                key={index}
                                id={"slider" + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={surveyContent}
                                setContent={set_surveyContent}
                                isAnswered={isAnswered(question, i)}
                            />
                        default:
                            break;
                    }

                })}

            </React.Fragment>

            // Set Total Page Steps
            setSteps(oldSteps => [
                ...oldSteps,
                {
                    name: page.pageName,
                    component: pageContent,  // change to pageContent.props.children[0] and upon click on next it should change children[0] to children[1] and so on 
                    percentage: parseInt(((i + 1) / surveyContent.pages.length) * 100),
                    currentPage,
                    setCurrentPage,
                    page
                }
                

            ]);
           
        });

    }, [surveyContent, currentPage]);


    let new_options_array = []
      
       surveyContent && surveyContent.pages && surveyContent.pages.map((page) => {
   
           page.questions.map((question) => {
               if (sec_question.includes(question._id)) {
                   if (!new_options_array.includes(question.selected)) {
   
   
                       new_options_array.push(question.selected)
                   }
               }
           })
       })
     
   
       const allSecQuestionsAnswered = sec_question.length > 0 &&
       sec_responded_questions.length > 0 &&
       sec_question.every(questionId => sec_responded_questions.includes(questionId));
   
   
       useEffect(()=>{
          
       
       if(allSecQuestionsAnswered === true){
           dispatch(calculate_sec(new_options_array, UserId))
           .then((response) =>{
       
           })
       }
       },[allSecQuestionsAnswered === true, new_options_array])



    const getReplies = () => {
        
        
        dispatch(getActivityImg(params.surveyid))
        .then((response) =>{
            try{
          
            setBannerImg(response.payload.content.fileData)}catch(err){console.log('img not available')}
            
        }).catch(err=>{
            console.log(err)
        })
        

        dispatch(getRespondentActivityDetails(params.surveyid))
            .then((response) => {
           // console.log('response->',response.payload.content.survey)
                if(response.payload.content.survey.scriptEnabled === true){
                    const surveytype = 'project-survey'
                    history(`/respondent/scripted-survey/${params.surveyid}/${surveytype}`)
                }
                else{
                setSurveyDetails(response.payload.content)
                set_surveyContent(response.payload.content.survey)
                setLoading(false)
                }

            })
        // dispatch(activityQuestionsCategories(params.surveyid))
        // .then(res=>console.log('res->',res.payload))
        //     .catch(err => {
        //         console.log(err)
        //         setLoading(false)
        //         Swal({
        //             title: err.response ? err.response.data.name : "Error",
        //             text: err.response ? err.response.data.content : "Something went wrong",
        //             icon: 'error'
        //         })
        //     })
    }

    useEffect(() => {
        getReplies()
    }, [])

    const onSubmit = () => {
        
        const endTime = Date.now();
        const surveyDuration = endTime - startTime;
        const totalDuration = duration + surveyDuration; // Calculate the total duration
        const minutes = Math.floor(totalDuration / 60000);
        const seconds = ((totalDuration % 60000) / 1000).toFixed(0);

        
        const LOI = `${minutes} min, ${seconds} sec`


        if (isAnswered()) {

        Swal({
            title: "Please wait!",
            text: "Saving data...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });

        const formData = new FormData()
        formData.append('activity', params.surveyid)
        formData.append('activityType', 'Survey')
        formData.append('loi', LOI)
        let questionCount = 0
        for (let i = 0; i < surveyContent.pages.length; i++) {
            for (let j = 0; j < surveyContent.pages[i].questions.length; j++) {
                questionCount++
                formData.append(`pages[${i}][questions][${questionCount}][questionId]`, surveyContent.pages[i].questions[j]._id)

                if (surveyContent.pages[i].questions[j].questionType === 'radio' || surveyContent.pages[i].questions[j].questionType === 'spectrum' || surveyContent.pages[i].questions[j].questionType === 'slider') 
                {
                    formData.append(`pages[${i}][questions][${questionCount}][selectedOptions][0]`, surveyContent.pages[i].questions[j].selected)
                    
                    

                } else if (surveyContent.pages[i].questions[j].questionType === 'checkbox') {
                    surveyContent.pages[i].questions[j].selected && surveyContent.pages[i].questions[j].selected.length > 0 &&
                        surveyContent.pages[i].questions[j].selected.map((value, index) => {
                            formData.append(`pages[${i}][questions][${questionCount}][selectedOptions][${index}]`, value)
                        })

                } else if (surveyContent.pages[i].questions[j].questionType === 'long text') {
                    formData.append(`pages[${i}][questions][${questionCount}][responseText]`, surveyContent.pages[i].questions[j].answer)

                } else if (surveyContent.pages[i].questions[j].questionType === 'image' && surveyContent.pages[i].questions[j].image) {
                    for (let k = 0; k < surveyContent.pages[i].questions[j].image.length; k++) {
                        
                        formData.append(`pages[${i}][questions][${questionCount}][responseFiles][${k}]`, surveyContent.pages[i].questions[j].image[k])

                     }

                } else if (surveyContent.pages[i].questions[j].questionType === 'video' && surveyContent.pages[i].questions[j].video) {
                    for (let k = 0; k < surveyContent.pages[i].questions[j].video.length; k++) {
                        formData.append(`pages[${i}][questions][${questionCount}][responseFiles][${k}]`, surveyContent.pages[i].questions[j].video[k])

                    }
                  

                } else if (surveyContent.pages[i].questions[j].questionType === 'image text' && surveyContent.pages[i].questions[j].image) {
                    for (let k = 0; k < surveyContent.pages[i].questions[j].image.length; k++) {
                        formData.append(`pages[${i}][questions][${questionCount}][responseFiles][${k}]`, surveyContent.pages[i].questions[j].image[k])

                    }
                    formData.append(`pages[${i}][questions][${questionCount}][responseText]`, surveyContent.pages[i].questions[j].answer)

                } else if (surveyContent.pages[i].questions[j].questionType === 'video text') {
                    
                    for (let k = 0; k < surveyContent.pages[i].questions[j].video.length; k++) {
                        formData.append(`pages[${i}][questions][${questionCount}][responseFiles][${k}]`, surveyContent.pages[i].questions[j].video[k])

                    }
                  
                    formData.append(`pages[${i}][questions][${questionCount}][responseText]`, surveyContent.pages[i].questions[j].answer)

                } else if (surveyContent.pages[i].questions[j].questionType === 'grid' && surveyContent.pages[i].questions[j].rows.length > 0) {
                    // if (surveyContent.pages[i].questions[j].isUniqueSelected) {
                    surveyContent.pages[i].questions[j].rows.map((row, rowIndex) => {
                        formData.append(`pages[${i}][questions][${questionCount}][gridResponse][${rowIndex}][row]`, row.text)

                        surveyContent.pages[i].questions[j].selected.map((selected, selectedIndex) => {
                            if (selected.row === rowIndex + 1)
                                formData.append(`pages[${i}][questions][${questionCount}][gridResponse][${rowIndex}][column]`, surveyContent.pages[i].questions[j].columns[selected.column - 1].text)
                        })

                    })
                    // }

                }
            }

        }

       
        dispatch(createSurveyReply(formData))
        .then((response) => {
            Swal.stopLoading()
            Swal.close()
            // getReplies(response.payload.content._id)
            Swal({
                title: "Submitted",
                text: 'Congratulations, Your Form has been Submitted',
                icon: 'success'
            }).then(() => history(`/respondent/survey-analysis/${params.surveyid}/${response.payload.content._id}`))
        })
        .catch(err => {
            console.log(err)
            Swal.stopLoading()
            Swal.close()
            Swal({
                title: err.response ? err.response.data.name : "Error",
                text: err.response ? err.response.data.content : "Something went wrong",
                icon: 'error'
            })
        })


        // dispatch(getSurveyReplies(params.surveyid))
        //     .then((response) => {
        //         //Swal.stopLoading()
        //         //Swal.close()
        //         // getReplies(response.payload.content._id)
        //         Swal({
        //             title: "Submitted",
        //             text: 'Congratulations, Your Form has been Submitted',
        //             icon: 'success'
        //         }).then(() => history(`/respondent/survey-analysis/${params.surveyid}/${response.payload.content._id}`))
        //     })
        //     .catch(err => {
        //         console.log(err)
        //         Swal.stopLoading()
        //         Swal.close()
        //         Swal({
        //             title: err.response ? err.response.data.name : "Error",
        //             text: err.response ? err.response.data.content : "Something went wrong",
        //             icon: 'error'
        //         })
        //     })

        }
    }

    return (

        steps.length > 0 && <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
            <div className="container-fluid pt-3">

                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // position: 'fixed',
                            // top: '2%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>

                    <>
                    
                        {/* /* Back */}
                        {/* <Back /> */}
                     
                        <div className="mb-3 mt-3">
                            {/* Image Display */}
                            {surveyDetails.bannerImage &&

                                // Image Display
                                <div>
                                    <div className="position-relative">
                                        <img src={`data:${bannerImg.type} ;base64, ${bannerImg.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px', maxHeight: 400, width: 'max-content' }} alt="img" />
                                    </div>
                                </div>
                            }
                        </div>

                        {/* Heading */}
                        <h5 style={{ color: "#000", fontWeight: 500, fontSize: "20px" }} className=" mt-5">
                            {surveyDetails.name}
                        </h5>

                        <div className="my-3">
                            <div className={styles.card_border}>
                                <div className={styles.card_border_body + " py-4"}>
                                    <p style={{ color: '#000', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }} className="mb-0">
                                        {surveyDetails.description}
                                    </p>
                                    {/* <p style={{color: '#666', fontSize: "0.96rem", fontWeight: '400'}} className='mb-0' dangerouslySetInnerHTML={{ __html: surveyDetails.description }}></p> */}
                                </div>
                            </div>
                        </div>
                 
                        {
                          
                            new Date(surveyDetails.startDate) <= Date.now() ?
                                <Survey_Steps showNavigation={true} steps={steps} initialStep={page} activityId={params.surveyid} onSubmit={onSubmit} />
                               
                                :
                                <div className='col-12 text-center text-muted'>Survey will start on {new Date(surveyDetails.startDate).toLocaleDateString()}</div>
                        }


                    </>



                </LoadingOverlay >

            </div>
        </TransitionGroup >

    )

}

export default Survey;