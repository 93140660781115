import React, { useEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';

import SurveyFormSteps from '../Activity/create/SurveyForm/SurveyFormSteps';

import Question_With_Radio from '../../../../assets/components/survey-questions/Question_With_Radio';
import Question_With_Checkbox from '../../../../assets/components/survey-questions/Question_With_Checkbox';
import Question_With_LongText from '../../../../assets/components/survey-questions/Question_With_LongText';
import Question_With_Image from '../../../../assets/components/survey-questions/Question_With_Image';
import Question_With_Image_Text from '../../../../assets/components/survey-questions/Question_With_Image_Text';
import Question_With_Video from '../../../../assets/components/survey-questions/Question_With_Video';
import Question_With_Video_Text from '../../../../assets/components/survey-questions/Question_With_Video_Text';
import Question_With_Grid from '../../../../assets/components/survey-questions/Question_With_Grid';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import SurveyIcon from '../../../../assets/images/survey_icon.png';
import Question_With_Spectrum from '../../../../assets/components/survey-questions/Question_With_Spectrum';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ArrowLeft from '../../../../assets/images/arrow_left.png'
import { getActivity, getActivityImg } from '../../../../actions/activities_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import ResizeDetector from 'react-resize-detector';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

import CryptoJS from 'crypto-js';



// ********** This component is used to show how the survey form will look to participants ****************

const EvaluationFormPreview = (props) => {

    const location = useLocation()
    const [steps, setSteps] = useState([]);
    const [currentPage, setCurrentPage] = useState();
    const history = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [bannerImg, setBannerImg] = useState({})
    const params = useParams()
    // Json Response
    const [evaluationContent, set_evaluationContent] = useState({});

    useEffect(() => {

        setSteps([]);

        let quesNo = 0;

        // Set Pages Content
        evaluationContent.pages && evaluationContent.pages.length > 0 && evaluationContent.pages.map((page, i) => {

            let pageContent = <React.Fragment>

                {page.questions.map((question, index) => {

                    quesNo++;

                    switch (question.questionType) {

                        case 'radio':

                            return <Question_With_Radio
                                key={index}
                              id={'radio' + CryptoJS.lib.WordArray.random(5)}
                                name={page.pageName + Math.floor(Math.random() * 100) + (index + 1)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                otherText={question.otherText}
                                isOtherAllowed={question.isOtherAllowed}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={evaluationContent}
                                setContent={set_evaluationContent}
                                isAnswered={true}
                            />

                        case 'checkbox':

                            return <Question_With_Checkbox
                                key={index}
                                id={'check' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                otherText={question.otherText}
                                isOtherAllowed={question.isOtherAllowed}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={evaluationContent}
                                setContent={set_evaluationContent}
                                isAnswered={true}
                            />

                        case 'long text':

                            return <Question_With_LongText
                                key={index}
                                id={'longText' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                answer={question.answer}
                                textLimit={question.textLimit}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={evaluationContent}
                                setContent={set_evaluationContent}
                                isAnswered={true}
                            />

                        case 'image':

                            return <Question_With_Image
                                key={index}
                                id={'image' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                image={question.image}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                comment={question.comment}
                                isComment={question.isComment}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={evaluationContent}
                                setContent={set_evaluationContent}
                                isAnswered={true}
                            />

                        case 'image text':

                            return <Question_With_Image_Text
                                key={index}
                                id={'image-text' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                image={question.image}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                answer={question.answer}
                                textLimit={question.textLimit}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={evaluationContent}
                                setContent={set_evaluationContent}
                                isAnswered={true}
                            />

                        case 'video':

                            return <Question_With_Video
                                key={index}
                                id={'video' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                video={question.video}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                comment={question.comment}
                                isComment={question.isComment}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={evaluationContent}
                                setContent={set_evaluationContent}
                                isAnswered={true}
                            />

                        case 'video text':

                            return <Question_With_Video_Text
                                key={index}
                                id={'video-text' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                video={question.video}
                                filesLimit={question.filesLimit}
                                filesSize={question.filesSize}
                                answer={question.answer}
                                textLimit={question.textLimit}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={evaluationContent}
                                setContent={set_evaluationContent}
                                isAnswered={true}
                            />

                        case 'grid':

                            return <Question_With_Grid
                                key={index}
                                id={'grid' + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                rows={question.rows}
                                columns={question.columns}
                                selected={question.selected}
                                comment={question.comment}
                                isComment={question.isComment}
                                uniqueSelected={question.uniqueSelected}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={evaluationContent}
                                setContent={set_evaluationContent}
                                isAnswered={true}
                            />

                        case 'spectrum':

                            return <Question_With_Spectrum
                                key={index}
                                id={"spectrum" + CryptoJS.lib.WordArray.random(5)}
                                questionText={`Q ${quesNo}. ` + question.questionText}
                                questionVideo={question.questionVideo}
                                questionFile={question.questionFile}
                                mediaType={question.mediaType}
                                options={question.options}
                                selected={question.selected}
                                required={question.required}
                                pageIndex={i}
                                questionIndex={index}
                                allContent={evaluationContent}
                                setContent={set_evaluationContent}
                                isAnswered={true}
                            />

                        default:
                            break;
                    }

                })}

            </React.Fragment>

            // Set Total Page Steps
            setSteps(oldSteps => [
                ...oldSteps,
                {
                    name: page.pageName,
                    component: pageContent,
                    percentage: parseInt(((i + 1) / evaluationContent.pages.length) * 100),
                    currentPage,
                    setCurrentPage,
                    page
                }
            ]);

        });

    }, [evaluationContent, currentPage]);

    useEffect(() => {


        dispatch(getActivityImg(params.evaluationid))
        .then((response) =>{
            
            
            setBannerImg(response.payload.content.fileData)
            
        })

        if (params.evaluationid !== 'undefined') {
            dispatch(getActivity(params.evaluationid))
                .then((response) => {
                    set_evaluationContent(response.payload.content.survey)
                    setLoading(false)
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        }

    }, [])

    const [currentWidth, setWidth] = useState()
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // top: '50%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>

                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                        <div className="admin_header">
                            <PageHeader
                                heading="Screener Form" img={SurveyIcon}
                                isSubheading={true}
                                subHeadingText="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
                                btnGroup={true}
                                btn1Click={() => setWidth(1026)}
                                btn2Click={() => setWidth(470)}
                                btn3Click={() => setWidth(370)}
                            />
                        </div>

                        {/* Back Icon */}
                        <span onClick={() => {location.state && location.state.from === 'create' ?
                            history(`/researcher/project/edit-evaluation/${params.projectid}/${params.evaluationid}`) :
                            history(-1)}}
                            style={{ cursor: 'pointer' }}>
                        <img src={ArrowLeft} alt="" width="8px" />
                        <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                    </span>

                    <div className="container-fluid" style={{ width: currentWidth }}>
                        {
                            steps.length > 0 && <SurveyFormSteps showNavigation={true} steps={steps} />
                        }

                    </div>
                </TransitionGroup>



                </LoadingOverlay >

            )}
/>
    )

}

export default EvaluationFormPreview;