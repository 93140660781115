import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import styles from '../../../assets/css/rstyling.module.css';
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png';
import {
    Card, CardBody,
} from "reactstrap";
import Listing from '../../../assets/components/listing/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { userResearchHistory } from '../../../actions/history_action';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const Research_History = () => {

    const history = useNavigate();
    const dispatch = useDispatch()
    const historyState = useSelector(state => state.ResearchHistory)
    const [isLoading, setLoading] = useState(true)
    const [column, setColumn] = useState([
        { Name: 'ID No', Type: 'serialNo' },
        { Name: "Project Name", Type: 'string', key: 'title' },
        { Name: "Project Code", Type: 'string', key: 'code' },
        { Name: "Status", Type: 'string', key: 'status' },
        { Name: "Type", Type: 'string' },
        { Name: 'No of Resp', Type: 'number' },
    ])

    const [historyData, setHistory] = useState([])
    const [searchString, setSearchString] = useState('')
    const [searchedResult, setSearchedResult] = useState([])

    // Global filtering
    useEffect(() => {
        let search = new RegExp(searchString, 'i');
        let filteredHistory = historyData.filter(row => {
            return (
                search.test(row._id) ||
                search.test(row.title) ||
                search.test(row.code) ||
                search.test(row.status) 
            );
        });
        setSearchedResult(filteredHistory);
    }, [searchString]);


    const getListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(userResearchHistory(currentPage, dataLimit))
            .then(response => {
                
                setHistory(response.payload.content.projects)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err);
            })
    }

    useEffect(() => {
        getListing()
    }, [])

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <Card className="main-card mb-3">
                <nav class="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                    <div class="text-white menu-header-title text-capitalize">Research History</div>

                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav mr-auto">
                        </ul>
                        <form class="form-inline my-2 my-lg-0" style={{ marginRight: '-2rem' }}>
                            <div className="input-group input-group-sm w-75">
                                <div className="input-group-prepend border-0">
                                    <span className={`bg-white my-2 my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                </div>
                                <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }} />
                            </div>

                        </form>
                    </div>
                </nav>

                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>
                    <CardBody className="p-0">

                        {/* Listing */}
                        <div className="table_border">
                            <Listing columnName={column}
                                data={(searchString.length > 0 || searchedResult.length > 0) ? searchedResult : historyData}
                                currentPage={historyState.currentPage ? historyState.currentPage : 1}
                                totalPages={historyState.totalPages ? historyState.totalPages : 1}
                                getListingData={(currentPage, dataLimit) => getListing(currentPage, dataLimit)}
                                startLoading={() => setLoading(true)}
                            />
                        </div>

                    </CardBody>
                </LoadingOverlay>
            </Card>
        </TransitionGroup>
    )
}

export default Research_History
