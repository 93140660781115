import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { TransitionGroup } from 'react-transition-group';
import { editUserDetails, editUserDetailsTest, getUserDetails } from '../../../actions/user_action';

import Profile_Steps from './Profile_Steps';
import Contact_Details from './Views/Contact_Details';
import Demographics from './Views/Demographics';
import Employement_Details from './Views/Employment_Details';
import Interests_Details from './Views/Interests_Details';
import Swal from 'sweetalert'
import Back from '../../../assets/components/back/Back';
import { IoMdContact } from "react-icons/io";
import { FaMagnifyingGlassChart } from "react-icons/fa6";
import { MdWorkOutline } from "react-icons/md";
import { RxHobbyKnife } from "react-icons/rx"
import { FaRegStar } from "react-icons/fa";

const Profile = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const history = useNavigate()
    const [profileDetails, setProfileDetails] = useState({
        username: '',
        birthday: '',
        city: '',
        address: '',
        gender: '',
        maritalStatus: '',
        employment: '',
        education: '',
        occupation: '',
        income: '',
        cwe: '',
        company: '',
        jobRole: '',
        profileImage: '',
        interests: [],
    })

    useEffect(() => {
        dispatch(getUserDetails(params.userId))
            .then(response => {
                setProfileDetails(response.payload.content)
                // setLoading(false)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                }).then(() => history('/respondent'))
            })
    }, []);

    const onEditUserDetails = () => {

        dispatch(editUserDetailsTest(profileDetails._id, profileDetails))
            .then(response => {
                if (response.payload.message === 'User profile updated successfully') {
                    Swal({
                        title: "Edited",
                        text: 'Details edited',
                        icon: 'success'
                    })
                }
                else if (response.payload.message === 'Profile completion email sent to user') {
                    Swal({
                        title: "Completed",
                        text: 'Your profile is completed successfully',
                        icon: 'success'
                    })
                }
                else {

                    Swal({
                        title: "Edited",
                        text: 'Details edited',
                        icon: 'success'
                    })
                }
                // setTimeout(() => {
                //     history('/respondent')
                // }, 2000);

            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }


    return (

        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
            <div className="container-fluid pt-3">

                {/* <Back/>  */}

                {/* Profiles Steps */}
                <Profile_Steps showNavigation={true} steps={[
                    { name: "Contact", icon: <IoMdContact />, component: <Contact_Details profileDetails={profileDetails} setProfileDetails={setProfileDetails} editUser={() => onEditUserDetails()} /> },
                    { name: "Demographics", icon: <FaMagnifyingGlassChart />, component: <Demographics profileDetails={profileDetails} setProfileDetails={setProfileDetails} editUser={() => onEditUserDetails()} /> },
                    { name: "Employment", icon: <MdWorkOutline />, component: <Employement_Details profileDetails={profileDetails} setProfileDetails={setProfileDetails} editUser={() => onEditUserDetails()} /> },
                    { name: "Interests", icon: <FaRegStar />, component: <Interests_Details profileDetails={profileDetails} setProfileDetails={setProfileDetails} editUser={() => onEditUserDetails()} /> }
                ]} />

            </div>
        </TransitionGroup>

    )

}

export default Profile;