import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import {
    AVAILABLE_ACTIVITY_ATTACHMENTS,
    AVAILABLE_ACTIVITY_WORD_CLOUD,
    AVAILABLE_PARTICIPANTS_POST,
    AVAILABLE_PARTICIPANTS_REPLY_STREAM,
    AVAILABLE_POST_COMMENTS,
    AVAILABLE_POST_PARTICIPANTS,
    AVAILABLE_POST_REPLY,
    AVAILABLE_SURVEY_PARTICIPANTS,
    AVAILABLE_SURVEY_REPLY,
    CREATE_COMMENTS,
    CREATE_POST_REPLY,
    CREATE_SURVEY_REPLY,
    DELETE_COMMENT,
    DELETE_POST_REPLY,
    EDIT_COMMENT,
    EDIT_POST_REPLY,
    EDIT_SURVEY_REPLY,
    EXPORT_POST_RESPONSES,
    EXPORT_SURVEY_RESPONSES,
    GET_SURVEY_REPLY,
    POST_REPORTS,
    SURVEY_REPORTS,
    GET_REPLIES_IMAGES,
    SEND_REPLIED_ANSWER,
    DELETE_SURVEY_ONE_REPLY,
    ACTIVITY_POINTS_CALCULATION,
    MASS_REPLIED_CHECKER
} from './types.js';


// ************** These apis are used in ADMIN, RESEARCHER & RESPONDENT END inside Activity Section of Projects & Group Forums/ general in RESPONDENT END ******************
// Posts refer to activity type Diary and Group Forum


export const calculate_sec = (options, userId) => {

    let data = options

    let url = `${BACK_SERVER_URL}api/replies/calculate-sec/${userId}`;

    const request = axios.post(url, data)
        .then(response => response.data)
    return {
        type: SEND_REPLIED_ANSWER,
        payload: request
    }
}

export const createPostReply = (data) => {

    let url = `${BACK_SERVER_URL}api/posts`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_POST_REPLY,
        payload: request
    }
}


export const editPostReply = (id, data) => {

    let url = `${BACK_SERVER_URL}api/posts/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_POST_REPLY,
        payload: request
    }
}
// journal inside view
export const delPostReply = (id) => {

    let url = `${BACK_SERVER_URL}api/posts/${id}`;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_POST_REPLY,
        payload: request
    }
}

// delete one reply of a post group forum
export const delReply = (id) => {

    let url = `${BACK_SERVER_URL}api/posts/${id}`;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_COMMENT,
        payload: request
    }
}

export const getAllPostReply = (data) => {

    let url = `${BACK_SERVER_URL}api/posts/activity`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_POST_REPLY,
        payload: request
    }
}

// ************** These apis are used in ADMIN, RESEARCHER inside Activity Section of Projects & Quick Surveys / Surveys in RESPONDENT END ******************

export const createSurveyReply = (data) => {

    let url = `${BACK_SERVER_URL}api/replies`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_SURVEY_REPLY,
        payload: request
    }
}
export const getSurveyReplies = (surveyid) => {
    //grab something from backend to indicate that all pages responses are saved
    let url = `${BACK_SERVER_URL}api/replies/getReplies/${surveyid}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: CREATE_SURVEY_REPLY,
        payload: request
    }
}

export const createSurveyReply2 = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/sharableSurvey`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_SURVEY_REPLY,
        payload: request
    }
}

export const editSurveyReply = (id, data) => {

    let url = `${BACK_SERVER_URL}api/replies/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_SURVEY_REPLY,
        payload: request
    }
}

export const getSurveyReply = (id, surveyid) => {
    //console.log('get survey replies in frotnend->', id)
    let url = `${BACK_SERVER_URL}api/replies/${id}/${surveyid}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_SURVEY_REPLY,
        payload: request
    }
}

export const delSurveyReply = (id) => {

    let url = `${BACK_SERVER_URL}api/replies/${id}`;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_POST_REPLY,
        payload: request
    }
}

export const getAllSurveyReply = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/activity`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_SURVEY_REPLY,
        payload: request
    }
}

export const calculateActivityPoints = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/activityPoints`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: ACTIVITY_POINTS_CALCULATION,
        payload: request
    }
}

export const deleteOneReplyOfASurvey = (deleteReplyDetails) => {

    let data = deleteReplyDetails
    let url = `${BACK_SERVER_URL}api/replies/activity/deleteOneReply`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: DELETE_SURVEY_ONE_REPLY,
        payload: request
    }
}

// ************** These apis are used in ADMIN, RESEARCHER & RESPONDENT END inside Activity Section of Projects & Group Forums/ general in RESPONDENT END ******************

export const createComments = (data) => {

    let url = `${BACK_SERVER_URL}api/comments/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_COMMENTS,
        payload: request
    }
}

export const editComments = (id, data) => {

    let url = `${BACK_SERVER_URL}api/comments/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_COMMENT,
        payload: request
    }
}

export const delComment = (id) => {

    let url = `${BACK_SERVER_URL}api/comments/${id}`;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_COMMENT,
        payload: request
    }
}




export const getAllReplyComments = (data) => {

    let url = `${BACK_SERVER_URL}api/comments/post/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_POST_COMMENTS,
        payload: request
    }
}

// **********  These apis are used in ADMIN & RESEARCHER END inside view/Participant Section of Activity in Projects & inside Mini Survey views ***********

export const getAllSurveyParticipants = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/participants/activity`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_SURVEY_PARTICIPANTS,
        payload: request
    }
}

// Returns all participants against a project
export const getAllPostParticipants = (data) => {

    let url = `${BACK_SERVER_URL}api/posts/participants/activity`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_POST_PARTICIPANTS,
        payload: request
    }
}

// Returns all posts against a user and and project
export const getAllParticipantPosts = (data) => {
//console.log('frontend data while getting author ->', data)
    let url = `${BACK_SERVER_URL}api/posts/participant`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_PARTICIPANTS_POST,
        payload: request
    }
}

export const getOneParticipantPost = (data) => {
    //console.log('frontend data while getting author ->', data)
        let url = `${BACK_SERVER_URL}api/posts/participant`;
    
        const request = axios.post(url, data)
            .then(response => response.data);
    
        return {
            type: AVAILABLE_PARTICIPANTS_POST,
            payload: request
        }
    }

// ****************  These apis are used in ADMIN & RESEARCHER END inside Analytics Section of Activity in Projects *********************

// Used to display all responses inside Stream Section of Activity 

export const getAllParticipantReplyStreams = (data) => {

    let url = `${BACK_SERVER_URL}api/posts/activity/stream`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_PARTICIPANTS_REPLY_STREAM,
        payload: request
    }
}

// Used to display all attachments of activity inside File Section

export const getAllPostReplyAttachments = (data) => {

    let url = `${BACK_SERVER_URL}api/posts/activity/attachments`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_ACTIVITY_ATTACHMENTS,
        payload: request
    }
}

export const getAllSurveyReplyAttachments = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/activity/attachments`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_ACTIVITY_ATTACHMENTS,
        payload: request
    }
}

// Used generate Word cloud of Activity 

export const getPostWordCloud = (data) => {

    let url = `${BACK_SERVER_URL}api/posts/activity/word-cloud`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_ACTIVITY_WORD_CLOUD,
        payload: request
    }
}

export const getSurveyWordCloud = (data, filter) => {
    data.graphFilters = filter
    let url = `${BACK_SERVER_URL}api/replies/activity/word-cloud`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_ACTIVITY_WORD_CLOUD,
        payload: request
    }
}

// Returns a downloadable link to export activity report in excel

export const exportSurveyResponses = (activityId) => {
   
    let url = `${BACK_SERVER_URL}api/replies/activity/export/${activityId}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: EXPORT_SURVEY_RESPONSES,
        payload: request
    }
}

export const exportPostResponses = (id) => {

    let url = `${BACK_SERVER_URL}api/posts/activity/export?activityId=${id}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: EXPORT_POST_RESPONSES,
        payload: request
    }
}

// ****************  These apis are used in ADMIN & RESEARCHER END inside Reports Section of Activity in Projects *********************

// Used to display report of each participant in an activity

export const getSurveyReports = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/activity/reports`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: SURVEY_REPORTS,
        payload: request
    }
}

export const getPostReports = (data) => {

    let url = `${BACK_SERVER_URL}api/posts/activity/reports`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: POST_REPORTS,
        payload: request
    }
}

export const getActivityReplyImages = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/getReplyImages/replyPath/admin`;

    const request = axios.post(url, data)
        .then(response => response.data)
    return {
        type: GET_REPLIES_IMAGES,
        payload: request
    }
}
export const getActivityReplyImagesRespondent = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/getReplyImages/replyPath/respondent`;

    const request = axios.post(url, data)
        .then(response => response.data)
    return {
        type: GET_REPLIES_IMAGES,
        payload: request
    }
}
// export const getActivityReplyVideos =(page, survey, question)=>{
// let data={
//     page: page,
//     question: question,
//     survey: survey
// }
//     let url = `${BACK_SERVER_URL}api/replies/getReplyVideos`;

//     const request = axios.post(url, data)
//     .then(response => response.data)
//     return {
//         type: GET_REPLIES_IMAGES,
//         payload: request
//     }
// }

export const getActivityReplyVideos = (data) => {
    // let data={
    //     page: page,
    //     question: question,
    //     survey: survey
    // }

    let url = `${BACK_SERVER_URL}api/replies/getReplyVideos`;

    const request = axios.post(url, data)
        .then(response => response.data)
    return {
        type: GET_REPLIES_IMAGES,
        payload: request
    }
}

export const sendActivityReplyAnswers = (surveyId, data) => {



    let url = `${BACK_SERVER_URL}api/replies/sendAnswer/${surveyId}`;

    const request = axios.post(url, data)
        .then(response => response.data)
    return {
        type: SEND_REPLIED_ANSWER,
        payload: request
    }
}

export const sendResponseVideoOnCloud = (surveyId, data, userId) => {



    let url = `${BACK_SERVER_URL}api/replies/uploadResponseVideo/${surveyId}/${userId}`;

    const request = axios.post(url, data)
        .then(response => response.data)
    return {
        type: SEND_REPLIED_ANSWER,
        payload: request
    }
}

export const sendSharableSurveyReplyAnswers = (surveyId, data, userId) => {


    let url = `${BACK_SERVER_URL}api/replies/sendAnswer/sharableSurvey/${surveyId}/${userId}`;

    const request = axios.post(url, data)
        .then(response => response.data)
    return {
        type: SEND_REPLIED_ANSWER,
        payload: request
    }
}



export const mass_responses_checker = (data, activityId) => {


    let url = `${BACK_SERVER_URL}api/replies/mass-responses-checker/${activityId}`;

    const request = axios.post(url, data)
        .then(response => response.data)
    return {
        type: MASS_REPLIED_CHECKER,
        payload: request
    }
}